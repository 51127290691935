<template>
  <div class="flex-container actions--container-new">
    <div
      v-if="!customButton"
      :id="id"
      class="action--button-new"
      :class="[cssClassName, { pressed: showDropdown }]"
      :style="[buttonStyle, highlightButtonStyle]"
      @click="toggleDropdown"
    >
      <span style="margin-right: 0.2rem">
        <icons
          v-if="frontIcon"
          :name="frontIcon"
          :color="highlightStyle ? 'green' : frontIconColor"
          :size="iconSize"
        />
      </span>
      <span
        v-if="!staticTitle"
        class="selected-option title-h6_m"
        :style="[
          size === 'secondary-large'
            ? { 'margin-right': '5rem' }
            : { 'margin-right': 'auto' },
          highlightStyle,
        ]"
      >
        <span v-html="getOptionTitle" />
        <span
          v-if="collapseSelected && optionLength > 1"
          v-tooltip="tooltipOptions"
          class="title-h5_m"
        >
          +{{ optionLength - 1 }}
        </span>
      </span>
      <span v-else class="title-h6_m" v-text="staticTitle" />
      <span style="margin-left: 0.8rem">
        <icons :name="icon" :size="iconSize" :color="iconColor" />
      </span>
    </div>
    <div v-else @click="toggleDropdown">
      <slot :data="{ selectedOptionsLength: selectedOptionId.length }" />
    </div>
    <transition name="dropdown">
      <ul
        v-if="showDropdown"
        :style="[customStyle, dropDownPanePosition, paneWidth]"
        class="dropdown-list dropdown--pane-new custom-scroll"
        :class="{ 'dropdown--pane-new--open': showDropdown }"
      >
        <div v-if="showSearch" class="search-input">
          <search-box
            :placeholder="$t('LABEL_MGMT.SEARCH_TXT')"
            :value="searchVal"
            custom-class="no-border"
            @setSearch="setSearch"
          />
        </div>
        <li
          v-for="option in filteredOptionList"
          :key="option.id"
          v-tooltip="showOptionTooltip && option[defaultKey]"
        >
          <button
            class="dropdown-button-new"
            type="button"
            :class="{
              'dropdown-button-new--selected': isSelectedId(option.id) > -1,
            }"
            :style="{ 'pointer-events': 'auto' }"
            @click="onClick(option)"
          >
            <span v-if="optionIcon" style="margin-right: 0.4rem">
              <icons
                :name="optionIcon"
                :size="iconSize"
                :color="isSelectedId(option.id) > -1 ? 'white' : 'darkestgrey'"
              />
            </span>
            <span v-if="option.icon" style="margin-right: 0.4rem">
              <icons
                :name="option.icon"
                :size="iconSize"
                :color="
                  isSelectedId(option.id) > -1
                    ? 'white'
                    : `${option.color} evenodd`
                "
              />
            </span>
            <span
              class="option-name title-h6_m text-truncate"
              :style="{
                color:
                  isSelectedId(option.id) > -1
                    ? 'white'
                    : option.textcolor
                    ? option.textcolor
                    : 'black',
              }"
            >
              {{ option[defaultKey] }}
            </span>
            <span v-if="showSelectedText">
              <span v-if="isSelectedId(option.id) > -1">Selected</span>
              <span v-else class="show-selected">Select</span>
            </span>
          </button>
        </li>
        <li v-if="!optionList.length">
          <button class="button action--button-new no-border-radius">
            <span>{{ 'No options available' }}</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';

export default {
  components: {
    SearchBox,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    customButton: {
      type: Boolean,
      default: false,
    },
    prefixText: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'secondary',
      validator: val => ['primary', 'secondary', 'tertiary'].includes(val),
    },
    size: {
      type: String,
      default: 'medium',
      validator: val => ['small', 'medium', 'large'].includes(val),
    },
    defaultOption: {
      type: String,
      default: '',
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    frontIcon: {
      type: String,
      default: '',
    },
    optionIcon: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'chevronDown',
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    collapseSelected: {
      type: Boolean,
      default: false,
    },
    showOptionTooltip: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOnSelect: {
      type: Boolean,
      default: true,
    },
    toggleFilter: {
      type: Function,
      default: () => {},
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    buttonStyle: {
      type: Object,
      default: undefined,
    },
    showSelectedText: {
      type: Boolean,
      default: true,
    },
    defaultKey: {
      type: String,
      default: 'name',
    },
    highlightSelected: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    staticTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchVal: '',
      showDropdown: false,
      selectedOption: [],
      selectedOptionId: [],
    };
  },
  computed: {
    frontIconColor() {
      if (this.variant === 'primary') return 'white';
      if (this.variant === 'secondary') return 'textlightgrey';
      return 'darkestgrey';
    },
    iconColor() {
      if (this.variant === 'primary') return 'white';
      return 'green';
    },
    optionLength() {
      return this.selectedOption.length;
    },
    getOptionTitle() {
      if (this.collapseSelected) {
        return this.selectedOption.length
          ? this.selectedOption[0]
          : this.defaultOption;
      }
      return this.selectedOption.length
        ? this.prefixText + this.selectedOption.join(', ')
        : this.defaultOption;
    },
    filteredOptionList() {
      if (!this.showSearch) return this.optionList;
      return this.optionList.filter(label => {
        return (
          label.title.toLowerCase().search(this.searchVal.toLowerCase()) > -1
        );
      });
    },
    tooltipOptions() {
      return this.selectedOption
        ? this.selectedOption.slice(1).join(',')
        : null;
    },
    iconSize() {
      if (this.variant === 'primary' || this.variant === 'tertiary')
        if (this.size === 'large') return 'medium';
      return 'semimedium';
    },
    cssClassName() {
      if (this.variant === 'primary') {
        return `${this.variant}-dropdown ${this.size}-dropdown`;
      }
      return `${this.variant}-dropdown ${this.variant}-${this.size}`;
    },
    dropDownPanePosition() {
      if (this.size === 'small') return { top: '3.4rem' };
      if (this.size === 'medium') return { top: '4.4rem' };
      if (this.size === 'large') return { top: '4.6rem' };
      return { top: '4.4rem' };
    },
    paneWidth() {
      if (!this.customStyle)
        return { 'max-width': '20rem', 'overflow-x': 'hidden' };
      return '';
    },
    highlightStyle() {
      if (this.highlightSelected && this.selectedOptionId.length > 0) {
        return { color: '#6bac1b' };
      }
      return '';
    },
    highlightButtonStyle() {
      if (this.highlightSelected && this.selectedOptionId.length > 0) {
        return { 'border-color': '#6bac1b' };
      }
      return '';
    },
  },
  mounted() {
    bus.$on('dropdown_pane', this.hideDropdown);
  },
  beforeDestroy() {
    this.removeEventListener();
  },
  methods: {
    onClick(val) {
      let index = this.isSelected(val[this.defaultKey]);
      let id = this.isSelectedId(val.id);
      if (id > -1) {
        this.$delete(this.selectedOption, index);
        this.$delete(this.selectedOptionId, id);
      } else {
        this.$set(
          this.selectedOption,
          this.selectedOption.length,
          val[this.defaultKey]
        );
        this.$set(this.selectedOptionId, this.selectedOptionId.length, val.id);
        this.$emit('selected-option', val[this.defaultKey]);
      }

      let state = !!this.selectedOption.length;

      this.toggleFilter(state, this.selectedOption, this.selectedOptionId);
    },
    isSelected(option) {
      return this.selectedOption.findIndex(ele => {
        return ele === option;
      });
    },
    isSelectedId(id) {
      return this.selectedOptionId.findIndex(ele => {
        return ele === id;
      });
    },
    toggleDropdown() {
      this.$emit('dropdown-opened');
      this.showDropdown = !this.showDropdown;

      if (this.showDropdown) this.addEventListener();
      else this.removeEventListener();
    },
    hideDropdown() {
      this.showDropdown = false;
    },
    documentClickListener(event) {
      const dropdownElement = this.$el;
      let targetElement = event.target; // clicked element

      do {
        if (targetElement === dropdownElement) {
          // This is a click inside the dropdown, do nothing, just return.
          return;
        }
        // Go up the DOM
        targetElement = targetElement.parentNode;
      } while (targetElement);

      // This is a click outside the dropdown.
      this.hideDropdown();
    },
    addEventListener() {
      document.addEventListener('mousedown', this.documentClickListener);
    },
    removeEventListener() {
      document.removeEventListener('mousedown', this.documentClickListener);
    },
    setSearch(value) {
      this.searchVal = value;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
.search-input {
  min-width: $space-normal * 10;

  .no-border {
    border: none;
    margin-bottom: $zero;
  }
}
</style>
