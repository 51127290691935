<template>
  <div class="automations column content-box settings-box no-padding">
    <woot-base-button
      size="medium"
      front-icon="plus"
      icon-view-box="0 0 20 20"
      class="settings-button"
      @click="openAddPopup"
    >
      {{ $t('AUTOMATION.HEADER_BTN_TXT') }}
    </woot-base-button>

    <div class="settings-container">
      <div class="column settings-main-menu-new">
        <search-box
          :placeholder="$t('AUTOMATION.SEARCH_TXT')"
          :value="search"
          custom-class="search-box"
          @setSearch="setSearch"
        />
        <div class="mg-bottom--smaller">
          <woot-tabs :index="selectedTabIndex" @change="onTabChange">
            <woot-tabs-item
              v-for="tab in tabs"
              :key="tab.key"
              :name="tab.name"
            />
          </woot-tabs>
        </div>
        <List
          :key="selectedTabIndex"
          :selected-tab-index="selectedTabIndex"
          :actions-list="actionsList"
          :table-columns="tableColumns"
          :automations-list="automationsList"
          :loading="isLoading"
          :search="search"
          @setSearch="setSearch"
          @viewAutomation="viewAutomation"
          @openEditPopup="openEditPopup"
          @openAddPopup="openAddPopup"
          @changeSelectedTab="onTabChange"
          @openDeletePopup="openDeletePopup"
          @cloneAutomation="cloneAutomation"
        />
      </div>
      <settings-side-card>
        <educational-onboarding
          v-if="!selectedAutomation"
          :show-case-data="SHOWCASE_AUTOMATION_DATA"
        />
        <rhs-panel
          v-else
          :automation="selectedAutomation"
          @cloneAutomation="cloneAutomation"
        />
      </settings-side-card>

      <transition name="modal-fade">
        <create-automation-modal
          v-if="showCreateModal"
          @handleCreate="onHandleCreate"
          @onClose="showCreateModal = false"
        />
      </transition>

      <transition name="modal-fade">
        <automation-details-modal
          v-if="showDetailsModal"
          opened
          :name="selectedTemplateDetails.name"
          :description="selectedTemplateDetails.description"
          @updateDetail="onUpdateTargetRoute"
          @onSubmit="onSubmitDetailsModal"
          @onClose="showDetailsModal = false"
        />
      </transition>

      <woot-delete-modal-new
        :show.sync="showDeletePopup"
        :on-close="closeDeletePopup"
        :on-confirm="confirmDeletion"
        :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
        :message="deleteMessage"
        show-close
        confirm-text="Continue"
        reject-text="Back"
        :custom-style="{
          'max-width': '35.2rem',
          height: 'auto',
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SearchBox from '../SearchBox';
import List from './components/List';
import SettingsSideCard from '../SettingSideCard';
import CreateAutomationModal from './components/CreateAutomationModal';
import EducationalOnboarding from 'dashboard/components/ui/settings/EducationalOnboarding';
import RhsPanel from './components/RhsPanel';
import AutomationDetailsModal from './components/shared/AutomationDetailsModal';

import alertMixin from 'shared/mixins/alertMixin';
import accountMixin from 'dashboard/mixins/account';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import {
  SHOWCASE_AUTOMATION_DATA,
  AUTOMATION_TABLE_ACTIONS_LIST,
  AUTOMATION_TABLE_COLUMNS,
  AUTOMATION_TABLE_TABS,
} from './utils/constants';

export default {
  name: 'AutomationHome',
  components: {
    SearchBox,
    SettingsSideCard,
    CreateAutomationModal,
    EducationalOnboarding,
    RhsPanel,
    AutomationDetailsModal,
    List,
  },
  mixins: [alertMixin, accountMixin, globalConfigMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      search: '',
      selectedTabIndex: 0,
      showCreateModal: false,
      showDetailsModal: false,
      showDeletePopup: false,
      selectedAutomation: null,
      tableColumns: AUTOMATION_TABLE_COLUMNS,
      tabs: AUTOMATION_TABLE_TABS,
      SHOWCASE_AUTOMATION_DATA,
      selectedTemplateDetails: { name: '', description: '' },
      selectedTemplateId: null,
    };
  },
  computed: {
    ...mapGetters({
      rules: 'automations/getAutomations',
      templates: 'automations/getAutomationTemplates',
      uiFlags: 'automations/getUIFlags',
      globalConfig: 'globalConfig/get',
    }),
    showRules() {
      return this.selectedTabIndex === 0;
    },
    showTemplates() {
      return this.selectedTabIndex === 1;
    },
    actionsList() {
      return this.showRules ? AUTOMATION_TABLE_ACTIONS_LIST : [];
    },
    isLoading() {
      const { isFetching: isFetchingRules, isFetchingTemplates } = this.uiFlags;
      return this.showRules ? isFetchingRules : isFetchingTemplates;
    },
    automationsList() {
      const list = this.showRules ? this.rules : this.templates;

      return list.filter(item => {
        return (
          item.name?.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          item.description?.toLowerCase().search(this.search.toLowerCase()) > -1
        );
      });
    },
    deleteConfirmText() {
      return `${this.$t('AUTOMATION.DELETE.CONFIRM.YES')} ${this
        .selectedAutomation?.name || ''}`;
    },
    deleteRejectText() {
      return `${this.$t('AUTOMATION.DELETE.CONFIRM.NO')} ${this
        .selectedAutomation?.name || ''}`;
    },
    deleteMessage() {
      return `${this.$t('AUTOMATION.DELETE.CONFIRM.MESSAGE')} ${this
        .selectedAutomation?.name || ''}?`;
    },
  },
  created() {
    this.$store.dispatch('automations/get');
  },
  methods: {
    onUpdateTargetRoute(data) {
      this.selectedTemplateDetails = {
        ...this.selectedTemplateDetails,
        ...data,
      };
    },
    onSubmitDetailsModal() {
      this.showDetailsModal = false;
      this.$router.push({
        name: 'create_automation',
        params: {
          template_id: this.selectedTemplateId,
          ...this.selectedTemplateDetails,
        },
      });
      this.selectedTemplateDetails = { name: '', description: '' };
      this.selectedTemplateId = null;
    },
    onTabChange(selectedTabIndex) {
      if (this.selectedTabIndex !== selectedTabIndex) {
        this.selectedAutomation = null;
        this.selectedTabIndex = selectedTabIndex;
        const actionName = `automations/${
          this.selectedTabIndex === 0 ? 'get' : 'getTemplates'
        }`;
        this.$store.dispatch(actionName);
      }
    },
    onHandleCreate(template) {
      this.showDetailsModal = true;
      this.selectedTemplateId = template?.id || null;
    },
    setSearch(value) {
      this.mix_panel_searched_settings_automation();
      this.search = value;
    },
    openAddPopup() {
      this.mixPanelAddAutomationInitiated();
      this.showCreateModal = true;
    },
    hideAddPopup() {
      this.showCreateModal = false;
    },
    openEditPopup(automation) {
      this.selectedAutomation = automation;
      if (this.showTemplates) {
        this.onHandleCreate(automation);
        return;
      }
      let route = this.addAccountScoping(
        `settings/automation/edit/${automation.id}`
      );
      this.$router.push(route);
    },
    viewAutomation(automation) {
      this.selectedAutomation = automation;
    },
    openDeletePopup() {
      this.showDeletePopup = true;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteAutomation(this.selectedAutomation.id);
    },
    async deleteAutomation(id) {
      try {
        await this.$store.dispatch('automations/delete', id);
        this.selectedAutomation = null;
        this.showAlert(
          this.$t('AUTOMATION.DELETE.API.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.DELETE.API.ERROR_MESSAGE'), 'error');
      }
    },
    async cloneAutomation(id) {
      try {
        await this.$store.dispatch('automations/clone', id);
        this.showAlert(
          this.$t('AUTOMATION.CLONE.API.SUCCESS_MESSAGE'),
          'success'
        );
        this.selectedAutomation = null;
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.CLONE.API.ERROR_MESSAGE'), 'error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-box {
  padding-top: $space-largest;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .search-box {
      background: none;
      margin-bottom: $zero;
      width: 30.4rem;
    }

    .settings-side-card {
      padding: $zero;
    }
  }
}
</style>
