<template>
  <div>
    <div class="conversations-sidebar-header">
      <div class="chat-list__top">
        <h1 class="page-title text-truncate title-h1 text-dark">
          <span class="mg-right--smaller">
            <img src="~/assets/images/ticket.svg" />
          </span>
          {{ $t('CHAT_LIST.TAB_HEADING') }}
        </h1>
      </div>
      <div class="search-container">
        <woot-base-button
          variant="secondary"
          icon-size="semimedium"
          front-icon="search"
          icon-color="lightgrey"
          class="search-button full-width"
          @click="onShowSearchModal"
        >
          <span
            class="body-b3 text-dark line-clamp-1"
            v-text="savedSearch.query"
          />
        </woot-base-button>
        <woot-base-button
          class="close-icon"
          size="small"
          tag="span"
          variant="secondary-danger"
          @click="onClearSearch"
        >
          <icons
            name="closeRound"
            color="red"
            size="normal"
            :show-title="false"
          />
        </woot-base-button>
      </div>
    </div>
    <div class="conversations-list">
      <template v-if="showConversationCards">
        <conversation-details-card
          v-for="result in conversations"
          :key="result.id"
          class="conversation-card-cmpt"
          :identifier="result.conversation.identifier"
          :assignee-name="result.conversation.agent_name"
          :contact-name="result.conversation.contact_name"
          :display-id="result.conversation.display_id"
          :content="result.content"
          :data="result"
          :search-term="savedSearch.query"
          @routeNavigated="
            $emit('conversation-load', result.conversation.display_id)
          "
        />
      </template>
      <template v-if="showContactCards">
        <contact-details-card
          v-for="result in conversations"
          :key="result.id"
          class="conversation-card-cmpt"
          :data="result"
          :search-term="savedSearch.query"
          @routeNavigated="
            conversationid => $emit('conversation-load', conversationid)
          "
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import ConversationDetailsCard from 'dashboard/components/ui/ConversationDetailsCard';
import ContactDetailsCard from 'dashboard/components/ui/ContactDetailsCard';

export default {
  components: { ConversationDetailsCard, ContactDetailsCard },
  props: {
    onShowSearchModal: {
      type: Function,
      default: () => {},
    },
    onClearSearch: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      savedSearch: 'conversationSearch/getSavedSearch',
    }),
    conversations() {
      return this.savedSearch.results;
    },
    showConversationCards() {
      if (!this.savedSearch.filters.searchBy) return true;

      const {
        filters: {
          searchBy: { value },
        },
      } = this.savedSearch;

      return !value || value === 'message';
    },
    showContactCards() {
      if (!this.savedSearch.filters.searchBy) return false;

      const {
        filters: {
          searchBy: { value },
        },
      } = this.savedSearch;
      return value === 'contact';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.conversation-card-cmpt {
  border: none;
  border-bottom: 1px solid $neutral-grey-200;
  border-radius: $zero;
  margin-bottom: $zero;
}

.search {
  &-container {
    padding: $space-medium $space-slab $space-small;
    position: relative;
  }

  &-button {
    border: 1px solid $neutral-grey-400;
    color: $text-dark;
    justify-content: flex-start;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: $height-h5;
    text-transform: capitalize;
  }
}

.close-icon {
  top: 29px;
  right: 20px;
  position: absolute;
  border-radius: 50%;
  transform: scale(0.8);
  width: $space-medium + $space-smaller;
}
</style>
