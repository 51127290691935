<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="
          `${$t('EDIT_CONTACT.TITLE')} - ${contact.name || contact.email}`
        "
        :header-content="$t('EDIT_CONTACT.DESC')"
      />
      <form class="row settings-form" @submit.prevent="onSubmit">
        <woot-input
          v-model.trim="name"
          :class="{ error: $v.name.$error }"
          class="medium-12 columns content-margin"
          :label="$t('EDIT_CONTACT.FORM.NAME.LABEL')"
          :placeholder="$t('EDIT_CONTACT.FORM.NAME.PLACEHOLDER')"
          @input="$v.name.$touch"
        />

        <woot-input
          v-model.trim="email"
          :class="{ error: $v.email.$error }"
          :has-error="$v.email.$error"
          :error="$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.ERROR_MESSAGE')"
          class="medium-12 columns content-margin"
          :label="$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.LABEL')"
          :placeholder="$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.PLACEHOLDER')"
          @blur="$v.email.$touch"
        />

        <div class="columns medium-12 content-margin">
          <woot-input
            v-model.trim="description"
            class=""
            rows="3"
            type="text"
            :label="$t('EDIT_CONTACT.FORM.BIO.LABEL')"
            :placeholder="$t('EDIT_CONTACT.FORM.BIO.PLACEHOLDER')"
            @input="$v.description.$touch"
          />
        </div>
        <div style="width: 100%" class="content-margin">
          <div class="row medium-12 flex-space-between">
            <woot-input
              v-model.trim="phoneNumber"
              class="medium-5 columns"
              :class="{ error: $v.phoneNumber.$error }"
              :has-error="$v.phoneNumber.$error"
              :error="null"
              :label="$t('EDIT_CONTACT.FORM.PHONE_NUMBER.LABEL')"
              :placeholder="$t('EDIT_CONTACT.FORM.PHONE_NUMBER.PLACEHOLDER')"
              @blur="$v.phoneNumber.$touch"
            />
            <woot-input
              v-model.trim="companyName"
              class="medium-6 columns"
              :label="$t('EDIT_CONTACT.FORM.COMPANY_NAME.LABEL')"
              :placeholder="$t('EDIT_CONTACT.FORM.COMPANY_NAME.PLACEHOLDER')"
              @input="$v.companyName.$touch"
            />
          </div>
          <span v-if="$v.phoneNumber.$error" class="capital_c3 desc-error">
            {{ this.$t('EDIT_CONTACT.FORM.PHONE_NUMBER.ERROR_MESSAGE') }}
          </span>
        </div>
        <woot-input
          v-if="contact.identifier"
          v-model.trim="identifier"
          class="medium-12 columns content-margin disable-social-input"
          :label="$t('EDIT_CONTACT.FORM.IDENTIFIER.LABEL')"
          :placeholder="$t('EDIT_CONTACT.FORM.IDENTIFIER.PLACEHOLDER')"
        />
        <div class="medium-12 columns">
          <label class="form-label">
            Social Information
          </label>
          <div
            v-for="socialProfile in socialProfileKeys"
            :key="socialProfile.key"
          >
            <div
              class="input-group"
              :class="{
                'disable-social-input':
                  socialProfile.key === 'instagram_username' &&
                  contact.instagram_username !== null,
              }"
            >
              <span class="input-group-label">{{
                socialProfile.prefixURL
              }}</span>

              <input
                v-model="socialProfileUserNames[socialProfile.key]"
                class="input-group-field"
                type="text"
              />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="button clear alert" @click.prevent="onCancel">
            {{ $t('EDIT_CONTACT.FORM.CANCEL') }}
          </button>
          <woot-primary-button
            :loading="uiFlags.isUpdating"
            :name="$t('EDIT_CONTACT.FORM.SUBMIT')"
            front-icon="peopleFilled"
          />
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { DuplicateContactException } from 'shared/helpers/CustomErrors';
import { required, email } from 'vuelidate/lib/validators';
import customValidations from '../../settings/inbox/validations';
import { mapGetters } from 'vuex';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  mixins: [alertMixin, googleAnalyticsMixin, mixPanelAnalyticsMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    parentComponentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasADuplicateContact: false,
      duplicateContact: {},
      companyName: '',
      description: '',
      email: '',
      identifier: '',
      name: '',
      phoneNumber: '',
      socialProfileUserNames: {
        instagram_username: '',
        facebook: '',
        twitter: '',
        linkedin: '',
      },
      socialProfileKeys: [
        {
          key: 'instagram_username',
          prefixURL: 'https://instagram.com/',
          editable: true,
        },
        {
          key: 'facebook',
          prefixURL: 'https://facebook.com/',
          editable: true,
        },
        {
          key: 'twitter',
          prefixURL: 'https://twitter.com/',
          editable: true,
        },
        {
          key: 'linkedin',
          prefixURL: 'https://linkedin.com/',
          editable: true,
        },
      ],
    };
  },
  validations: {
    name: {
      required,
    },
    description: {},
    email: { email },
    companyName: {},
    phoneNumber: { ...customValidations.postiveNumber },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
  },
  watch: {
    contact() {
      this.setContactObject();
    },
  },
  mounted() {
    this.setContactObject();
  },
  methods: {
    getCart() {
      const { phone_number: phoneNumber, _email, name } = this.contact;
      if (!phoneNumber && !_email) {
        this.$store.dispatch('emptyCustomerCart');
        this.$store.dispatch('clearCustomer');
        return;
      }
      const payload = {
        phone_number: phoneNumber || '',
        email: _email || '',
        firstName: name || '',
      };
      try {
        this.$store.dispatch('getCustomerCart', payload);
      } catch (error) {
        // handle error
      }
    },
    onCancel() {
      if (this.parentComponentName === 'ContactInfo') {
        this.mix_panel_clicked_contact_edit_cancel();
      }
      this.$emit('cancel');
    },
    setContactObject() {
      const {
        email: contactEmail,
        phone_number: phoneNumber,
        name,
      } = this.contact;
      const additionalAttributes = this.contact.additional_attributes || {};

      this.name = name || '';
      this.email = contactEmail || '';
      this.identifier = this.contact.identifier || '';
      this.phoneNumber = phoneNumber || '';
      this.companyName = additionalAttributes.company_name || '';
      this.description = additionalAttributes.description || '';
      const {
        social_profiles: socialProfiles = {},
        screen_name: twitterScreenName,
      } = additionalAttributes;
      this.socialProfileUserNames = {
        instagram_username: this.contact.instagram_username,
        twitter: socialProfiles.twitter || twitterScreenName || '',
        facebook: socialProfiles.facebook || '',
        linkedin: socialProfiles.linkedin || '',
      };
    },
    getContactObject() {
      return {
        id: this.contact.id,
        name: this.name,
        email: this.email,
        identifier: this.identifier,
        instagram_username: this.socialProfileUserNames.instagram_username,
        phone_number: this.phoneNumber,
        additional_attributes: {
          ...this.contact.additional_attributes,
          description: this.description,
          company_name: this.companyName,
          social_profiles: this.socialProfileUserNames,
        },
      };
    },
    resetDuplicate() {
      this.hasADuplicateContact = false;
      this.duplicateContact = {};
    },
    async onSubmit() {
      this.googleAnalyticsEvent(
        types.default.CONTACT_EDITED,
        types.default.CONTACT_INFO_OR_PREVIOUS_CONVO,
        types.default.CONTACT_INFO_OR_PREVIOUS_CONVO
      );
      this.resetDuplicate();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('EDIT_CONTACT.FORM_ERROR'), 'error');
        return;
      }
      try {
        await this.$store
          .dispatch('contacts/update', this.getContactObject())
          .then(() => {
            this.showAlert(this.$t('EDIT_CONTACT.SUCCESS_MESSAGE'), 'success');
            this.$store.dispatch('resetDraftOrder');
            this.getCart();
            if (this.parentComponentName === 'ContactInfo') {
              this.mix_panel_clicked_contact_edit_submit();
            }
          });
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          this.hasADuplicateContact = true;
          this.duplicateContact = error.data;
          this.showAlert(this.$t('EDIT_CONTACT.CONTACT_ALREADY_EXIST'), 'info');
        } else {
          this.showAlert(this.$t('EDIT_CONTACT.ERROR_MESSAGE'), 'error');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-form {
  padding: $space-slab $space-medium;
}

.input-group-label {
  width: $space-normal * 10;
}
.content-margin {
  margin-bottom: 2.4rem;
}
.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}
.disable-social-input {
  pointer-events: none;
}
</style>
