export default {
  methods: {
    createCallInstance({
      agentNumber,
      agentRole,
      agentName,
      customerNumber = '',
      customerName = null,
      chat = null,
      activeInbox = null,
    }) {
      bus.$emit('initiateCallInstance', {
        agentNumber,
        agentRole,
        agentName,
        customerNumber,
        customerName,
        chat,
        activeInbox,
      });
    },
  },
};
