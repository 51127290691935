import { mapGetters } from 'vuex';
import countries from 'shared/constants/countries';
import {
  generateCustomAttributeTypes,
  getActionOptions,
  getConditionOptions,
  getCustomAttributeInputType,
  getOperatorTypes,
  isACustomAttribute,
  getFileName,
  getDefaultConditions,
  getDefaultActions,
  generateAutomationPayload,
  getStandardAttributeInputType,
  generateCustomAttributes,
} from '../automationHelper';
import { MODES, ERROR_PREFIX, DEFAULT_AUTOMATION_PAYLOAD } from '../constants';
import { validateAutomationRule } from '../validations';
import showError from 'dashboard/helper/showError';

export default {
  computed: {
    ...mapGetters({
      agentRecords: 'agents/getAgents',
      contacts: 'contacts/getContacts',
      inboxes: 'inboxes/getInboxes',
      labels: 'labels/getLabelsWithoutGroups',
      contactLabels: 'labels/getContactLabelsWithoutGroups',
      teamRecords: 'teams/getTeams',
    }),
    agents() {
      return [{ id: 0, name: 'None' }, ...this.agentRecords];
    },
    teams() {
      return [{ id: 0, name: 'None' }, ...this.teamRecords];
    },
    booleanFilterOptions() {
      return [
        {
          id: true,
          name: this.$t('FILTER.ATTRIBUTE_LABELS.TRUE'),
        },
        {
          id: false,
          name: this.$t('FILTER.ATTRIBUTE_LABELS.FALSE'),
        },
      ];
    },

    statusFilterOptions() {
      const statusFilters = this.$t('CHAT_LIST.CHAT_STATUS_ITEMS');
      return [
        ...Object.keys(statusFilters).map(status => {
          return {
            id: statusFilters[status].VALUE,
            name: statusFilters[status].NAME,
          };
        }),
      ];
    },
  },
  methods: {
    getFileName,
    getAttributes(key) {
      return this.automationTypes[key].conditions;
    },
    getInputType(key) {
      if (!key) return '';

      const customAttribute = isACustomAttribute(this.allCustomAttributes, key);
      if (customAttribute) {
        return getCustomAttributeInputType(
          customAttribute.attribute_display_type
        );
      }
      const type = this.getAutomationType(key);
      return type.inputType;
    },
    getLabelKey(key) {
      return this.getAutomationType(key)?.labelKey;
    },
    getOperators(key) {
      if (!key) return [];

      if (this.mode === MODES.EDIT) {
        const customAttribute = isACustomAttribute(
          this.allCustomAttributes,
          key
        );
        if (customAttribute) {
          return getOperatorTypes(customAttribute.attribute_display_type);
        }
      }
      const type = this.getAutomationType(key);

      return type.filterOperators;
    },
    getAutomationType(key) {
      return this.automationTypes[this.automation.event_name].conditions.find(
        condition => condition.key === key
      );
    },
    getCustomAttributeType(key) {
      const type = this.automationTypes[
        this.automation.event_name
      ].conditions.find(i => i.key === key).customAttributeType;
      return type;
    },
    getConditionDropdownValues(type) {
      const {
        agents,
        allCustomAttributes: customAttributes,
        booleanFilterOptions,
        contacts,
        inboxes,
        identifiers,
        statusFilterOptions,
        teams,
        contactLabels,
        labels,
      } = this;

      return getConditionOptions({
        agents,
        booleanFilterOptions,
        contacts,
        customAttributes,
        inboxes,
        identifiers,
        statusFilterOptions,
        teams,
        countries,
        type,
        contactLabels,
        labels,
      });
    },
    appendNewCondition() {
      this.automation.conditions.push(...getDefaultConditions());
    },
    appendNewAction() {
      this.automation.actions.push(...getDefaultActions());
    },
    removeFilter(index) {
      if (this.automation.conditions.length <= 1) {
        this.showAlert(this.$t('AUTOMATION.CONDITION.DELETE_MESSAGE'));
      } else {
        this.automation.conditions.splice(index, 1);
      }
    },
    removeAction(index) {
      if (this.automation.actions.length <= 1) {
        this.showAlert(this.$t('AUTOMATION.ACTION.DELETE_MESSAGE'));
      } else {
        this.automation.actions.splice(index, 1);
      }
    },
    isFormValid(payload) {
      const [
        errorMessage,
        isValid,
        errorneousFieldClassName,
        specificFieldSelector,
      ] = validateAutomationRule(payload);

      showError({
        message: this.$t(`${ERROR_PREFIX}.FORM.ERRORS.${errorMessage}`),
        isValid,
        errorneousFieldClassName: `${ERROR_PREFIX}_${errorneousFieldClassName}`,
        specificFieldSelector,
      });

      return isValid;
    },
    submitAutomation() {
      const isValid = this.isFormValid(this.automation);

      if (isValid) {
        const automation = generateAutomationPayload(this.automation);
        this.$emit('saveAutomation', automation);
      }
    },
    resetFilter(index, currentCondition) {
      this.automation.conditions[index].filter_operator = this.automationTypes[
        this.automation.event_name
      ].conditions.find(
        condition => condition.key === currentCondition.attribute_key
      ).filterOperators[0].value;
      this.automation.conditions[index].values = '';
    },
    showUserInput(type) {
      return !(type === 'is_present' || type === 'is_not_present');
    },
    showActionInput(action) {
      if (
        ['send_email_to_team', 'send_message', 'send_email'].includes(action)
      ) {
        return false;
      }

      const type = this.automationActionTypes.find(i => i.key === action)
        .inputType;
      return !!type;
    },
    resetAction(index) {
      this.automation.actions[
        index
      ].action_params = this.generateActionParamsArray(
        this.automation.actions[index]
      );
    },
    manifestConditions(automation) {
      if (!automation.event_name) return automation.conditions;

      if (!automation.conditions.length)
        return DEFAULT_AUTOMATION_PAYLOAD.conditions;

      return automation.conditions.map(condition => {
        const inputType = getStandardAttributeInputType(
          this.automationTypes,
          automation.event_name,
          condition.attribute_key
        );

        const baseCondition = {
          ...condition,
          query_operator: condition.query_operator || 'and',
        };

        switch (inputType) {
          case 'plain_text':
            return {
              ...baseCondition,
              values: condition.values[0] || '',
            };
          case 'date':
            return {
              ...baseCondition,
              values: condition.values[0] || new Date(),
            };
          case 'array[text]':
            return {
              ...baseCondition,
              values: condition?.values?.length ? [...condition.values] : [],
            };
          default:
            return {
              ...baseCondition,
              values: condition.values
                ? this.getConditionDropdownValues(
                    condition.attribute_key
                  )?.filter(item => condition.values.includes(item.id))
                : [],
            };
        }
      });
    },
    generateActionParamsArray(action) {
      const params = action?.action_params || [];
      let actionParams = [];
      const actionType = this.automationActionTypes.find(
        item => item.key === action.action_name
      );
      const inputType = actionType ? actionType.inputType : '';

      switch (inputType) {
        case 'multi_select':
        case 'search_select':
          actionParams = this.getActionDropdownValues(
            action.action_name
          ).filter(item => params.includes(item.id));
          break;
        case 'text_with_variables':
          actionParams = params[0];
          break;
        case 'team_message':
          actionParams = {
            team_ids: this.getActionDropdownValues(
              action.action_name
            ).filter(item => params[0].team_ids.includes(item.id)),
            message: params[0].message,
          };
          break;
        case null:
          actionParams = null;
          break;
        default:
          actionParams = [...params];
      }

      return actionParams;
    },
    manifestActions(automation) {
      if (!automation.actions.length) return DEFAULT_AUTOMATION_PAYLOAD.actions;

      return automation.actions.map(action => {
        const actionParams = this.generateActionParamsArray(action);

        return {
          ...action,
          action_params: actionParams,
        };
      });
    },
    formatAutomation(automation) {
      this.automation = {
        ...automation,
        conditions: this.manifestConditions(automation),
        actions: this.manifestActions(automation),
      };
    },
    getActionDropdownValues(type) {
      const { agents, labels, contactLabels, teams } = this;
      return getActionOptions({
        agents,
        labels: labels.filter(label => label.active),
        contactLabels: contactLabels.filter(label => label.active),
        teams,
        type,
      });
    },
    manifestCustomAttributes() {
      const conversationCustomAttributesRaw = this.$store.getters[
        'attributes/getAttributesByModel'
      ]('conversation_attribute');

      const contactCustomAttributesRaw = this.$store.getters[
        'attributes/getAttributesByModel'
      ]('contact_attribute');
      const conversationCustomAttributeTypes = generateCustomAttributeTypes(
        conversationCustomAttributesRaw,
        'conversation_attribute'
      );
      const contactCustomAttributeTypes = generateCustomAttributeTypes(
        contactCustomAttributesRaw,
        'contact_attribute'
      );
      let manifestedCustomAttributes = generateCustomAttributes(
        conversationCustomAttributeTypes,
        contactCustomAttributeTypes,
        this.$t('AUTOMATION.CONDITION.CONVERSATION_CUSTOM_ATTR_LABEL'),
        this.$t('AUTOMATION.CONDITION.CONTACT_CUSTOM_ATTR_LABEL')
      );
      this.automationTypes.message_created.conditions.push(
        ...manifestedCustomAttributes
      );
      this.automationTypes.conversation_created.conditions.push(
        ...manifestedCustomAttributes
      );
      this.automationTypes.conversation_updated.conditions.push(
        ...manifestedCustomAttributes
      );
    },
  },
};
