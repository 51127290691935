import { required, url, integer, decimal } from 'vuelidate/lib/validators';
import { isValidPhoneNumber } from 'libphonenumber-js';

// const checkValidName = value => /^[a-zA-Z ]+$/.test(value);

const isPostiveNumber = value => {
  if (!value) return true;
  return value >= 0;
};

const validImageUrl = value => {
  let pattern = /(http[s]*:\/\/)([a-z\-_0-9/.]+)\.([a-z.]{2,3})\/([a-z0-9\-_/._~:?#[\]@!$&'()*+,;=%]*)([a-z0-9]+\.)(png|jpg|gif|svg|jpeg)/i;
  return pattern.test(value);
};

const checkValidPhoneNumber = value => {
  if (!value) {
    return true;
  }
  return isValidPhoneNumber('+' + value);
};

const mustBeSmallWidgetImage = value => {
  // eslint-disable-next-line dot-notation
  if (value['image']) {
    // eslint-disable-next-line dot-notation
    let imgUrl = value['image'];
    if (imgUrl.match(/\.(jpeg|jpg|gif|png)$/) !== null) {
      return true;
    }

    return false;
  }

  return true;
};

const mustBeSmallFirstPopupImage = value => {
  // eslint-disable-next-line dot-notation
  if (value['firsttimepopupImage']) {
    // eslint-disable-next-line dot-notation
    let imgUrl = value['firsttimepopupImage'];
    if (imgUrl.match(/\.(jpeg|jpg|gif|png)$/) !== null) {
      return true;
    }

    return false;
  }

  return true;
};

const containsOnlyAlphabets = value => {
  return /^[A-Za-z\s]*$/.test(value);
};
export default {
  channelWidgetFirstTimePopUpsetting: {
    mustBeSmallFirstPopupImage,
  },
  channelWidgetConfigsetting: {
    mustBeSmallWidgetImage,
  },
  channelWebsiteUrl: { required, url },
  autoCloseDuration: { required, integer, isPostiveNumber },
  autoResolveDuration: { required, integer, isPostiveNumber },
  autoResolveBotDuration: { required, integer, isPostiveNumber },
  autoAlertDuration: { required, integer, isPostiveNumber },
  timeDelay: { required, integer, isPostiveNumber },
  phone: { checkValidPhoneNumber },
  notNumeric: { containsOnlyAlphabets },
  widgetZindex: { required, integer },
  validImage: { validImageUrl },
  popupDelay: { required, decimal, isPostiveNumber },
  postiveNumber: { isPostiveNumber, integer },
  knowlarityInboxName: { required },
};
