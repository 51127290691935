<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <page-header
      :header-title="$t('CONVERSATION.FILTER.TITLE')"
      :show-border="false"
    />
    <div class="filter-feature">
      <div class="conversation-filters">
        <conversation-filter-tab
          v-if="appliedConversationFiltersCopy"
          :key="componentKey"
          ref="conversationFilterTab"
          :filter-list="filterOptions"
          :active-tab="activeAssigneeTab"
          :applied-filters="appliedConversationFiltersCopy"
          parent-component-name="Ticket Filter"
          @filterChange="updateFilterType"
        />
      </div>
      <div class="search-results-container">
        <div
          class="flex-row flex-align flex-justify--between mg-bottom--smaller"
        >
          <div>
            <span
              class="text-light body-b2"
              v-text="$t('CONVERSATION.FILTER.RESULTS.HEADING')"
            />
            <transition name="modal-fade">
              <span
                v-if="resultsCount > 3"
                class="text-dark title-h5_m"
                v-text="`(${resultsCount})`"
              />
            </transition>
          </div>
          <div class="conversation-sort-filter">
            <conversation-sort-filter
              :key="componentKey"
              ref="conversationSortFilter"
              variant="tertiary"
              @applyFilter="updateFilterType"
            />
          </div>
        </div>
        <div class="results custom-scroll">
          <transition name="modal-fade" mode="out-in" appear>
            <div v-if="showConversationCards" class="result-cards">
              <conversation-details-card
                v-for="result in filteredResults"
                :key="result.id"
                :identifier="result.identifier"
                :assignee-name="
                  result.meta.assignee && result.meta.assignee.name
                "
                :contact-name="result.meta.sender.name"
                :display-id="result.id"
                :content="lastMessage(result)"
                :data="result"
                @routeNavigated="onClose"
              />
            </div>
            <loading-state v-if="isFetchingResults" class="loading-state" />
            <img
              v-if="!isFetchingResults && !filteredResults.length"
              :src="require('dashboard/assets/images/search_not_found.svg')"
              class="zero-state"
              alt="no-results-found"
            />
          </transition>
          <transition name="modal-fade" mode="out-in" appear>
            <div v-if="showLoadMore" class="flex-row flex-justify">
              <woot-base-button
                variant="tertiary"
                size="small"
                @click="loadMoreResults"
              >
                {{ $t('CONVERSATION.FILTER.RESULTS.LOAD_MORE') }}
              </woot-base-button>
            </div>
            <div
              v-if="showEndOfResultsText"
              class="text-center body-b2 text-light"
              v-text="$t('CONVERSATION.FILTER.RESULTS.END_OF_RESULTS')"
            />
          </transition>
        </div>
      </div>
    </div>
    <div class="medium-12 columns settings-sub-menu-bottom-nav">
      <woot-base-button
        size="small"
        variant="secondary-danger"
        @click="resetAllFilters"
      >
        {{ $t('CONVERSATION.FILTER.RESET') }}
      </woot-base-button>
      <woot-base-button size="small" @click="saveFilter">
        {{ $t('CONVERSATION.FILTER.APPLICATION.TITLE') }}
      </woot-base-button>
    </div>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import ConversationFilterTab from 'dashboard/components/widgets/conversation/filter/ConversationFiltersDeprecated';
import ConversationDetailsCard from 'dashboard/components/ui/ConversationDetailsCard';
import LoadingState from 'dashboard/components/ui/LoadingState';
import ConversationSortFilter from 'dashboard/components/widgets/conversation/filter/ConversationSortFilter';

import alertMixin from 'shared/mixins/alertMixin';
import conversationMixin from 'dashboard/mixins/conversations';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import assigneeTypeMixin from 'dashboard/mixins/assigneeTypeMixin';

import wootConstants from 'dashboard/constants';
import { FILTERS, SLA_BREACHED_FILTER } from './constants';

export default {
  components: {
    PageHeader,
    ConversationFilterTab,
    ConversationDetailsCard,
    LoadingState,
    ConversationSortFilter,
  },
  mixins: [
    alertMixin,
    conversationMixin,
    mixPanelAnalyticsMixin,
    assigneeTypeMixin,
  ],
  props: {
    assigneeTab: { type: String, default: 'me' },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      runOnce: true,
      isFetchingResults: false,
      endReached: false,
      currentPage: 1,
      componentKey: 1,
      resultsCount: 0,
      activeAssigneeTab: wootConstants.ASSIGNEE_TYPE.ME,
      activeStatus: wootConstants.STATUS_TYPE.OPEN,
      appliedConversationFiltersCopy: null,
      filteredResults: [],
      wasReset: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      appliedConversationFilters: 'getConversationFilters',
      inboxes: 'inboxes/getInboxes',
      agents: 'agents/getAgents',
      accountLabels: 'labels/getLabelsWithoutGroups',
      canShowSlaBreachedFilter: 'accounts/getEnableSlaBreachedFilter',
    }),
    filterOptions() {
      let filters = JSON.parse(JSON.stringify(FILTERS));
      if (this.canShowSlaBreachedFilter) {
        filters[2].FILTERS.push(SLA_BREACHED_FILTER);
        return filters;
      }
      return filters;
    },
    showEndOfResultsText() {
      return (
        this.endReached &&
        this.filteredResults.length &&
        !this.isFetchingResults
      );
    },
    showLoadMore() {
      return (
        this.showConversationCards &&
        !this.endReached &&
        this.filteredResults.length &&
        !this.isFetchingResults
      );
    },
    showConversationCards() {
      return this.filteredResults.length && !this.isFetchingResults;
    },
    currentInboxId() {
      return this.$route.params.inbox_id;
    },
  },
  created() {
    this.appliedConversationFiltersCopy = {
      ...this.appliedConversationFilters,
    };
  },
  mounted() {
    this.setDefaultAssigneeTabOption();
    this.setRelevanceFilterState();
    this.setConversationSortState();
  },
  methods: {
    setRelevanceFilterState() {
      const { unread, alert, starred } = this.appliedConversationFiltersCopy;

      // set relevance filter
      const sortByBools = {
        unread,
        alert,
        starred,
      };

      // This is done to preserve the applied relevance filter on subsequent mounts of the component
      this.$refs.conversationFilterTab.$refs.multiSelector[0].selectedOption = Object.keys(
        sortByBools
      ).filter(key => sortByBools[key]);

      this.$refs.conversationFilterTab.$refs.multiSelector[0].selectedOptionId = Object.keys(
        sortByBools
      ).reduce(
        (acc, cur, idx) => (sortByBools[cur] ? [...acc, idx + 1] : acc),
        []
      );
    },
    setConversationSortState() {
      const { isNew: newest, oldest } = this.appliedConversationFiltersCopy;

      const sortByBools = {
        newest,
        oldest,
      };

      // set conversation sort filter
      this.$refs.conversationSortFilter.selectedFilterName = Object.keys(
        sortByBools
      ).filter(key => sortByBools[key]);
    },
    lastMessage(chat) {
      return chat.messages.last().content;
    },
    saveFilter() {
      this.mix_panel_clicked_ticket_save_filter();
      let inboxId = this.appliedConversationFiltersCopy.inboxId;
      if (!inboxId) {
        this.$router.push(`/app/accounts/${this.accountId}/dashboard`);
      } else
        this.$router.push(`/app/accounts/${this.accountId}/inbox/${inboxId}`);

      this.$store.dispatch('setChatStatus', this.activeStatus);
      this.$store.dispatch(
        'setConversationFilters',
        this.appliedConversationFiltersCopy
      );
      this.onClose();
    },
    resetAllFilters() {
      this.wasReset = true;
      this.mix_panel_clicked_ticket_save_filter();
      this.activeAssigneeTab = wootConstants.ASSIGNEE_TYPE.ME;
      this.activeStatus = wootConstants.STATUS_TYPE.OPEN;
      this.filteredResults = [];
      [
        'selectedAgent',
        'teamId',
        'identifier',
        'assigned_by',
        'resolved_by',
        'closed_by',
        'labels',
      ].map(operator => this.updateConversationFilter(operator, undefined));
      this.updateConversationFilter('assigneeType', this.activeAssigneeTab);
      this.updateConversationFilter('status', this.activeStatus);
      this.updateConversationFilter('inboxId', undefined);
      this.updateConversationFilter('statusTimeRange', 2);
      this.setRelevanceFilter([]);
      this.setConversationSortFilter([]);
      this.$store.dispatch('resetConversationFilters');
      this.$router.push(`/app/accounts/${this.accountId}/dashboard`);
      this.componentKey += 1;
      this.wasReset = false;
    },
    updateConversationFilter(key, value) {
      if (key !== 'page' && !this.runOnce && !this.wasReset) {
        switch (key) {
          case 'statusTimeRange': {
            this.mix_panel_clicked_date_filter_menu_item(
              'Ticket Filter',
              value.date_range_name
            );
            break;
          }
          case 'inboxId': {
            const filteredInbox = this.inboxes.find(
              inbox => inbox.id === value
            );
            if (filteredInbox)
              this.mix_panel_clicked_inbox_filter_menu_item(
                'Ticket Filter',
                filteredInbox.channel_type
              );
            break;
          }
          case 'selectedAgent': {
            const filteredAgent = this.agents.find(agent => agent.id === value);
            if (filteredAgent)
              this.mix_panel_clicked_agent_filter_menu_item(
                'Ticket Filter',
                filteredAgent.role
              );
            break;
          }
          case 'teamId': {
            this.mix_panel_clicked_ticket_team_filter();
            break;
          }
          case 'assigned_by': {
            this.mix_panel_clicked_ticket_assigned_by(value || 'all');
            break;
          }
          default:
            break;
        }
      }

      this.appliedConversationFiltersCopy[key] = value;
    },
    updateFilterType(key, value) {
      this.currentPage = 1;
      this.updateConversationFilter('page', this.currentPage);

      switch (key) {
        case 'assignee_type':
          this.updateAssigneeTab(value);
          break;

        case 'facebook':
          this.updateConversationFilter('identifier', value);
          break;

        case 'assigned_by':
          this.updateConversationFilter('assigned_by', value);
          break;

        case 'resolved_by':
          this.updateConversationFilter('resolved_by', value);
          break;

        case 'closed_by':
          this.updateConversationFilter('closed_by', value);
          break;

        case 'label':
          this.updateConversationFilter('labels', value);
          break;

        case 'teams':
          this.updateConversationFilter('teamId', value);
          break;

        case 'agent':
          this.updateConversationFilter('selectedAgent', value);
          break;

        case 'change_inbox':
          this.updateConversationFilter('inboxId', value.id || undefined);
          break;

        case 'status_time':
          this.updateConversationFilter('statusTimeRange', value);
          break;

        case 'conversation_status':
          this.activeStatus = value;
          this.updateConversationFilter('status', value);
          break;

        case 'relevance':
          this.setRelevanceFilter(value);
          break;

        case 'sort':
          this.setConversationSortFilter(value);
          break;

        case 'sla_breached':
          this.updateConversationFilter('sla_breached', value);
          break;

        default:
          this.showAlert(
            this.$t('CONVERSATION.FILTER.APPLICATION.ERROR'),
            'error'
          );
          break;
      }

      if (this.runOnce) {
        this.updateConversationFilter('inboxId', this.currentInboxId);
        this.setDefaultAssigneeTabOption();
      }
      this.runOnce = false;

      this.fetchResults();
    },
    setDefaultAssigneeTabOption() {
      const activeTab = this.assigneeTabsList.find(
        tab => tab.key === this.assigneeTab
      );

      this.activeAssigneeTab = activeTab.key;
      this.updateConversationFilter('assigneeType', this.activeAssigneeTab);
    },
    updateAssigneeTab(value) {
      this.currentPage = 1;
      this.activeAssigneeTab = value.key;
      this.updateConversationFilter('page', this.currentPage);
      this.updateConversationFilter('assigneeType', this.activeAssigneeTab);
      this.fetchResults();
    },
    loadMoreResults() {
      this.currentPage += 1;
      this.updateConversationFilter('page', this.currentPage);
      this.fetchResults(true);
    },
    appendResults(results) {
      const newResults = [...this.filteredResults];
      results.forEach(result => {
        const indexInCurrentList = newResults.findIndex(
          r => r.id === result.id
        );
        if (indexInCurrentList < 0) newResults.push(result);
      });
      this.filteredResults = newResults;
    },
    fetchResults(append = false) {
      this.isFetchingResults = true;
      this.endReached = false;

      this.$store
        .dispatch('fetchConversations', this.appliedConversationFiltersCopy)
        .then(response => {
          this.isFetchingResults = false;
          const results = response.payload;

          const {
            mine_count: me,
            unassigned_count: unassigned,
            all_count: all,
          } = response.meta;

          const countMap = { me, unassigned, all };
          this.resultsCount = countMap[this.activeAssigneeTab];

          if (!results.length) this.endReached = true;

          if (append) this.appendResults(results);
          else this.filteredResults = results;
        })
        .catch(() => {
          this.showAlert(
            this.$t('CONVERSATION.FILTER.RESULTS.ERROR_MESSAGE'),
            'error'
          );
          this.isFetchingResults = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.filter {
  &-feature {
    padding: $zero $space-medium;

    .search-results-container {
      border-top: 1px solid $neutral-grey-400;
      margin-top: $space-slab;
      padding: $space-slab $zero;

      .results {
        height: $space-three * 100;
        position: relative;

        .loading-state {
          height: 36vh;
        }

        .zero-state {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .result-cards {
          padding-right: $space-smaller;
        }
      }
    }
  }
}

.settings-sub-menu-bottom-nav {
  justify-content: space-between;
  padding: $space-two $space-medium;
  position: relative;
}
</style>
