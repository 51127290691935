<template>
  <modal :show.sync="show" :on-close="onClose" :show-close="false">
    <div class="column content-box">
      <woot-modal-header
        :header-title="
          `${selectedCount} ${$t('CONVERSATION.BULK_MODAL_HEADER')}`
        "
      />
      <form class="row settings-form" @submit.prevent="modifyConv">
        <div class="medium-12 content-margin">
          <label class="form-label">
            {{ $t('INBOX_MGMT.ADD.AGENTS.TITLE') }}
          </label>
          <woot-single-selector
            size="large"
            :custom-style="{ width: '100%' }"
            :option-list="agentList"
            :default-option="selectedAgent"
            @click="onAgentSelect"
          />
        </div>

        <div class="medium-12 content-margin">
          <label class="form-label">
            {{ 'Assign Status' }}
          </label>
          <woot-single-selector
            size="large"
            :custom-style="{ width: '100%' }"
            :option-list="getActionList"
            :show-all="true"
            :default-option="currentStatus"
            @click="toggleStatus"
          />
        </div>

        <div v-if="waitTimeOptions" class="medium-12 row content-margin">
          <span class="form-label medium-12">
            {{ 'Set the time for alert reminders' }}
          </span>
          <div class="content-margin">
            <button
              v-for="(item, index) in quickAction"
              :key="item.NAME"
              type="button"
              class="button hollow quick-button"
              :class="{ 'active-quick': index === selectedQuickAction }"
              @click="setDelay(item.VALUE, index)"
            >
              {{ item.NAME }}
            </button>
          </div>
          <span class="form-label medium-12">
            {{ 'Or select manually' }}
          </span>
          <div class="limechat-timepicker row">
            <div class="column flex-row-justify-center picker-container">
              <vue-timepicker
                v-model="alertDelay"
                hide-clear-button
                :minute-interval="10"
                format="HH:mm"
              >
              </vue-timepicker>
            </div>
          </div>
        </div>

        <div class="medium-12 content-margin">
          <label class="form-label">
            {{ $t('CONVERSATION.ASSIGN_LABELS') }}
          </label>
          <div v-if="activeList.length" style="margin-bottom: 1.2rem">
            <woot-chips
              v-for="label in activeList"
              :key="label.id"
              variant="primary-small"
              :bg-color="label.color"
              :title="label.title"
              :description="label.description"
              :show-cancel-icon="true"
              @click="onRemove"
            />
          </div>
          <woot-single-selector
            size="large"
            :option-list="inactiveList"
            :custom-style="{ width: '100%' }"
            :default-option="$t('CONVERSATION.ASSIGN_LABELS')"
            @click="onSelect"
          />
        </div>

        <div class="medium-12 content-margin">
          <div v-if="showCannedResponsesList" class="canned-container">
            <canned-response
              data-dropdown-menu
              :without-padding="true"
              :on-keyenter="replaceText"
              :on-click="replaceText"
            />
            <button
              type="button"
              class="cancel-button"
              @click="hideCannedResponse"
            >
              <icons name="cancel" color="stroke-red" size="semimedium" />
            </button>
          </div>
          <woot-input
            v-model="message"
            :placeholder="this.$t('CONVERSATION.FOOTER.MSG_INPUT')"
            :rows="3"
          />
        </div>

        <div class="medium-12 content-margin">
          <label class="form-label">
            Actions
          </label>
          <woot-action-button
            :active="isStarred"
            name="Mark Starred"
            icon="starStroke"
            @click="toggleStarred"
          />
        </div>

        <div class="modal-footer">
          <button
            class="button clear flex-row-justify-center clear-all"
            type="button"
            @click.prevent="onClear"
          >
            <icons name="cancel" color="stroke-red" size="semimedium" />
            {{ $t('CONVERSATION.CLEAR_ALL') }}
          </button>
          <div class="flex-row-justify-center">
            <button class="button clear red" @click.prevent="onClose">
              {{ $t('LABEL_MGMT.FORM.CANCEL') }}
            </button>
            <woot-primary-button
              :name="$t('CONVERSATION.APPLY_CHANGE')"
              size="medium"
              :loading="isLoading"
              front-icon="verified"
            />
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import Modal from 'dashboard/components/Modal';
import CannedResponse from './CannedResponse';
import alertMixin from 'shared/mixins/alertMixin';
import VueTimepicker from 'vue2-timepicker';
import { mapGetters } from 'vuex';
import wootConstants from 'dashboard/constants';
import { isObjectEqual } from 'dashboard/helper/commons';
import mixpanelEvent, {
  mixpanelEventWithProps,
} from 'dashboard/helper/mixpanel';

export default {
  components: {
    VueTimepicker,
    Modal,
    CannedResponse,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: true,
    },
    selectedConv: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => {},
    },
    selectedCount: {
      type: Number,
      default: 0,
    },
    selectAll: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      agent: -1,
      status: null,
      actionList: [
        'CONVERSATION.HEADER.RESOLVE_ACTION',
        'CONVERSATION.HEADER.REOPEN_ACTION',
      ],
      message: '',
      selectedLabels: [],
      isStarred: false,
      isLoading: false,
      alertDelay: { HH: '00', mm: '00' },
      selectedQuickAction: null,
      waitTimeOptions: false,
      showCannedResponsesList: false,
    };
  },
  computed: {
    ...mapGetters({
      agents: 'agents/getVerifiedAgents',
      accountLabels: 'labels/getLabelsWithoutGroups',
    }),
    quickAction() {
      return wootConstants.QUICK_ACTION_ALERT_FOR_NON_EMAIL;
    },
    agentList() {
      return [
        {
          confirmed: true,
          name: 'None',
          id: 0,
          role: 'agent',
          account_id: 0,
          email: 'None',
        },
        ...this.agents,
      ];
    },
    selectedAgent() {
      let selectedIndex = this.agentList.findIndex(
        item => item.id === this.agent
      );
      return selectedIndex > -1
        ? this.agentList?.[selectedIndex].name
        : 'Assign Agents';
    },
    getActionList() {
      return this.$t('CHAT_LIST.CHAT_STATUS_ITEMS').reduce((filtered, item) => {
        if (!['bot', 'closed'].includes(item.VALUE)) {
          filtered.push({
            id: item.VALUE,
            name: item.NAME,
          });
        }

        return filtered;
      }, []);
    },
    currentStatus() {
      return this.status === null ? 'Assign status' : this.status;
    },
    activeList() {
      return this.accountLabels.filter(({ title }) =>
        this.selectedLabels.includes(title)
      );
    },
    inactiveList() {
      let reducedLabels = this.accountLabels.map(item => {
        return {
          name: item.title,
          id: item.id,
        };
      });

      return reducedLabels.filter(
        accountLabel => !this.selectedLabels.includes(accountLabel.name)
      );
    },
  },
  watch: {
    status() {
      if (this.status === 'waiting' || this.status === 'followup') {
        this.waitTimeOptions = true;
      } else {
        this.waitTimeOptions = false;
      }
    },
    message(updatedMessage) {
      const isSlashCommand = updatedMessage[0] === '/';
      const hasNextWord = updatedMessage.includes(' ');
      const isShortCodeActive = isSlashCommand && !hasNextWord;
      if (isShortCodeActive) {
        this.showCannedResponsesList = true;
        if (updatedMessage.length > 1) {
          const searchKey = updatedMessage.substr(1, updatedMessage.length);
          this.$store.dispatch('getCannedResponse', { searchKey });
        } else {
          this.$store.dispatch('getCannedResponse');
        }
      } else {
        this.showCannedResponsesList = false;
      }
    },
  },
  methods: {
    onAgentSelect(val) {
      this.agent = val.id;
    },
    toggleStatus(val) {
      this.status = val.id;
    },
    setDelay(val = { HH: '00', mm: '00' }, index = 0) {
      this.selectedQuickAction = index;
      this.alertDelay = val;
    },
    timeInMinutes({ HH = '00', mm = '00' }) {
      // eslint-disable-next-line radix
      return parseInt(HH) * 60 + parseInt(mm);
    },
    onSelect(val) {
      this.selectedLabels.push(val.name);
    },
    onRemove(index) {
      this.selectedLabels.splice(index, 1);
    },
    toggleStarred() {
      this.isStarred = !this.isStarred;
      if (this.isStarred) {
        mixpanelEvent('clicked_ticket_bulk_starred');
      }
    },
    onClear() {
      this.agent = -1;
      this.status = null;
      this.selectedLabels = [];
      this.isStarred = false;
      this.alertDelay = { HH: '00', mm: '00' };
      this.waitTimeOptions = false;
      this.selectedQuickAction = null;
    },
    getChangedProperties() {
      const defaultState = {
        agent: -1,
        status: null,
        selectedLabels: [],
        isStarred: false,
        alertDelay: { HH: '00', mm: '00' },
      };

      const changedProperties = Object.keys(defaultState).filter(prop => {
        if (prop === 'selectedLabels') {
          return !isObjectEqual(
            this.selectedLabels,
            defaultState.selectedLabels
          );
        }
        if (prop === 'alertDelay') {
          return !isObjectEqual(this.alertDelay, defaultState.alertDelay);
        }
        return this[prop] !== defaultState[prop];
      });

      return changedProperties;
    },
    async modifyConv() {
      this.isLoading = true;
      let alertTime = this.timeInMinutes(this.alertDelay);
      const changedProperties = this.getChangedProperties();
      mixpanelEventWithProps(
        'clicked_ticket_bulk_apply_changes',
        changedProperties
      );
      let payload = {
        selectAll: this.selectAll,
        alertDelay: alertTime,
        agentId: this.agent,
        status: this.status === null ? undefined : this.status,
        starred: this.isStarred,
        labels: this.selectedLabels,
        conversationIds: this.selectedConv,
        filters: this.filters,
        message: this.message,
      };

      if (this.selectedConv.length) {
        try {
          await this.$store.dispatch('bulkConversationUpdate', payload);
          this.showAlert(this.$t('CONVERSATION.MODIFY_SUCCESS'), 'success');
          this.onClose();
        } catch (error) {
          this.showAlert(this.$t('CONVERSATION.MODIFY_FAIL'), 'error');
          // handle error
        }
        this.$emit('done');
      }

      this.isLoading = false;
    },
    hideCannedResponse() {
      this.showCannedResponsesList = false;
    },
    replaceText(message) {
      setTimeout(() => {
        this.message = message;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '~dashboard/assets/scss/variables';

.modal-footer {
  justify-content: space-between !important;

  .clear-all {
    display: flex;
    padding: $zero;
  }
}
.settings-form {
  padding: $space-slab $space-medium;
}
.content-box {
  text-align: left !important;
}
.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}
.content-margin {
  margin-bottom: 2.4rem;

  .quick-button {
    width: auto;
    margin-right: $space-slab;
    padding: $space-smaller $space-small;
  }

  .active-quick {
    background: $pg-1-500;
    color: $neutral-white;
    border-color: $pg-1-500;
  }
}
.limechat-timepicker {
  display: flex;
  flex-direction: column;
  padding: $zero $space-medium;
  align-items: center;
  justify-content: space-between;

  .picker-container {
    align-items: center;

    .vue__time-picker {
      font-size: $font-size-small;
      width: auto;
      font-family: inherit;
    }
  }
}
.limechat-timepicker ::v-deep input {
  cursor: default;
  margin-bottom: $zero;
  background: $neutral-white;
}
.limechat-timepicker ::v-deep ul {
  &::-webkit-scrollbar {
    height: 0.1em;
    transition: all 0.5s;
    width: 0.4em;
    z-index: 10;
  }

  & ::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $neutral-grey-500;
    border-radius: 0.1em;
  }
}
.medium-12 {
  .button {
    float: right;
  }
}
.selector-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: $settings-searchbar-height;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  background: $neutral-grey-200;
  border: 1px solid $neutral-grey-200;
  cursor: pointer;
  border-radius: $border-radius;
  padding: $space-slab;
  text-transform: capitalize;

  &:focus {
    border: 1px solid $pg-1-500;
    background: $neutral-white;
    outline: none;
  }

  &:hover {
    border: 1px solid $neutral-grey-600;
    background: $neutral-white;
  }
}

.canned-container {
  display: flex;
  align-items: flex-start;

  .cancel-button {
    margin-left: $space-small;
    cursor: pointer;
  }
}
</style>
