/* eslint arrow-body-style: 0 */
import ConversationView from './chats/views/ConversationView';
import CreateTicket from './createTicket';
import ContactPanel from './ContactPanel';
import OverviewPanel from './overview/OverviewPanel';
import OrderPanel from './orders/Index';
import ProductPanel from './products/Index';
import CreateOrder from './orders/creation/CreateOrder';

import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/dashboard'),
      name: 'home',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: () => {
        return { inboxId: 0 };
      },
    },
    {
      path: frontendURL('accounts/:accountId/create-ticket'),
      name: 'create_ticket',
      roles: ['administrator', 'agent', 'supervisor'],
      component: CreateTicket,
      children: [
        {
          path: frontendURL(
            'accounts/:accountId/create-ticket/inbox/:inbox_id'
          ),
          name: 'create_ticket_with_inbox',
          roles: ['administrator', 'agent', 'supervisor'],
          component: CreateTicket,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/inbox/:inbox_id'),
      name: 'inbox_dashboard',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => {
        return { inboxId: route.params.inbox_id };
      },
    },
    {
      path: frontendURL('accounts/:accountId/conversations/:conversation_id'),
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => {
        return { inboxId: 0, conversationId: route.params.conversation_id };
      },
      children: [
        {
          path: '',
          roles: ['administrator', 'agent', 'supervisor'],
          component: ContactPanel,
          children: [
            {
              path: '',
              name: 'overview_panel',
              roles: ['administrator', 'agent', 'supervisor'],
              component: OverviewPanel,
              props: route => {
                return { conversationId: route.params.conversation_id };
              },
            },
            {
              path: 'orders',
              name: 'orders_panel',
              roles: ['administrator', 'agent', 'supervisor'],
              component: OrderPanel,
            },
            {
              path: 'orders/cart',
              name: 'create_order_cart',
              roles: ['administrator', 'agent', 'supervisor'],
              component: CreateOrder,
            },
            {
              path: 'products',
              name: 'products_panel',
              roles: ['administrator', 'agent', 'supervisor'],
              component: ProductPanel,
            },
          ],
        },
      ],
    },
    {
      path: frontendURL(
        'accounts/:accountId/inbox/:inbox_id/conversations/:conversation_id'
      ),
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => {
        return {
          conversationId: route.params.conversation_id,
          inboxId: route.params.inbox_id,
        };
      },
      children: [
        {
          path: '',
          roles: ['administrator', 'agent', 'supervisor'],
          component: ContactPanel,
          children: [
            {
              path: '',
              name: 'overview_panel_through_inbox',
              roles: ['administrator', 'agent', 'supervisor'],
              component: OverviewPanel,
              props: route => {
                return { conversationId: route.params.conversation_id };
              },
            },
            {
              path: 'orders',
              name: 'orders_panel_through_inbox',
              roles: ['administrator', 'agent', 'supervisor'],
              component: OrderPanel,
            },
            {
              path: 'orders/cart',
              name: 'create_order_cart_through_inbox',
              roles: ['administrator', 'agent', 'supervisor'],
              component: CreateOrder,
            },
            {
              path: 'products',
              name: 'products_panel_through_inbox',
              roles: ['administrator', 'agent', 'supervisor'],
              component: ProductPanel,
            },
          ],
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/label/:label'),
      name: 'label_conversations',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({ label: route.params.label }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/label/:label/conversations/:conversation_id'
      ),
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({
        conversationId: route.params.conversation_id,
        label: route.params.label,
      }),
    },
    {
      path: frontendURL('accounts/:accountId/team/:teamId'),
      name: 'team_conversations',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({ teamId: route.params.teamId }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/team/:teamId/conversations/:conversationId'
      ),
      name: 'conversations_through_team',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({
        conversationId: route.params.conversationId,
        teamId: route.params.teamId,
      }),
      children: [
        {
          path: '',
          roles: ['administrator', 'agent', 'supervisor'],
          component: ContactPanel,
          children: [
            {
              path: '',
              name: 'overview_panel_through_label',
              roles: ['administrator', 'agent', 'supervisor'],
              component: OverviewPanel,
              props: route => {
                return { conversationId: route.params.conversation_id };
              },
            },
            {
              path: 'orders',
              name: 'orders_panel_through_label',
              roles: ['administrator', 'agent', 'supervisor'],
              component: OrderPanel,
            },
            {
              path: 'orders/cart',
              name: 'create_order_cart_through_label',
              roles: ['administrator', 'agent', 'supervisor'],
              component: CreateOrder,
            },
            {
              path: 'products',
              name: 'products_panel_through_label',
              roles: ['administrator', 'agent', 'supervisor'],
              component: ProductPanel,
            },
          ],
        },
      ],
    },
  ],
};
