<template>
  <div class="csat--table-container">
    <div v-if="uiFlags.isFetching">
      <table-skeleton />
    </div>

    <div v-else class="medium-12 bottom-container">
      <woot-table
        class="lime-card-1dp"
        title="Feedback Utterances"
        :header-list="tableHeader"
        :table-data="tableData"
        :is-sortable="true"
        :is-link="true"
      />
    </div>

    <div v-if="metrics.totalResponseCount" class="table-pagination">
      <ve-pagination
        :total="metrics.totalResponseCount"
        :page-index="pageIndex"
        :page-size="25"
        :page-size-option="[25]"
        @on-page-number-change="onPageNumberChange"
      />
    </div>
  </div>
</template>
<script>
import { VePagination } from 'vue-easytable';
import TableSkeleton from './TableSkeleton.vue';
import { CSAT_RATINGS } from 'shared/constants/messages';
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';

export default {
  components: {
    TableSkeleton,
    VePagination,
  },
  mixins: [timeMixin],
  props: {
    pageIndex: {
      type: Number,
      default: 1,
    },
    showInboxColumn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'csat/getUIFlags',
      csatResponses: 'csat/getCSATResponses',
      metrics: 'csat/getMetrics',
    }),
    tableHeader() {
      return this.showInboxColumn
        ? this.$t('ANALYTICS.CSAT_HEADER')
        : this.$t('ANALYTICS.CSAT_HEADER').slice(0, 4);
    },
    tableData() {
      return this.csatResponses.map(response => {
        const data = {
          contact: response.contact || '-',
          assignedAgent: response.assigned_agent || '-',
          rating: CSAT_RATINGS.filter(
            rating => rating.value === response.response
          )[0].emoji,
          message: response.comment || '',
        };

        if (this.showInboxColumn)
          return {
            ...data,
            inboxName: response?.inbox_name || '',
            ticketLink: `<a href="${response.ticket_link}" target="_blank">Link</a>`,
          };

        return data;
      });
    },
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.csat--table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .table-pagination {
    margin-top: $space-small;
    text-align: right;
  }
}
</style>
