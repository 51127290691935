import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import CrmApi from '../../api/limekit/crm';
import { AVAILABLE_CRMS } from 'dashboard/routes/dashboard/conversation/overview/constants';

const state = {
  records: [],
  enabledCrms: [],
  departmentNames: [],
  projects: [],
  formSchema: {
    [AVAILABLE_CRMS.JIRA]: {},
    [AVAILABLE_CRMS.LEADSQUARED]: {},
  },
  selectedCrm: '',
  uiFlags: {
    fetchingList: false,
    updatingItem: false,
    deletingItem: false,
    fetchingProjects: false,
    fetchingSchema: false,
    creatingTicket: false,
  },
};

export const getters = {
  getTickets({ records }) {
    return records;
  },
  getUIFlags({ uiFlags }) {
    return uiFlags;
  },
  getEnabledCrms({ enabledCrms }) {
    return enabledCrms;
  },
  getDepartmentNames({ departmentNames }) {
    return departmentNames;
  },
  getFormSchema: ({ formSchema }) => crm => {
    return formSchema[crm];
  },
  getProjectsFromJira({ projects }) {
    return projects;
  },
  getSelectedCrm({ selectedCrm }) {
    return selectedCrm;
  },
};

export const actions = {
  get: async ({ commit }, data) => {
    try {
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingList: true });
      const response = await CrmApi.fetchTickets(data);
      commit(types.default.SET_TICKETS, response.data);
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingList: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_TICKETS, []);
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingList: false });
      throw new Error(error.response.data?.message);
    }
  },
  getTicket: async ({ commit }, data) => {
    try {
      const response = await CrmApi.fetchTicket(data);
      return response;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      // Handle error
      throw new Error(error);
    }
  },
  raiseTicket: async ({ commit }, data) => {
    try {
      commit(types.default.SET_TICKETS_UI_FLAG, { creatingTicket: true });
      const response = await CrmApi.createTicket(data);
      commit(types.default.SET_TICKETS_UI_FLAG, { creatingTicket: false });
      return response;
    } catch (error) {
      commit(types.default.SET_TICKETS_UI_FLAG, { creatingTicket: false });
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      // Handle error
      throw new Error(error);
    }
  },
  update: async ({ commit }, data) => {
    try {
      commit(types.default.SET_TICKETS_UI_FLAG, { updatingItem: true });
      const response = await CrmApi.updateTicket(data);
      commit(types.default.EDIT_TICKET, response.data);
      commit(types.default.SET_TICKETS_UI_FLAG, { updatingItem: false });
    } catch (error) {
      commit(types.default.SET_TICKETS_UI_FLAG, { updatingItem: false });
      throw new Error(error.response.data?.message);
    }
  },
  delete: async ({ commit }, data) => {
    try {
      commit(types.default.SET_TICKETS_UI_FLAG, { deletingItem: true });
      await CrmApi.deleteTicket(data);
      commit(types.default.DELETE_TICKET, data.id);
      commit(types.default.SET_TICKETS_UI_FLAG, { deletingItem: false });
    } catch (error) {
      commit(types.default.SET_TICKETS_UI_FLAG, { deletingItem: false });
      throw new Error(error.response.data?.message);
    }
  },
  setSelectedCrm: ({ commit }, crmName) => {
    commit(types.default.SET_SELECTED_CRM, crmName);
  },
  fetchEnabledCrms: async ({ commit }) => {
    if (state.enabledCrms.length > 0) return state.enabledCrms;

    try {
      const {
        data: { enabled_crms },
      } = await CrmApi.getEnabledCrms();
      commit(types.default.SET_ENABLED_CRMS, enabled_crms);
      return enabled_crms;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      throw new Error(error);
    }
  },
  fetchProjectsAndIssueTypes: async ({ commit }) => {
    if (state.projects.length > 0) return state.projects;

    try {
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingProjects: true });
      const {
        data: {
          result: { projects },
        },
      } = await CrmApi.getProjectsAndIssueTypes();
      commit(types.default.SET_JIRA_PROJECT_AND_ISSUE_TYPES, projects);
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingProjects: false });
      return projects;
    } catch (error) {
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingProjects: false });
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      // Handle error
      throw error;
    }
  },
  fetchFormSchemaForJira: async (
    { commit },
    { selectedCrm, projectKey, issueType }
  ) => {
    try {
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingSchema: true });
      const {
        data: {
          result: { fields },
        },
      } = await CrmApi.getFormSchema(selectedCrm, {
        project_key: projectKey,
        issue_type: issueType,
      });
      commit(types.default.SET_TICKET_FORM_SCHEMA, {
        [AVAILABLE_CRMS.JIRA]: fields,
      });
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingSchema: false });
      return fields;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingSchema: false });
      throw error;
    }
  },
  fetchFormSchemaForLeadSquared: async ({ commit }, selectedCrm) => {
    try {
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingSchema: true });
      const {
        data: {
          result: { fields },
        },
      } = await CrmApi.getFormSchema(selectedCrm);
      commit(types.default.SET_TICKET_FORM_SCHEMA, {
        [AVAILABLE_CRMS.LEADSQUARED]: fields,
      });
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingSchema: false });
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      commit(types.default.SET_TICKETS_UI_FLAG, { fetchingSchema: false });
      throw error;
    }
  },
  fetchDepartmentNames: async ({ commit }, data) => {
    try {
      const response = await CrmApi.getDepartmentNames(data);
      commit(types.default.SET_DEPARTMENT_NAMES, data);
      return response.data;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      // Handle error
      throw error;
    }
  },
  crmConnection: async ({ commit }, data) => {
    try {
      const response = await CrmApi.crmConnection(data);
      return response ? response.data : {};
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      return error;
    }
  },
  fetchLimekitCrmConnection: async ({ commit }, data) => {
    try {
      const response = await CrmApi.fetchLimekitCrmConnection(data);
      return response;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      throw new Error(error);
    }
  },
  createLimekitCrmConnection: async ({ commit }, data) => {
    try {
      const response = await CrmApi.createLimekitCrmConnection(data);
      return response;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      throw new Error(error);
    }
  },
  deleteLimekitCrmConnection: async ({ commit }, data) => {
    try {
      const response = await CrmApi.deleteLimekitCrmConnection(data);
      return response;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      throw new Error(error);
    }
  },
  getAgentNames: async ({ commit }, data) => {
    try {
      const response = await CrmApi.getAgentNames(data);
      return response.data;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      // Handle error
    }
    return {};
  },
  getTicketFields: async ({ commit }, data) => {
    try {
      const response = await CrmApi.getTicketFields(data);
      return response.data;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      // Handle error
    }
    return {};
  },
  getFreshdeskWidgetId: async ({ commit }, data) => {
    try {
      const response = await CrmApi.getFreshdeskWidgetId(data);
      return response.data;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      // Handle error
    }
    return {};
  },
};

export const mutations = {
  [types.default.SET_TICKETS_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.default.SET_TICKETS]: MutationHelpers.set,
  [types.default.EDIT_TICKET]: MutationHelpers.updateAttributes,
  [types.default.DELETE_TICKET]: MutationHelpers.destroy,
  [types.default.SET_ENABLED_CRMS]($state, enabledCrms) {
    $state.enabledCrms = [...enabledCrms];
  },
  [types.default.SET_DEPARTMENT_NAMES]($state, departmentNames) {
    $state.departmentNames = [...departmentNames];
  },
  [types.default.SET_TICKET_FORM_SCHEMA]($state, schemaObject) {
    $state.formSchema = { ...$state.formSchema, ...schemaObject };
  },
  [types.default.SET_JIRA_PROJECT_AND_ISSUE_TYPES]($state, projects) {
    $state.projects = projects;
  },
  [types.default.SET_SELECTED_CRM]($state, selectedCrm) {
    $state.selectedCrm = selectedCrm;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
