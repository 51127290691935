<template>
  <div v-if="agentData.id">
    <div
      class="profile-viewer"
      :style="{
        backgroundImage: 'url(' + bgImage + ')',
        backgroundColor: bgColor,
      }"
    >
      <profile-viewer
        :src="agentData.thumbnail"
        :is-delete="agentData.canDelete"
        action="edit"
        disable-file-upload
        @action="$emit('openEditModal', { ...agentData, navigateTo: 0 })"
        @delete="$emit('openDeleteModal', [agentData.index])"
        @profileChange="handleImageUpload"
      />
    </div>
    <div class="information-container">
      <div v-if="personalDetails.length" class="personal-details">
        <info-reader v-for="detail in personalDetails" :key="detail">
          <span class="title-h5 text-dark" v-text="detail" />
          <woot-base-button
            variant="tertiary"
            tag="span"
            size="small"
            @click="onCopy(detail)"
          >
            <icons name="copy" view="0 0 16 16" color="grey" size="normal" />
          </woot-base-button>
        </info-reader>
      </div>
      <div class="partition">
        <div
          v-if="employmentDetails.length"
          class="title-h5"
          style="font-variant: all-small-caps; font-size: 2rem;"
        >
          {{ $t('AGENT_MGMT.RHS_PANEL.SECTIONS.EMPLOYEMENT_DETAILS.TITLE') }}
        </div>
      </div>
      <div v-if="employmentDetails.length">
        <div class="employment-details">
          <info-reader
            v-for="detail in employmentDetails"
            :key="detail.label"
            custom-class="employee-item"
          >
            <div
              v-if="detail.label === 'Signature'"
              class="flex-row flex-align flex-justify--between full-width"
            >
              <span
                style="cursor: pointer"
                class="body-b2 text-light"
                @click="$emit('openEditModal', { ...agentData, navigateTo: 1 })"
                v-text="detail.label"
              />
              <woot-base-button
                tag="span"
                size="small"
                variant="tertiary"
                @click="$emit('openEditModal', { ...agentData, navigateTo: 1 })"
              >
                <icons name="signature" color="green" size="normal" />
              </woot-base-button>
            </div>
            <template v-else>
              <span class="body-b2 text-light" v-text="detail.label" />
              <span
                class="title-h5 text-dark capitalize"
                v-html="detail.data"
              />
            </template>
          </info-reader>
        </div>
        <div class="inbox-chips custom-scroll">
          <woot-chips
            v-for="inbox in agentInboxes"
            :key="inbox.id"
            :title="inbox.name"
            :show-tooltip="false"
            :custom-style="{
              'border-color': getInboxMeta(inbox).hex,
              color: getInboxMeta(inbox).hex,
            }"
            :front-icon="getInboxMeta(inbox).name"
            :front-icon-color="getInboxMeta(inbox).color"
            variant="secondary-small"
          />
        </div>
      </div>
    </div>
  </div>
  <educational-onboarding v-else :show-case-data="SHOWCASE_ROLE_DATA">
    <reference-box
      class="reference-box"
      :to="documentation.agent.add"
      content="Know how to create an agent profile here"
      type="document"
    />
  </educational-onboarding>
</template>

<script>
import copy from 'copy-text-to-clipboard';

import ProfileViewer from 'dashboard/components/ui/ProfileViewer';
import EducationalOnboarding from 'dashboard/components/ui/settings/EducationalOnboarding';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import documentation from 'dashboard/helper/data/documentationData.json';

import inboxMixin from 'dashboard/mixins/inbox';
import alertMixin from 'shared/mixins/alertMixin';

import {
  ROLE_COLORS,
  SHOWCASE_ROLE_DATA,
} from 'dashboard/routes/dashboard/settings/agents/constants';

export default {
  components: {
    EducationalOnboarding,
    ReferenceBox,
    InfoReader: {
      props: {
        tag: { type: String, default: 'div' },
        customClass: { type: String, default: '' },
      },
      render(h) {
        return h(
          this.tag,
          {
            attrs: {
              class: `info-container flex-row flex-justify--between ${this.customClass}`,
            },
          },
          this.$slots.default
        );
      },
    },
    ProfileViewer,
  },
  mixins: [inboxMixin, alertMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      SHOWCASE_ROLE_DATA,
      documentation,
    };
  },
  computed: {
    agentData() {
      return this.data;
    },
    showCaseRole() {
      return this.showcaseData[this.currIndex];
    },
    personalDetails() {
      const props = ['name', 'email', 'phone_number'];
      return Object.keys(this.agentData).reduce((details, prop, index) => {
        const d = this.agentData[props[index]];
        if (d) details.push(d);
        return details;
      }, []);
    },
    employmentDetails() {
      if (!this.agentData.id) return [];

      const fields = [
        { prop: 'role', label: 'Role' },
        { prop: 'signature', label: 'Signature' },
        { prop: 'concurrency_enabled', label: 'Concurrency' },
        { prop: 'concurrency_limit', label: 'Ticket Limit' },
        { prop: 'inboxes', label: 'Inboxes' },
      ];
      return fields.reduce((acc, field) => {
        const { label, prop } = field;
        let d = this.agentData[prop];

        if (prop === 'inboxes') d = this.agentData[prop]?.length || 0;

        if (prop === 'signature' && !this.agentData.signature) return acc;

        if (prop === 'concurrency_limit') d += ' Daily';

        if (prop === 'concurrency_limit' && !this.agentData.concurrency_enabled)
          return acc;

        acc.push({ label, data: d });

        return acc;
      }, []);
    },
    agentInboxes() {
      const {
        data: { inboxes },
      } = this;
      return inboxes;
    },
    bgColor() {
      return ROLE_COLORS[this.agentData.role];
    },
    bgImage() {
      return SHOWCASE_ROLE_DATA.find(data => data.name === this.agentData.role)
        .image;
    },
  },
  methods: {
    getInboxMeta(inbox) {
      return this.getInboxClassByType(
        inbox?.channel_type,
        inbox?.phone_number,
        '',
        inbox?.instagram_add_on
      );
    },
    onCopy(value) {
      copy(value);
      this.showAlert('Text Copied', 'info');
    },
    handleImageUpload({ url }) {
      this.updateAgentProfilePicture(url);
    },
    updateAgentProfilePicture(url) {
      let payload = {
        id: this.agentData.id,
        thumbnail: url,
      };

      this.$store
        .dispatch('agents/update', payload)
        .then(() => {
          this.showAlert(
            this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'),
            'success'
          );
        })
        .catch(() => {
          this.showAlert(
            this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'),
            'success'
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.reference-box {
  bottom: $space-two;
  position: absolute;
  width: 100%;
}

.profile-viewer {
  background: $facebook-blue;
  background-size: cover;
  background-position: center;
  border-radius: $border-radius-smaller;
  height: $space-two * 10;
  transition: background 0.3s ease-in-out;
}

.information-container {
  padding: $space-medium;

  .info-container {
    border-bottom: 1px solid $neutral-grey-300;
    margin-bottom: $space-slab;
    padding-bottom: $space-slab;

    &:last-child {
      border: none;
      margin-bottom: $zero;
    }
  }

  .partition {
    border-radius: $border-radius-smaller;
    color: $neutral-grey-600;
    margin: $space-small $zero $space-normal;
    padding: $space-small $space-normal $space-small $zero;
  }

  .inbox-chips {
    max-height: $space-one * 10;
    overflow: overlay;
  }

  .access-list {
    margin: $space-slab $space-small;
  }

  .employee-item {
    margin-bottom: $space-small;
    padding-bottom: $space-small;
  }
}
</style>
