const getters = {
  getCustomTicketFields($state) {
    return Object.values($state.records).sort((n1, n2) => n2.id - n1.id);
  },
  getFieldWithId: $state => id => {
    return $state.records.find(field => field.id === id);
  },
  getFieldsOfInbox: $state => inboxId => {
    return $state.records.filter(field =>
      field.inboxes.some(inbox => inbox.id === inboxId)
    );
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export default getters;
