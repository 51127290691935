<template>
  <div class="message-text--metadata">
    <span v-if="isAnEmail && isPrivate" class="time">{{ readableTime }}</span>
    <template v-if="!isAnEmail">
      <span class="time">{{ readableTime }}</span>
      <span v-if="sentByMessage" v-tooltip.left="details" class="tick">
        <icons v-if="isFailed" name="error" color="warning" />
        <icons v-else-if="isSent" name="tick" color="grey" view="0 0 16 16" />
        <icons
          v-else-if="isDelivered"
          name="tick"
          color="darkestgrey"
          view="0 0 16 16"
        />
        <icons v-else-if="isRead" name="tick" color="tick" view="0 0 16 16" />
        <div v-else>
          <spinner size="small" color="grey" />
        </div>
      </span>
    </template>
    <i
      v-if="isATweet && isIncoming"
      v-tooltip.top-start="$t('CHAT_LIST.REPLY_TO_TWEET')"
      class="icon ion-reply cursor-pointer"
      @click="onTweetReply"
    />
    <a
      v-if="isATweet && isIncoming"
      :href="linkToTweet"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <i
        v-tooltip.top-start="$t('CHAT_LIST.VIEW_TWEET_IN_TWITTER')"
        class="icon ion-android-open cursor-pointer"
      />
    </a>
  </div>
</template>

<script>
import { MESSAGE_TYPE, MESSAGE_STATUS } from 'shared/constants/messages';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import Spinner from 'shared/components/Spinner';

export default {
  components: {
    Spinner,
  },
  props: {
    sender: {
      type: Object,
      default: () => ({}),
    },
    readableTime: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'sent',
    },
    details: {
      type: String,
      default: '',
    },
    isPrivate: {
      type: Boolean,
      default: true,
    },
    isATweet: {
      type: Boolean,
      default: true,
    },
    isAnEmail: {
      type: Boolean,
      default: false,
    },
    messageType: {
      type: Number,
      default: 1,
    },
    sourceId: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    isIncoming() {
      return MESSAGE_TYPE.INCOMING === this.messageType;
    },
    screenName() {
      const { additional_attributes: additionalAttributes = {} } =
        this.sender || {};
      return additionalAttributes?.screen_name || '';
    },
    linkToTweet() {
      const { screenName, sourceId } = this;
      return `https://twitter.com/${screenName}/status/${sourceId}`;
    },
    sentByMessage() {
      return (
        [1, 3, 5, 6, 7, 8, 9, 11, 12, 13].includes(this.messageType) &&
        !this.isPrivate
      );
    },
    isFailed() {
      return this.status === MESSAGE_STATUS.FAILED;
    },
    isSent() {
      return this.status === MESSAGE_STATUS.SENT;
    },
    isDelivered() {
      return this.status === MESSAGE_STATUS.DELIVERED;
    },
    isRead() {
      return this.status === MESSAGE_STATUS.READ;
    },
  },
  methods: {
    onTweetReply() {
      bus.$emit(BUS_EVENTS.SET_TWEET_REPLY, this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.message-text--metadata {
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  right: $space-small;
  display: flex;
  padding-top: $space-micro;
  flex-direction: row;
  color: $neutral-grey-600;

  .tick {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time {
    margin-right: $space-micro;
    display: block;
    font-size: var(--font-size-micro);
    line-height: 1.8;
  }

  i {
    line-height: 1.4;
    padding-right: var(--space-small);
    padding-left: var(--space-small);
    color: var(--s-900);
  }

  a {
    color: var(--s-900);
  }
}

.activity-wrap {
  .message-text--metadata {
    display: flex;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    right: 0;
    background: $chat-background;

    .time {
      color: $neutral-grey-800;
      margin: 0;
      font-size: $font-size-mini;
    }
  }
}

.is-image {
  .message-text--metadata {
    .time {
      white-space: nowrap;
    }
  }
}

.is-private {
  .message-text--metadata {
    align-items: flex-end;
  }

  &.is-image {
    .time {
      position: inherit;
      padding-left: $space-one;
    }
  }
}
</style>
