import {
  OPERATOR_TYPES_1,
  OPERATOR_TYPES_3,
  OPERATOR_TYPES_4,
} from 'dashboard/routes/dashboard/settings/automation/utils/operators';
import filterQueryGenerator from './filterQueryGenerator';
import actionQueryGenerator from './actionQueryGenerator';
import { DEFAULT_AUTOMATION_PAYLOAD } from './constants';

const MESSAGE_CONDITION_VALUES = [
  {
    id: 'incoming',
    name: 'Incoming Message',
  },
  {
    id: 'outgoing',
    name: 'Outgoing Message',
  },
];
const IDENTIFIER = [
  {
    id: 'comment',
    name: 'Facebook Comment',
  },
  {
    id: 'instagram_comment',
    name: 'Instagram Comment',
  },
  {
    id: 'direct_message',
    name: 'Direct Message',
  },
];
const CRM_TICKET_TYPES = [
  {
    id: 'kapture',
    name: 'Kapture',
  },
  {
    id: 'freshdesk',
    name: 'Freshdesk',
  },
  {
    id: 'leadsquared',
    name: 'LeadSquared',
  },
];
const CRM_UPDATE_TICKET_TYPES = [
  {
    id: 'freshdesk',
    name: 'Freshdesk',
  },
];

export const getCustomAttributeInputType = key => {
  const customAttributeMap = {
    date: 'date',
    text: 'plain_text',
    list: 'search_select',
    checkbox: 'search_select',
  };

  return customAttributeMap[key] || 'plain_text';
};

export const isACustomAttribute = (customAttributes, key) => {
  return customAttributes?.find(attr => {
    return attr.attribute_key === key;
  });
};

export const getCustomAttributeListDropdownValues = (
  customAttributes,
  type
) => {
  const attribute = customAttributes.find(attr => attr.attribute_key === type);
  if (!attribute) {
    return [];
  }
  return attribute.attribute_values.map(item => {
    return {
      id: item,
      name: item,
    };
  });
};

export const isCustomAttributeCheckbox = (customAttributes, key) => {
  return customAttributes?.find(attr => {
    return (
      attr.attribute_key === key && attr.attribute_display_type === 'checkbox'
    );
  });
};

export const isCustomAttributeList = (customAttributes, type) => {
  return customAttributes?.find(attr => {
    return (
      attr.attribute_key === type && attr.attribute_display_type === 'list'
    );
  });
};

export const getOperatorTypes = key => {
  const operatorMap = {
    list: OPERATOR_TYPES_1,
    text: OPERATOR_TYPES_3,
    number: OPERATOR_TYPES_1,
    link: OPERATOR_TYPES_1,
    date: OPERATOR_TYPES_4,
    checkbox: OPERATOR_TYPES_1,
  };

  return operatorMap[key] || OPERATOR_TYPES_1;
};

export const generateCustomAttributeTypes = (customAttributes, type) => {
  return customAttributes.map(attr => {
    return {
      key: attr.attribute_key,
      name: attr.attribute_display_name,
      inputType: getCustomAttributeInputType(attr.attribute_display_type),
      filterOperators: getOperatorTypes(attr.attribute_display_type),
      customAttributeType: type,
    };
  });
};

export const generateConditionOptions = (options, key = 'id') => {
  return options?.map(i => {
    return {
      id: i[key],
      name: i.title,
    };
  });
};

export const getActionOptions = ({
  agents,
  teams,
  labels,
  contactLabels,
  type,
}) => {
  const actionsMap = {
    assign_agent: agents,
    assign_team: teams,
    send_email_to_team: teams,
    create_crm_ticket: CRM_TICKET_TYPES,
    update_crm_ticket: CRM_UPDATE_TICKET_TYPES,
    add_label: generateConditionOptions(labels),
    add_contact_label: generateConditionOptions(contactLabels),
  };
  return actionsMap[type];
};

export const getConditionOptions = ({
  agents,
  booleanFilterOptions,
  contacts,
  countries,
  customAttributes,
  inboxes,
  statusFilterOptions,
  teams,
  labels,
  contactLabels,
  type,
}) => {
  if (isCustomAttributeCheckbox(customAttributes, type)) {
    return booleanFilterOptions;
  }

  if (isCustomAttributeList(customAttributes, type)) {
    return getCustomAttributeListDropdownValues(customAttributes, type);
  }
  const conditionFilterMaps = {
    previous_status: statusFilterOptions,
    assignee_id: agents,
    contact: contacts,
    inbox_id: inboxes,
    identifier: IDENTIFIER,
    team_id: teams,
    label_id: labels,
    contact_label_id: contactLabels,
    country_code: countries,
    message_type: MESSAGE_CONDITION_VALUES,
  };

  return conditionFilterMaps[type];
};

export const getFileName = (action, files = []) => {
  if (!action?.action_params) return '';

  const blobId = action.action_params[0];
  if (!blobId) return '';
  if (action.action_name === 'send_attachment' && !!files.length) {
    const file = files?.find(item => item.blob_id === blobId);
    if (file) return file.filename.toString();
  }
  return '';
};

export const getDefaultConditions = () => {
  return DEFAULT_AUTOMATION_PAYLOAD.conditions;
};

export const getDefaultActions = () => {
  return [
    {
      action_name: '',
      action_params: [],
    },
  ];
};

export const filterCustomAttributes = customAttributes => {
  return customAttributes.map(attr => {
    return {
      key: attr.attribute_key,
      name: attr.attribute_display_name,
      type: attr.attribute_display_type,
    };
  });
};

export const getStandardAttributeInputType = (automationTypes, event, key) => {
  const condition = automationTypes[event].conditions?.find(
    item => item.key === key
  );
  return condition ? condition.inputType : undefined;
};

export const generateAutomationPayload = payload => {
  const automation = JSON.parse(JSON.stringify(payload));
  automation.conditions[automation.conditions.length - 1].query_operator = null;
  automation.conditions = filterQueryGenerator(automation.conditions).payload;
  automation.actions = actionQueryGenerator(automation.actions);
  return automation;
};

export const isCustomAttribute = (attrs, key) => {
  return attrs?.find(attr => attr.key === key);
};

export const generateCustomAttributes = (
  conversationAttributes = [],
  contactAttribtues = [],
  conversationlabel,
  contactlabel
) => {
  const customAttributes = [];
  if (conversationAttributes.length) {
    customAttributes.push(
      {
        key: `conversation_custom_attribute`,
        name: conversationlabel,
        disabled: true,
      },
      ...conversationAttributes
    );
  }
  if (contactAttribtues.length) {
    customAttributes.push(
      {
        key: `contact_custom_attribute`,
        name: contactlabel,
        disabled: true,
      },
      ...contactAttribtues
    );
  }
  return customAttributes;
};

export const getDefaultAutomation = oldAutomation => {
  const { name, description, event_name, active } = oldAutomation;

  return {
    ...JSON.parse(JSON.stringify(DEFAULT_AUTOMATION_PAYLOAD)),
    name,
    description,
    event_name,
    active,
  };
};
