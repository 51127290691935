import store from 'dashboard/store';

const generateAutomationRuleSummary = rule => {
  const getInboxNameById = id => {
    const inbox = store.getters['inboxes/getInboxes'].find(
      ibx => ibx.id === id
    );
    return inbox ? inbox.name : id;
  };

  const getConversationLabelNameById = id => {
    const label = store.getters['labels/getLabelsWithoutGroups'].find(
      lbl => lbl.id === id
    );
    return label ? label.title : id;
  };

  const getContactLabelNameById = id => {
    const label = store.getters['labels/getContactLabelsWithoutGroups'].find(
      lbl => lbl.id === id
    );
    return label ? label.title : id;
  };

  const getTeamNameById = id => {
    const team = store.getters['teams/getTeams'].find(tm => tm.id === id);
    return team ? team.name : id;
  };

  const mapIdsToNames = (values, key) => {
    if (!values || values.length === 0) return ['_____'];
    if (key === 'inbox_id') return values.map(getInboxNameById);

    if (['add_label', 'label_id'].includes(key))
      return values.map(getConversationLabelNameById);

    if (['add_contact_label', 'contact_label_id'].includes(key))
      return values.map(getContactLabelNameById);

    if (['assign_team', 'team_id'].includes(key))
      return values.map(getTeamNameById);
    return values;
  };

  const getStyledKey = key => {
    if (key === 'inbox_id') return 'inbox';

    if (['add_label', 'label_id'].includes(key)) return 'tag';

    if (['add_contact_label', 'contact_label_id'].includes(key))
      return 'contact tag';

    return key.replace(/_/g, ' ');
  };

  const eventParts = rule.event_name.split('_');
  const styledEventName = `<b class="capitalize text-dark">${
    eventParts[0]
  }</b> <span class="lowercase text-light"> is </span> <b class="text-lime-green">${eventParts
    .slice(1)
    .join(' ')}</b>`;

  const event = `<div class="section"><strong>Event</strong><div>When a ${styledEventName}</div></div>`;

  const conditions = rule.conditions
    .map(condition => {
      const values = mapIdsToNames(
        condition.values,
        condition.attribute_key
      ).join(', ');
      const operator = condition.query_operator
        ? `${condition.query_operator.toUpperCase()}<br>`
        : '';
      return `<li>If <b class="text-dark capitalize">${getStyledKey(
        condition.attribute_key
      )}</b> is <b class="text-lime-green">${values}</b></li><div class="operator">${operator}</div>`;
    })
    .join('');

  const conditionsBlock = `<div class="section"><strong>Conditions</strong><ul>${conditions}</ul></div>`;

  const actions = rule.actions
    .map(action => {
      const params =
        action.action_params && action.action_params.length
          ? ` <b class="text-lime-green">${mapIdsToNames(
              action.action_params,
              action.action_name
            ).join(', ')}</b>`
          : '';
      return `<li>Then <b class="text-dark capitalize">${action.action_name.replace(
        /_/g,
        ' '
      )}</b>${params}</li>`;
    })
    .join('');

  const actionsBlock = `<div class="section"><strong>Actions</strong><ul>${actions}</ul></div>`;

  return `<div>${event}${conditionsBlock}${actionsBlock}</div>`;
};

export default generateAutomationRuleSummary;
