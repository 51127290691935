<template>
  <div class="content-box settings-box">
    <div
      v-if="inboxes.length && !uiFlags.isFetching"
      class="settings-container column custom-scroll lime-card-1dp mr-right"
    >
      <page-header
        :header-title="$t('TICKET_ASSIGNMENT.TITLE')"
        :header-content="$t('TICKET_ASSIGNMENT.DESCRIPTION')"
      >
        <div class="flex-row flex-align gap--small">
          <reference-box
            class="mg-top--large"
            :to="FEATURE_DEMO_URL"
            content="Video Demo"
            type="video"
          />
          <reference-box
            class="mg-top--large"
            :to="PLAYBOOK_URL"
            content="Playbook"
            type="document"
          />
        </div>
      </page-header>
      <ticket-assignment-settings />
    </div>
    <zero-state v-else-if="!inboxes.length && !uiFlags.isFetching" />
    <loading-state v-else class="loader-cmpt" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import TicketAssignmentSettings from './components/TicketAssignmentSettings';
import ZeroState from './components/ZeroState';
import LoadingState from 'dashboard/components/ui/LoadingState';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import { PLAYBOOK_URL, FEATURE_DEMO_URL } from './utils/constants';

export default {
  name: 'TicketAssignmentHome',
  components: {
    PageHeader,
    TicketAssignmentSettings,
    ZeroState,
    LoadingState,
    ReferenceBox,
  },
  data() {
    return {
      PLAYBOOK_URL,
      FEATURE_DEMO_URL,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      inboxes: 'inboxes/getInboxes',
    }),
  },
  created() {
    this.$store.dispatch('inboxes/get');
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.settings-box {
  position: relative;
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;
  height: 100%;

  .lime-card-1dp {
    padding: $zero;
  }

  .settings-container {
    height: 100%;
    margin: $zero;
  }
}
</style>
