/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
import Vue from 'vue';
import * as types from '../../mutation-types';
import getters, { getSelectedChatConversation } from './getters';
import actions from './actions';
import { findPendingMessageIndex } from './helpers';
import wootConstants from '../../../constants';
import { playNotificationAudio } from 'shared/helpers/AudioNotificationHelper';

const state = {
  allConversations: [],
  allTabConversations: [],
  pendingMessages: [],
  listLoadingStatus: true,
  chatStatusFilter: wootConstants.STATUS_TYPE.OPEN,
  currentInbox: null,
  selectedChatId: null,
  conversationFilters: {
    ...wootConstants.DEFAULT_CONVERSATION_FILTERS_DEPRECATED,
  },
  uiFlags: { isFilterActive: false, isCreatingConversation: false },
};

function removePendingMessage(echoId) {
  const index = state.pendingMessages.indexOf(echoId);
  if (index > -1) state.pendingMessages.splice(index, 1);
}

export function updateConversationInArray(
  conversations,
  conversation,
  selectedChatId
) {
  const currentConversationIndex = conversations.findIndex(
    c => c.id === conversation.id
  );
  if (currentConversationIndex > -1) {
    const { messages, ...conversationAttributes } = conversation;
    const currentConversation = {
      ...conversations[currentConversationIndex],
      ...conversationAttributes,
    };

    Vue.set(conversations, currentConversationIndex, currentConversation);

    if (selectedChatId === conversation.id) {
      window.bus.$emit('scrollToMessage');
    }
  } else {
    conversations.push(conversation);
  }
}

export function setConversations(conversations, newConversations) {
  const updatedConversations = [...conversations];
  newConversations.forEach(conversation => {
    const indexInCurrentList = updatedConversations.findIndex(
      c => c.id === conversation.id
    );
    if (indexInCurrentList < 0) {
      updatedConversations.push(conversation);
    }
  });
  return updatedConversations;
}

export function setUnreadCount(conversations, conversationId, unreadCount) {
  const index = conversations.findIndex(c => c.id === conversationId);
  if (index > -1) {
    Vue.set(conversations[index], 'unread_count', unreadCount);
  }
}

// mutations
export const mutations = {
  [types.default.SET_CONVERSATION_UI_FLAGS](_state, flagObject) {
    _state.uiFlags = { ...flagObject };
  },
  [types.default.SET_CONVERSATION_FILTERS](_state, filters) {
    _state.conversationFilters = {
      ..._state.conversationFilters,
      ...filters,
      canSetDefaultValues: false,
    };
  },
  [types.default.RESET_CONVERSATION_FILTERS](_state) {
    _state.conversationFilters = {
      ...wootConstants.DEFAULT_CONVERSATION_FILTERS_DEPRECATED,
      canSetDefaultValues: true,
    };
  },
  [types.default.UPDATE_CONVERSATION_FILTERS](_state, data) {
    const { key, value } = data;
    _state.conversationFilters[key] = value;
  },
  [types.default.SET_ALL_CONVERSATION](_state, conversationList) {
    _state.allConversations = setConversations(
      _state.allConversations,
      conversationList
    );
  },
  [types.default.SET_ALL_TAB_CONVERSATION](_state, conversationList) {
    _state.allTabConversations = setConversations(
      _state.allTabConversations,
      conversationList
    );
  },
  [types.default.EMPTY_ALL_TAB_CONVERSATION](_state) {
    _state.allTabConversations = [];
  },
  [types.default.SET_ALL_MESSAGES_LOADED](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'allMessagesLoaded', true);
  },

  [types.default.CLEAR_ALL_MESSAGES_LOADED](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'allMessagesLoaded', false);
  },
  [types.default.CLEAR_CURRENT_CHAT_WINDOW](_state) {
    _state.selectedChatId = null;
  },

  [types.default.SET_PREVIOUS_CONVERSATIONS](_state, { id, data }) {
    if (data.length) {
      const [chat] = _state.allConversations.filter(c => c.id === id);
      chat.messages.unshift(...data);
    }
  },

  [types.default.UPDATE_PENDING_MESSAGES](_state, { id, data }) {
    if (data.length) {
      const [chat] = getSelectedChatConversation({
        allConversations: _state.allConversations,
        selectedChatId: id,
      });

      if (!chat) return;

      let chatIdx = 1;
      const chatMessagesLength = chat.messages.length;

      for (
        let idx = data.length - 1;
        idx >= _state.pendingMessages.length;
        idx -= 1
      ) {
        removePendingMessage(
          chat.messages[chatMessagesLength - chatIdx].echo_id
        );
        Vue.set(chat.messages, chatMessagesLength - chatIdx, data[idx]);
        chatIdx += 1;
      }
    }
  },

  [types.default.SET_CURRENT_CHAT_WINDOW](_state, activeChat) {
    if (activeChat) {
      _state.selectedChatId = activeChat.id;
    }
  },

  [types.default.ASSIGN_AGENT](_state, assignee) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat.meta, 'assignee', assignee);
  },

  [types.default.ASSIGN_TEAM](_state, team) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat.meta, 'team', team);
  },

  [types.default.BULK_ASSIGN_AGENT]({ allConversations }, data) {
    const { conversations, assignee } = data;
    conversations.forEach(conversation => {
      const { conversation_id: conversationId } = conversation;
      const [chat] = getSelectedChatConversation({
        allConversations,
        selectedChatId: conversationId,
      });
      chat.meta.assignee = assignee;
    });
  },

  [types.default.RESOLVE_CONVERSATION](_state, status) {
    const [chat] = getSelectedChatConversation(_state);
    chat.status = status;
  },

  [types.default.BULK_RESOLVE_CONVERSATION](
    { allConversations },
    conversations
  ) {
    conversations.forEach(conversation => {
      const { conversation_id: conversationId, status } = conversation;
      const [chat] = getSelectedChatConversation({
        allConversations,
        selectedChatId: conversationId,
      });
      chat.status = status;
    });
  },

  [types.default.UPDATE_CONVERSATION_NEW_LABEL](
    { allConversations },
    conversation
  ) {
    const { id: conversationId, is_new } = conversation;
    const [chat] = getSelectedChatConversation({
      allConversations,
      selectedChatId: conversationId,
    });
    if (chat) {
      chat.is_new = is_new;
    }
  },

  [types.default.STAR_CONVERSATION]({ allConversations }, conversation) {
    const { conversation_id: conversationId, starred } = conversation;
    const [chat] = getSelectedChatConversation({
      allConversations,
      selectedChatId: conversationId,
    });
    chat.starred = starred;
  },

  [types.default.BULK_STAR_CONVERSATION]({ allConversations }, conversations) {
    conversations.forEach(conversation => {
      const { conversation_id: conversationId, starred } = conversation;
      const [chat] = getSelectedChatConversation({
        allConversations,
        selectedChatId: conversationId,
      });
      chat.starred = starred;
    });
  },

  [types.default.SET_BULK_CONVERSATION_LABELS](
    { allConversations },
    conversations
  ) {
    conversations.forEach(conversation => {
      const { conversation_id: conversationId, labels } = conversation;
      const [chat] = getSelectedChatConversation({
        allConversations,
        selectedChatId: conversationId,
      });
      labels.forEach(label => {
        if (!chat.labels.includes(label)) {
          chat.labels.push(label);
        }
      });
    });
  },

  [types.default.MUTE_CONVERSATION](_state) {
    const [chat] = getSelectedChatConversation(_state);
    chat.muted = true;
  },

  [types.default.UNMUTE_CONVERSATION](_state) {
    const [chat] = getSelectedChatConversation(_state);
    chat.muted = false;
  },

  [types.default.SET_UNREAD_COUNT](
    _state,
    { conversation_id, unread_message_count }
  ) {
    setUnreadCount(
      _state.allConversations,
      conversation_id,
      unread_message_count
    );
    setUnreadCount(
      _state.allTabConversations,
      conversation_id,
      unread_message_count
    );
  },

  [types.default.ADD_PENDING_MESSAGE](_state, echoId) {
    _state.pendingMessages.push(echoId);
  },

  [types.default.REMOVE_PENDING_MESSAGE](_state, echoId) {
    removePendingMessage(echoId);
  },

  [types.default.ADD_MESSAGE](_state, message) {
    const { allConversations, selectedChatId } = _state;

    const { conversation_id: conversationId } = message;

    const [chat] = getSelectedChatConversation({
      allConversations,
      selectedChatId: conversationId,
    });

    if (!chat) return;

    const pendingMessageIndex = findPendingMessageIndex(chat, message);
    if (pendingMessageIndex !== -1) {
      Vue.set(chat.messages, pendingMessageIndex, message);
    } else {
      chat.messages.push(message);
      chat.timestamp = message.created_at;
      if (selectedChatId === conversationId) {
        window.bus.$emit('scrollToMessage');
      }
    }
    if (chat.status === 'open') {
      playNotificationAudio();
    }
  },

  // not a good way to update timestamp in the state but doing it to be on a safer side as it is done above.
  [types.default.UPDATE_MESSAGE_TIMESTAMP_IN_ALL_TAB_CONVERSATION](
    _state,
    message
  ) {
    const { allTabConversations } = _state;
    const { conversation_id: conversationId } = message;

    const [chat] = getSelectedChatConversation({
      allConversations: allTabConversations,
      selectedChatId: conversationId,
    });

    if (!chat) return;

    chat.messages.push(message);
    chat.timestamp = message.created_at;
  },

  [types.default.DELETE_MESSAGE](_state, message) {
    const { allConversations } = _state;
    const { conversationId, messageId, data } = message;

    const currentConversationIndex = allConversations.findIndex(
      c => c.id === conversationId
    );

    if (currentConversationIndex > -1) {
      const currentMessageIndex = allConversations[
        currentConversationIndex
      ].messages.findIndex(m => m.id === messageId);

      if (currentMessageIndex > -1) {
        const messages = [];

        allConversations[currentConversationIndex].messages.map(
          // eslint-disable-next-line array-callback-return
          (obj, index) => {
            if (index === currentMessageIndex) {
              messages.push({ ...data });
            } else {
              messages.push({ ...obj });
            }
          }
        );

        const currentConversation = {
          ...allConversations[currentConversationIndex],
          messages,
        };

        Vue.set(
          allConversations,
          currentConversationIndex,
          currentConversation
        );
      }
    }
  },

  [types.default.ADD_CONVERSATION](_state, conversation) {
    _state.allConversations.push(conversation);
  },

  [types.default.ADD_CONVERSATION_TO_ALL_TAB](_state, conversation) {
    _state.allTabConversations.push(conversation);
  },

  [types.default.UPDATE_CONVERSATION](_state, conversation) {
    updateConversationInArray(
      _state.allConversations,
      conversation,
      _state.selectedChatId
    );
  },

  [types.default.UPDATE_ALL_TAB_CONVERSATION](_state, conversation) {
    updateConversationInArray(
      _state.allTabConversations,
      conversation,
      _state.selectedChatId
    );
  },

  [types.default.SET_LIST_LOADING_STATUS](_state) {
    _state.listLoadingStatus = true;
  },

  [types.default.CLEAR_LIST_LOADING_STATUS](_state) {
    _state.listLoadingStatus = false;
  },

  [types.default.MARK_MESSAGE_READ](_state, { id, lastSeen }) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    if (chat) {
      chat.agent_last_seen_at = lastSeen;
    }
  },

  [types.default.CHANGE_CHAT_STATUS](_state, data) {
    _state.chatStatusFilter = data;
  },

  // Update assignee on action cable message
  [types.default.UPDATE_ASSIGNEE](_state, payload) {
    const [chat] = _state.allConversations.filter(c => c.id === payload.id);
    if (chat) {
      Vue.set(chat.meta, 'assignee', payload.assignee);
      chat.forwarded_conversation = payload.forwarded_conversation;
      chat.assigned_by = payload.assigned_by;
      chat.assigned_by_resource_id = payload.assigned_by_resource_id;
    }
  },

  [types.default.UPDATE_CONVERSATION_CONTACT](
    _state,
    { conversationId, ...payload }
  ) {
    const [chat] = _state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      Vue.set(chat.meta, 'sender', payload);
    }
  },

  [types.default.SET_ACTIVE_INBOX](_state, inboxId) {
    _state.currentInbox = inboxId ? parseInt(inboxId, 10) : null;
  },

  [types.default.SET_CONVERSATION_CAN_REPLY](
    _state,
    { conversationId, canReply }
  ) {
    const [chat] = _state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      Vue.set(chat, 'can_reply', canReply);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
