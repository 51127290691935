export const humanReadableDate = (
  date,
  options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
) => {
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};
