/* eslint-disable no-console */
import axios from 'axios';
import auth from '../api/auth';
import constants from '../constants';
import { getInstance } from '../auth0';

const createApiInstance = () => {
  const auth0 = getInstance();

  const instance = axios.create({
    baseURL: constants.apiURL,
  });

  if (!auth0.isAuthenticated || auth0.loading) {
    // return the instance without interceptors and authorization headers
    return instance;
  }

  instance.interceptors.request.use(async config => {
    try {
      const token = await auth0.getTokenSilently();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } catch (error) {
      console.error('HD log axios token fetch:', error);
      auth.logout({ withAlert: true, alertTimeout: 3000 });

      return Promise.reject(error);
    }
  });

  instance.interceptors.response.use(
    response => response,
    async error => {
      if (!error.response) {
        return Promise.reject(error);
      }

      const originalRequest = error.config;
      if (constants.UNAUTHORIZED_STATUSES.includes(error.response.status)) {
        if (!originalRequest.withRetry) {
          originalRequest.withRetry = true;

          try {
            const newToken = await auth0.getTokenSilently({ cacheMode: 'off' });
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return instance(originalRequest);
          } catch (renewalError) {
            console.error('HD log axios 401 retry:', renewalError);
            // Token renewal failed - log out the user and redirect to login
            auth.logout({ withAlert: true, alertTimeout: 3000 });

            return Promise.reject(renewalError);
          }
        } else {
          console.error('HD logging out user on 401');
          auth.logout({ withAlert: true, alertTimeout: 3000 });
        }
      }

      if (axios.isCancel(error)) {
        // request cancelled
        return null;
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default createApiInstance;
