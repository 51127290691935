<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <page-header
      :header-title="$t('CONVERSATION.SEARCH.TITLE')"
      :show-border="false"
    />
    <div class="search-feature">
      <div class="search-bar flex-row flex-align">
        <div v-if="searchByFilter" class="search-by-filter">
          <woot-base-button
            size="small"
            :front-icon="searchByFilter.icon"
            back-icon="cross"
            @click="removeFilter"
          >
            {{ searchByFilter.name }}
          </woot-base-button>
        </div>
        <search-box
          ref="searchBox"
          variant
          class="search-box"
          :placeholder="$t('CONVERSATION.SEARCH.PLACEHOLDER')"
          :value="searchTermComputed"
          @setSearch="setSearch"
        />
      </div>
      <div v-if="!searchByFilter" class="filters-section">
        <div
          class="text-light body-b2 mg-bottom--small"
          v-text="$t('CONVERSATION.SEARCH.SEARCH_BY.HEADING')"
        />
        <div class="search-by-options flex-row gap--small">
          <woot-base-button
            v-for="option in SEARCH_BY_OPTIONS"
            :key="option.name"
            size="small"
            variant="secondary"
            class="search-by-btn"
            :front-icon="option.icon"
            @click="applySearchByFilter(option)"
          >
            {{ option.name }}
          </woot-base-button>
        </div>
      </div>
      <div v-if="showFilterByOptions" class="filters-section">
        <div
          class="text-light body-b2 mg-bottom--small"
          v-text="$t('CONVERSATION.SEARCH.FILTER_BY.HEADING')"
        />
        <div class="filters flex-row flex-align--start gap--small">
          <status-time-filter
            :default-selection="defaultRangeSelection"
            @date-range-change="onDateRangeChange"
          />
          <woot-single-selector
            variant="secondary"
            size="small"
            front-icon="inbox"
            :default-option="selectedInboxName"
            :option-list="inboxList"
            :show-selected-text="false"
            :custom-style="{ left: 0, width: '20rem' }"
            :button-style="{
              color: '#808975',
              padding: '0.4rem 0.6rem',
            }"
            @click="applyInboxFilter"
          />
        </div>
      </div>
      <div class="search-results-container">
        <div
          class="text-light body-b2 mg-bottom--small"
          v-text="$t('CONVERSATION.SEARCH.RESULTS.HEADING')"
        />
        <div class="results custom-scroll">
          <transition name="modal-fade" mode="out-in" appear>
            <img
              v-if="
                !conversations.length && !hasSearched && !uiFlags.isFetching
              "
              :src="require('dashboard/assets/images/search_zero_state.svg')"
              class="zero-state"
              alt="search-for-results"
            />
            <loading-state
              v-else-if="uiFlags.isFetching"
              class="loading-state"
              :message="loadingMessage"
            />
            <div v-else-if="showConversationCards" class="result-cards">
              <conversation-details-card
                v-for="result in conversations"
                :key="result.id"
                :identifier="result.conversation.identifier"
                :assignee-name="result.conversation.agent_name"
                :contact-name="result.conversation.contact_name"
                :display-id="result.conversation.display_id"
                :content="result.content"
                :data="result"
                :search-term="searchTermComputed"
                @routeNavigated="onClose(result.conversation.display_id)"
              />
            </div>
            <div
              v-else-if="showContactCards"
              class="result-cards contact-cards"
            >
              <contact-details-card
                v-for="conversation in conversations"
                :key="conversation.id"
                :data="conversation"
                :search-term="searchTermComputed"
                @routeNavigated="conversationid => onClose(conversationid)"
              />
            </div>
            <img
              v-if="!uiFlags.isFetching && hasSearched && !conversations.length"
              :src="require('dashboard/assets/images/search_not_found.svg')"
              class="zero-state"
              alt="no-results-found"
            />
          </transition>
          <transition name="modal-fade" mode="out-in" appear>
            <div v-if="showLoadMore" class="flex-row flex-justify">
              <woot-base-button
                variant="tertiary"
                size="small"
                @click="loadMoreResults"
              >
                {{ $t('CONVERSATION.SEARCH.RESULTS.LOAD_MORE') }}
              </woot-base-button>
            </div>
            <div
              v-if="showEndOfResultsText"
              class="text-center body-b2 text-light"
              v-text="$t('CONVERSATION.SEARCH.RESULTS.END_OF_RESULTS')"
            />
          </transition>
        </div>
      </div>
    </div>
    <div class="medium-12 columns settings-sub-menu-bottom-nav">
      <woot-base-button
        size="small"
        variant="secondary-danger"
        @click="resetAllFilters"
      >
        {{ $t('CONVERSATION.SEARCH.RESET') }}
      </woot-base-button>
      <woot-base-button
        size="small"
        :disabled="uiFlags.isFetching"
        @click="saveSearch"
      >
        {{ $t('CONVERSATION.SEARCH.APPLY.TITLE') }}
      </woot-base-button>
    </div>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import StatusTimeFilter from 'dashboard/components/widgets/conversation/filter/StatusTimeFilterDeprecated';
import LoadingState from 'dashboard/components/ui/LoadingState';
import ConversationDetailsCard from 'dashboard/components/ui/ConversationDetailsCard';
import ContactDetailsCard from 'dashboard/components/ui/ContactDetailsCard';

import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { SEARCH_BY, SEARCH_BY_OPTIONS } from './constants';
import { isEnter } from 'shared/helpers/KeyboardHelpers';
import * as types from 'shared/constants/googleEventType';
import { manifestSearchResults } from './helpers';

export default {
  components: {
    PageHeader,
    SearchBox,
    StatusTimeFilter,
    LoadingState,
    ConversationDetailsCard,
    ContactDetailsCard,
  },
  mixins: [alertMixin, googleAnalyticsMixin, mixPanelAnalyticsMixin],
  props: {
    savedSearchData: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      searchTerm: '',
      searchMessage: '',
      page: 1,
      defaultRangeSelection: 3,
      show: true,
      hasSearched: false,
      endReached: false,
      searchByFilter: null,
      statusTimeRange: null,
      selectedInbox: { id: 0, name: 'All' },
      SEARCH_BY_OPTIONS,
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      conversations: 'conversationSearch/getConversations',
      uiFlags: 'conversationSearch/getUIFlags',
    }),
    searchTermComputed() {
      return this.searchMessage || this.searchTerm;
    },
    showEndOfResultsText() {
      return (
        this.conversations.length && this.endReached && !this.uiFlags.isFetching
      );
    },
    showLoadMore() {
      return (
        (this.showConversationCards || this.showContactCards) &&
        !this.endReached &&
        !this.uiFlags.isFetching
      );
    },
    showConversationCards() {
      const canShow =
        !this.searchByFilter || this.searchByFilter.value === SEARCH_BY.MESSAGE;
      return canShow && this.conversations.length && !this.uiFlags.isFetching;
    },
    showContactCards() {
      const canShow =
        this.searchByFilter && this.searchByFilter?.value !== SEARCH_BY.MESSAGE;
      return canShow && this.conversations.length && !this.uiFlags.isFetching;
    },
    showFilterByOptions() {
      const searchByMessages =
        this.searchByFilter && this.searchByFilter.value === SEARCH_BY.MESSAGE;
      return (
        searchByMessages ||
        (!this.searchByFilter && this.searchTermComputed.trim())
      );
    },
    loadingMessage() {
      const commonMessage = this.$t('CONVERSATION.SEARCH.LOADING_FOR_MESSAGE');
      if (!this.searchByFilter) return commonMessage;

      switch (this.searchByFilter.value) {
        case SEARCH_BY.MESSAGE:
          return commonMessage;
        case SEARCH_BY.NAME:
          return this.$t('CONVERSATION.SEARCH.LOADING_FOR_PEOPLE');
        default:
          return this.$t('CONVERSATION.SEARCH.LOADING_FOR_CONTACT');
      }
    },
    selectedInboxName() {
      return this.selectedInbox.name === 'All'
        ? 'All Inboxes'
        : this.selectedInbox.name;
    },
    inboxList() {
      let inboxesData = [];

      inboxesData = this.inboxes.reduce((ibList, ib) => {
        ibList.push({
          id: ib.id,
          name: ib.name,
          type: ib.channel_type,
        });

        return ibList;
      }, []);

      inboxesData = [{ id: 0, name: 'All' }, ...inboxesData];

      return inboxesData;
    },
  },
  watch: {
    searchTerm(newValue) {
      this.googleAnalyticsEvent(
        types.default.MESSAGE_SEARCH,
        types.default.DASHBOARD_MID_PANEL,
        newValue
      );

      this.searchMessage = '';
      this.clearActiveTimeout();

      this.typingTimer = setTimeout(() => {
        this.getSearchResults();
        this.hasSearched = true;
      }, 1000);
    },
  },
  mounted() {
    if (this.savedSearchData) this.populateData();

    document.addEventListener('keydown', this.handleKeyEvents);
  },
  destroyed() {
    this.onClose();
    this.$store.dispatch('conversationSearch/resetSearchResults');
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
    populateData() {
      const {
        query,
        results,
        filters: { searchBy, statusTimeRange, inbox },
      } = this.savedSearchData;

      // set search query
      this.searchMessage = query;

      // set search by filter
      this.searchByFilter = searchBy;

      // set time filter
      this.defaultRangeSelection = statusTimeRange?.default || 3;

      // set inbox filter
      this.selectedInbox = inbox;

      // set conversations
      this.$store.dispatch(
        'conversationSearch/setSearchConversations',
        results
      );
    },
    saveSearch() {
      let payload = {
        query: this.searchTermComputed,
        filters: {
          searchBy: this.searchByFilter,
        },
        results: this.conversations,
      };

      if (
        !this.searchByFilter ||
        this.searchByFilter?.value === SEARCH_BY.MESSAGE
      )
        payload = {
          ...payload,
          filters: {
            ...payload.filters,
            statusTimeRange: this.statusTimeRange,
            inbox: this.selectedInbox,
          },
        };

      this.$store.dispatch('conversationSearch/saveSearchResults', payload);
      this.mix_panel_clicked_ticket_save_search('Ticket Search');
      this.onClose();
    },
    loadMoreResults() {
      this.page += 1;
      this.getSearchResults(this.page, true);
    },
    async performSearch(action, params, append = false) {
      const response = await manifestSearchResults(
        this.$store.dispatch,
        append,
        this.searchTermComputed,
        () => this.$store.dispatch(action, params)
      );

      if (!response.length) {
        this.endReached = true;
        if (!this.searchTermComputed.trim()) this.hasSearched = false;
      }
    },
    async getSearchResults(page = 1, append = false) {
      const queryType = this.searchByFilter?.value || SEARCH_BY.MESSAGE;
      if (queryType === SEARCH_BY.MESSAGE) {
        const params = {
          query_type: SEARCH_BY.MESSAGE,
          q: this.searchTermComputed,
          page,
          append,
          ...this.getAdditionalParams(),
        };
        this.endReached = false;
        await this.performSearch('conversationSearch/get', params, append);
      } else {
        const params = {
          search: this.searchTermComputed,
          searchBy: queryType,
          page,
        };
        await this.performSearch('contacts/search', params, append);
      }
    },
    getAdditionalParams() {
      if (
        !this.searchByFilter ||
        this.searchByFilter?.value === SEARCH_BY.MESSAGE
      ) {
        return {
          status_time_range: this.statusTimeRange,
          inbox_id:
            this.selectedInbox.id === 0 ? undefined : this.selectedInbox.id,
        };
      }
      return {};
    },
    setSearch(value) {
      this.searchTerm = value;
    },
    clearActiveTimeout() {
      if (this.typingTimer) clearTimeout(this.typingTimer);
    },
    handleKeyEvents(e) {
      if (isEnter(e) && !this.uiFlags.isFetching) {
        this.googleAnalyticsEvent(
          types.default.MESSAGE_SEARCH,
          types.default.DASHBOARD_MID_PANEL,
          this.searchTermComputed
        );

        this.clearActiveTimeout();

        this.hasSearched = true;
        this.getSearchResults();
      }
    },
    resetAllFilters() {
      this.searchTerm = '';
      this.searchMessage = '';
      this.searchByFilter = null;
      this.statusTimeRange = null;
      this.selectedInbox = { id: 0, name: 'All' };
      this.$store.dispatch('conversationSearch/resetSearchResults');
      this.$store.dispatch('conversationSearch/clearSavedResult');
      this.focusSearchBox();
      this.mix_panel_clicked_ticket_search_reset('Ticket Search');
    },
    focusSearchBox() {
      this.$refs.searchBox.$refs.searchInput.focus();
    },
    applySearchByFilter(option) {
      if (this.uiFlags.isFetching) return;
      this.searchByFilter = option;
      this.focusSearchBox();
      this.getSearchResults();
      this.mix_panel_clicked_ticket_search_category(
        'Ticket Search',
        option.name
      );
    },
    removeFilter() {
      if (this.uiFlags.isFetching) return;

      this.focusSearchBox();

      this.searchByFilter = null;
      this.statusTimeRange = null;
      this.selectedInbox = { id: 0, name: 'All' };
      this.$store.dispatch('conversationSearch/resetSearchResults');
      this.getSearchResults().then(() => {
        this.searchByFilter = null;
      });
    },
    onDateRangeChange(value) {
      this.statusTimeRange = value;
      if (!this.searchTermComputed.trim() || !this.hasSearched) return;
      this.getSearchResults();
      if (value && !value.isFiredOnMount) {
        this.mix_panel_clicked_date_filter_menu_item(
          'Ticket Search',
          value.date_range_name
        );
      }
    },
    applyInboxFilter(val) {
      const filteredInbox = this.inboxes.find(inbox => inbox.name === val.name);
      if (filteredInbox && filteredInbox.channel_type) {
        this.mix_panel_clicked_inbox_filter_menu_item(
          'Ticket Search',
          filteredInbox.channel_type
        );
      }
      this.selectedInbox = val;
      if (!this.searchTermComputed.trim()) return;
      this.getSearchResults();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.search {
  &-feature {
    padding: $zero $space-medium;

    .filters-section {
      padding-top: $space-small;

      .search-by-options {
        flex-wrap: wrap;

        .search-by-btn {
          width: $space-one * 10;
        }
      }

      .filters {
        flex-wrap: wrap;
      }
    }

    .search-results-container {
      border-top: 1px solid $neutral-grey-400;
      margin-top: $space-slab;
      padding: $space-slab $zero;

      .results {
        height: $space-three * 100;
        position: relative;

        .loading-state {
          height: 36vh;
        }

        .zero-state {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .result-cards {
          padding-right: $space-smaller;
        }
      }
    }
  }

  &-bar {
    border: 1px solid $neutral-grey-400;
    border-radius: $border-radius-smaller;
    padding: $zero $space-small;
  }

  &-box {
    border: none;
    margin-bottom: $zero;
    width: 80%;
  }
}

.settings-sub-menu-bottom-nav {
  justify-content: space-between;
  padding: $space-two $space-medium;
  position: relative;
}
</style>
