<template>
  <div class="keyword-selector">
    <woot-input
      v-model="keyword"
      :size="size"
      :label="inputLabel"
      :placeholder="inputPlaceholder"
      @keyup.enter.native="addKeyword"
    />
    <div class="mg-top--smaller">
      <woot-chips
        v-for="(chip, index) in keywords"
        :key="index"
        :title="chip"
        :custom-style="{
          color: 'text-black-800',
        }"
        variant="secondary-small"
        :show-cancel-icon="true"
        :show-tooltip="false"
        @click="removeKeyword(index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeywordInput',
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    keywords: {
      type: Array,
      default: () => [],
    },
    inputLabel: {
      type: String,
      default: '',
    },
    inputPlaceholder: {
      type: String,
      default: 'Type a keyword and press enter',
    },
  },
  data() {
    return {
      keyword: '',
    };
  },
  methods: {
    addKeyword() {
      if (this.keyword.trim() && !this.keywords.includes(this.keyword.trim())) {
        const updatedKeywords = [...this.keywords];
        updatedKeywords.push(this.keyword.trim());
        this.keyword = '';
        this.$emit('updateKeywords', updatedKeywords);
      }
    },
    removeKeyword(index) {
      const updatedKeywords = [...this.keywords];
      updatedKeywords.splice(index, 1);
      this.$emit('updateKeywords', updatedKeywords);
    },
  },
};
</script>
