<!-- eslint-disable array-callback-return -->
<template>
  <div class="scroll--panel custom-scroll">
    <woot-modal
      :full-width="true"
      :show.sync="showImagePreview"
      :on-close="() => (showImagePreview = false)"
    >
      <span class="download-button" @click="onDownload(productImageSrc)">
        <icons name="download" color="green" size="medium" />
      </span>
      <img :src="productImageSrc" class="modal-image" />
    </woot-modal>
    <div class="product-search flex-row">
      <search-box
        :placeholder="$t('PRODUCTS_MGMT.SEARCH_TXT')"
        :value="search"
        :variant="true"
        :on-search="searchProducts"
        @setSearch="setSearch"
      />
      <div
        v-tooltip="
          !permissions.canCreate &&
            $t('ORDER.CTA_PERMISSION_MESSAGES.NO_PERMISSION')
        "
        class="flex-row"
      >
        <span
          class="cart-icon"
          :class="{
            disabled: currentCartId === undefined || !permissions.canCreate,
          }"
          @click="navigateToCart"
        >
          <icons
            name="cart"
            :color="currentCartId !== undefined ? 'green' : 'grey'"
            size="medium"
          />
          <span
            v-if="totalProductsInCart"
            :key="totalProductsInCart"
            class="cart-count zoom-in-out"
          >
            {{ totalProductsInCart }}
          </span>
        </span>
      </div>
    </div>
    <div
      v-if="!uiFlags.fetchingProducts && !productsList.length"
      class="no-order"
    >
      <img
        :src="require('dashboard/assets/images/noOrder.svg')"
        alt="No Product"
      />
      <p class="no-items-error-message">
        {{ $t('PRODUCTS_MGMT.NO_PRODUCTS') }}
      </p>
    </div>
    <div>
      <accordian-card
        v-for="product in productsList"
        :key="product.id"
        custom-desc
        @panelToggled="onClearVariantData"
      >
        <template v-slot:desc>
          <div style="width: 100%" class="flex-row flex-align--start">
            <div class="item-wrap flex-row">
              <div
                class="card__media"
                :class="{
                  'image-unavailable': setImageSource(product).isUnavailable,
                }"
                @click="e => onCardMediaClicked(e, product)"
              >
                <img
                  style="font-size: 1rem"
                  :src="setImageSource(product).source"
                  :alt="'Product Image'"
                />
              </div>
              <div class="card__content flex-column">
                <header v-tooltip="product.name" class="title-h5 text-truncate">
                  {{ product.name }}
                </header>
                <footer
                  class="card__meta flex-space-between"
                  role="contentinfo"
                >
                  <div class="body-b2 flex-row flex-align">
                    <span
                      v-if="setProductPrice(product).onSale"
                      v-html="
                        `${accountCurrency} ${setProductPrice(product).price}`
                      "
                    />
                    <span
                      :class="{
                        'sale-price': setProductPrice(product).onSale,
                      }"
                      v-html="
                        `${accountCurrency} ${
                          setProductPrice(product).comparedAtPrice
                        }`
                      "
                    />
                  </div>
                </footer>
              </div>
            </div>
            <span class="share-icon" @click="e => onShareProduct(e, product)">
              <icons name="share" color="green" size="semimedium" />
            </span>
          </div>
        </template>
        <product-variants
          v-if="product.shopify_variant_options[0].value !== 'Default Title'"
          :id="product.id"
          @variantData="handleVariantData"
          @clearVariantData="onClearVariantData"
          @disableAddToCart="
            disableAddToCart = { ...disableAddToCart, ...$event }
          "
          @availableOptions="availableOptions = $event"
          @resetOptions="onResetOptions = $event"
        />
        <span
          v-if="
            product.shopify_variant_options[0].value === 'Default Title' &&
              product.default_variant.inventory_quantity < 1
          "
          class="text-red title-h6"
        >
          {{ $t('PRODUCTS_MGMT.OUT_OF_STOCK') }}
        </span>
        <hr v-if="product.description" />
        <p
          v-if="product.description"
          class="product-card--excerpt body-b3 text-dark flex-column custom-scroll"
          v-html="product.description"
        />
        <div class="form-buttons flex-row flex-justify--end flex-align">
          <div
            v-tooltip="
              !permissions.canCreate &&
                $t('ORDER.CTA_PERMISSION_MESSAGES.NO_PERMISSION')
            "
          >
            <woot-secondary-button
              size="small"
              :name="$t('PRODUCTS_MGMT.ADD_TO_CART')"
              :disabled="
                (disableAddToCart[product.id] && !!product) ||
                  !permissions.canCreate
              "
              @click="addProductToCart(product)"
            />
          </div>
        </div>
      </accordian-card>
      <woot-loading-state
        v-if="uiFlags.fetchingProducts"
        :message="$t('PRODUCTS_MGMT.LIST.LOADING')"
      />
      <p
        v-if="!uiFlags.fetchingProducts && productsList.length && hasNextPage"
        class="clear button load-more-conversations"
        @click="onPageChange"
      >
        {{ $t('PRODUCTS_MGMT.LIST.LOAD_MORE') }}
      </p>
      <p
        v-if="!uiFlags.fetchingProducts && productsList.length && !hasNextPage"
        class="text-center text-muted end-of-list-text"
      >
        {{ $t('PRODUCTS_MGMT.LIST.LOADED') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { frontendURL, conversationUrl } from 'dashboard/helper/URLHelper';

import AccordianCard from 'dashboard/components/AccordianCard';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import ProductVariants from './ProductVariants';

import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import accountMixin from '../../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import fileMixin from 'shared/mixins/fileMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import deepShopifyPermissionsMixin from 'shared/mixins/deepShopifyPermissionsMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    AccordianCard,
    SearchBox,
    ProductVariants,
  },
  mixins: [
    googleAnalyticsMixin,
    accountMixin,
    alertMixin,
    fileMixin,
    inboxMixin,
    deepShopifyPermissionsMixin,
  ],
  data() {
    return {
      search: '',
      productImageSrc: '',
      page: 1,
      selectedProduct: {},
      disableAddToCart: {},
      variantsData: {},
      showShareModal: false,
      isAddToCartDisabled: true,
      showImagePreview: false,
      availableOptions: [],
      onResetOptions: () => {},
    };
  },
  computed: {
    ...mapGetters({
      productsList: 'getProducts',
      productsInCart: 'getProductsInCart',
      cartDetails: 'getCartMeta',
      meta: 'getProductsMeta',
      uiFlags: 'getProductsUIFlags',
      currentChat: 'getSelectedChat',
      activeInbox: 'getSelectedInbox',
      hideProductLinks: 'accounts/getHideProductLinksFlag',
    }),
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    totalProductsInCart() {
      return this.productsInCart?.length;
    },
    currentCartId() {
      return this.cartDetails.id;
    },
    hasNextPage() {
      const isDisabled = this.page === Math.ceil(this.meta.count / 8);
      return !isDisabled;
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
  },
  created() {
    this.$store.dispatch('getProducts', {
      page: 1,
      is_shopify: true,
    });
  },
  methods: {
    setImageSource(product) {
      const productId = product.id;
      let defaultImage = null;

      if (product?.default_image) {
        // Handling for variant selection
        defaultImage = product.default_image?.src || '';
      } else {
        defaultImage = product.shopify_images[0]?.src || '';
      }

      if (
        Object.keys(this.variantsData).length !== 0 &&
        this.variantsData[productId]
      ) {
        if (this.variantsData[productId].image_link === null) {
          return { isUnavailable: true, source: defaultImage };
        }
        return {
          isUnavailable: false,
          source: this.variantsData[productId].image_link,
        };
      }

      if (!defaultImage)
        return {
          isUnavailable: true,
          source: '',
        };

      return {
        isUnavailable: false,
        source: defaultImage,
      };
    },
    setProductPrice(product) {
      const {
        id: productId,
        price: comparedAtPrice,
        sale_price: price,
      } = product;

      if (
        Object.keys(this.variantsData).length !== 0 &&
        this.variantsData[productId]
      ) {
        if (!this.variantsData[productId].compare_at_price)
          return {
            onSale: false,
            comparedAtPrice: '',
            price: this.variantsData[productId].price,
          };
        if (!this.variantsData[productId].price)
          return {
            onSale: false,
            comparedAtPrice: '',
            price: '',
          };
        return {
          onSale:
            this.variantsData[productId].compare_at_price >
            this.variantsData[productId].price,
          comparedAtPrice: this.variantsData[productId].compare_at_price,
          price: this.variantsData[productId].price,
        };
      }
      return {
        onSale: comparedAtPrice > price,
        comparedAtPrice: comparedAtPrice,
        price: price,
      };
    },
    addProductToCart(selectedProduct) {
      const {
        id: productId,
        default_variant: defaultVariant,
      } = selectedProduct;

      const selectedVariant = this.variantsData[productId] || defaultVariant;

      if (!selectedVariant) {
        this.showAlert(this.$t('CART.ITEM.OUT_OF_STOCK'), 'error');
        return;
      }

      const { id: variantId } = selectedVariant;

      // don't add product to cart if already in cart
      if (
        this.productsInCart.find(
          product => +product.variant_item?.shopify_variant_id === variantId
        )
      ) {
        this.showAlert(this.$t('CART.ITEM.IN_CART'), 'warning');
        return;
      }

      if (!this.currentCartId) {
        this.showAlert(this.$t('CART.NO_CART_ERROR'));
        return;
      }

      const payload = {
        cart: this.currentCartId,
        variant_item: variantId,
        product_item_id: productId,
        quantity: 1,
      };

      try {
        this.$store.dispatch('addProductToCart', payload).then(response => {
          if (response.status === 200) {
            this.showAlert(this.$t('CART.ITEM.ADDED'), 'info');
            this.onResetOptions();
            this.disableAddToCart[productId] = true;
          } else this.showAlert(this.$t('CART.ITEM.ERROR'), 'error');
        });
      } catch (error) {
        this.showAlert(this.$t('CART.ITEM.ERROR'), 'error');
      }
      // this.selectedProduct = selectedVariant;
    },
    setSearch(value) {
      const refetchAllproducts = !!this.search && value === '';
      if (refetchAllproducts) {
        this.$store.dispatch('getProducts', {
          page: 1,
          is_shopify: true,
        });
      }
      this.search = value;
    },
    searchProducts() {
      if (this.search) {
        this.googleAnalyticsEvent(
          types.default.PRODUCTS_SEARCH,
          types.default.PRODUCTS_PANEL,
          this.search
        );
        this.$store.dispatch('getProducts', {
          query: this.search,
          page: 1,
          is_shopify: true,
        });
      }
    },
    handleVariantData(data) {
      this.variantsData = { ...this.variantsData, ...data };
    },
    onClearVariantData() {
      this.variantsData = {};
    },
    onCardMediaClicked(e, product) {
      this.productImageSrc = this.setImageSource(product).source;
      this.showImagePreview = true;
      e.stopPropagation();
    },
    categorisedOptions(product) {
      const allCategories = product.shopify_variant_options;

      if (!allCategories || allCategories[0].value === 'Default Title')
        return '';

      let optionsObj = allCategories.reduce((options, option) => {
        options[option.category] = options[option.category] || [];
        options[option.category].push(option);
        return options;
      }, Object.create(null));

      const separator = '\n\n';

      let optionsString = separator;

      Object.keys(optionsObj).map(category => {
        optionsString += category + ' - ';
        optionsObj[category].map((option, idx) => {
          optionsString += ' ' + option.value;
          if (idx === optionsObj[category].length - 1) return optionsString;
          optionsString += ', ';

          return null;
        });
        optionsString += separator;
        return null;
      });

      return optionsString;
    },
    shareEmail(message, productImage) {
      bus.$emit('openReplyBox', { message, productImage });
    },
    calculateRate(productPrice) {
      if (!productPrice.onSale)
        return `Rate - ${this.accountCurrency}${productPrice.price}`;
      return `Rate (after discount of ${Math.floor(
        100 - (100 * productPrice.price) / productPrice.comparedAtPrice
      )}%) - ${this.accountCurrency}${productPrice.price}`;
    },
    async shareProduct() {
      try {
        let desc = '';

        if (!this.hideProductLinks) {
          if (this.selectedProduct.product_link)
            desc += `\n\nFollow this link to the product:\n${this.selectedProduct.product_link}`;
          else if (this.selectedProduct?.product?.product_link)
            desc += `\n\nFollow this link to the product:\n${this.selectedProduct.product.product_link}`;
        }

        let productName =
          this.selectedProduct?.name || this.selectedProduct.product?.name;
        let variants = this.categorisedOptions(this.selectedProduct);
        let productImage = this.setImageSource(this.selectedProduct).source;
        let rate = this.calculateRate(
          this.setProductPrice(this.selectedProduct)
        );
        let variantTitle = '';

        if (this.variantsData[this.selectedProduct.id])
          variants = this.variantsData[this.selectedProduct.id].title || '';

        if (this.isAnEmailChannel) {
          let msg = `**${productName}**\n\n${variants ||
            variantTitle}\n\n**${rate}**${desc ? '\n\n' + desc : ''}`;
          this.shareEmail(msg.replaceAll('\n\n', '\\\n'), productImage);
          return;
        }

        const messagePayload = {
          conversationId: this.currentChat.id,
          contentType: 'cards',
          contentAttributes: {
            items: [
              {
                title: `*${productName}*\n${
                  variantTitle.trim() ? variantTitle + '\n' : ''
                }${variants ? variants + '\n' : ''}${rate || ''}`,
                description: desc,
                media_url: productImage,
                actions: [],
              },
            ],
          },
        };
        await this.$store.dispatch('sendMessage', messagePayload);
        bus.$emit('scrollToMessage');
      } catch (error) {
        // handle error
      }
      this.showShareModal = false;
    },
    onShareProduct(e, product) {
      e.stopPropagation();

      this.selectedProduct = product;
      this.shareProduct();
    },
    navigateToCart() {
      const { activeInbox } = this;

      const path = conversationUrl({
        accountId: this.currentChat.account_id,
        activeInbox,
        id: this.currentChat.id,
      });

      this.$store.dispatch('setOrderMode', 'create');

      this.$router.push(frontendURL(path) + '/orders/cart');
    },
    closeSharePopup() {
      this.showShareModal = false;
    },
    onPageChange() {
      this.page += 1;
      if (this.search) {
        this.$store.dispatch('getProducts', {
          query: this.search,
          page: this.page,
          append: true,
          is_shopify: true,
        });
      } else {
        this.$store.dispatch('getProducts', {
          page: this.page,
          append: true,
          is_shopify: true,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.product-search {
  gap: $space-small;

  .search {
    flex: 1 0 auto;
  }

  .disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .cart-icon {
    border: 1px solid $neutral-grey-400;
    border-radius: $border-radius;
    cursor: pointer;
    margin-bottom: $space-slab;
    padding: $space-small;
    position: relative;
    transition: border 0.2s ease-out;

    ::v-deep .icon {
      transition: fill 0.2s ease-out;
    }

    .cart-count {
      min-width: 1.4rem;
      padding: 0.1rem 0.5rem;
      text-align: center;
      background: $warn-red-500;
      color: $neutral-white;
      font-size: 0.9rem;
      font-weight: 800;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      right: $space-three;
      top: $space-three;
      z-index: 100;
    }

    &:hover {
      border: 1px solid $neutral-grey-600;

      ::v-deep .icon {
        fill: $pg-1-600;
      }
    }
  }
}

.share-icon {
  cursor: pointer;

  &:hover {
    ::v-deep .icon {
      fill: $pg-1-600;
    }
  }
}

.modal-image {
  max-height: 100%;
}

.download-button {
  position: absolute;
  top: $space-normal + $space-micro;
  right: $space-jumbo;
  cursor: pointer;
}

.item-wrap {
  width: 90%;

  .card__media {
    border: 1px solid $neutral-grey-400;
    border-radius: $border-radius;
    cursor: pointer;
    height: $space-smaller * 13;
    min-width: $space-smaller * 13;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: 0.3s ease all;
    width: $space-smaller * 13;

    &:hover {
      border: 1px solid $neutral-grey-500;
      transform: translateY(-$space-three);

      img {
        transform: scale(1.2);
      }
    }

    img {
      border-radius: $border-radius;
      height: 100%;
      object-fit: cover;
      padding: 0;
      transition: 0.8s ease all;
      width: 100%;
    }
  }

  .image-unavailable {
    z-index: 0;

    &::before {
      background: $neutral-grey-600;
      bottom: $zero;
      content: 'Image Unavailable';
      color: #fff;
      font-size: $font-size-nano;
      font-weight: $font-weight-medium;
      line-height: $space-small;
      text-align: center;
      height: 36%;
      left: $zero;
      opacity: 0.8;
      position: absolute;
      width: 100%;
      z-index: 1;
      animation: slideup 0.3s ease-out;
    }
  }

  .card__content {
    gap: $space-smaller;
    height: 100%;
    width: 100%;
    padding: 0 $space-one;
    text-overflow: ellipsis;
    overflow: hidden;

    .text-truncate {
      cursor: pointer;
      width: $space-normal * 10;
    }

    .card__meta {
      border-bottom: 0;

      .sale-price {
        font-size: $font-size-small;
        margin-left: $space-small;
        color: $neutral-grey-600;
        text-decoration: line-through;
        text-decoration-color: $warn-red-500;
      }
    }
  }
}

.card__content::v-deep a {
  color: $pg-1-500;
}

.card__content::v-deep strong {
  color: $neutral-grey-800;
  font-size: $font-size-medium;
}

.card__content::v-deep img {
  width: 100%;
  margin-bottom: $space-small;
}
.no-order {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 12rem;
    margin-bottom: $space-large;
  }

  p {
    color: $secondary-blue;
    font-size: $font-size-default;
    line-height: $space-medium;
  }
}
.load-more-conversations {
  border: 0;
  color: $pg-1-500;
  font-size: $font-size-small;
  margin: 0;
  padding: $space-normal;
  width: 100%;
}
.end-of-list-text {
  padding: $space-normal;
}
.loading-state {
  padding: $zero;
}

hr {
  background-color: $neutral-grey-400;
  margin: $space-slab $zero;
}

.product-card {
  margin-top: $space-slab;

  &--excerpt {
    max-height: 40vh;
    max-width: 100%;
    position: relative;
    text-align: start;
    word-break: break-word;
  }
}

.form-buttons {
  gap: $space-slab;
  margin-top: $space-slab;
  width: 100%;

  button {
    height: $space-smaller * 7;
    width: $space-largest;
  }
}

@keyframes slideup {
  0% {
    transform: translateY($space-three);
  }
  100% {
    transform: translateY($zero);
  }
}
</style>
