<template>
  <div
    class="column padding-container settings-box custom-scroll"
    style="padding-top: 0px"
  >
    <woot-modal :show.sync="downloading" :on-close="hidePopup">
      <div class="download-note">
        <img
          src="https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
        />
        <div
          v-html="$t('ANALYTICS.DOWNLOAD_REPORT.EMAIL', { email: userEmail })"
        />
      </div>
    </woot-modal>
    <woot-modal :show.sync="downloadError" :on-close="hidePopup">
      <div class="download-note">
        <div>{{ $t('ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL') }}</div>
      </div>
    </woot-modal>

    <div class="filter-pane">
      <date-range-selector
        :default-selection="2"
        @date-range-change="onDateRangeChange"
      />
    </div>
    <div v-if="uiFlag" class="metrics-card-container">
      <metrics-card-skeleton
        v-for="(item, index) in metricsCount"
        :key="index"
      />
    </div>
    <div v-else class="metrics-card-container">
      <metrics-card
        v-for="(item, index) in metricsCount"
        :key="index"
        :title="item.title"
        :sub-title="item.sub"
        :unit="item.unit"
        :color="item.color"
      />
    </div>

    <div v-if="uiFlag" class="flex-space-between">
      <div style="width: 63%">
        <line-chart-skeleton chart-height="17rem" />
      </div>
      <div>
        <pie-chart-skeleton chart-height="23.5rem" />
      </div>
    </div>
    <div v-else class="flex-space-between">
      <div class="bottom-container" style="width: 64%">
        <div class="lime-card-1dp">
          <div style="position: relative">
            <span class="table-title">Periodic sales data</span>
            <line-chart
              :series="chartSeries"
              :categories="chartCategories"
              :colors="chartColors"
              :toolbar-enable="false"
            />
          </div>
        </div>
      </div>

      <div class="bottom-container">
        <div class="lime-card-1dp">
          <pie-chart
            title="New Users Vs Existing Users"
            :series="pieSeries"
            :colors="pieColors"
            :labels="pieLabels"
            :height="292"
            legend-position="bottom"
            :legend-offset-x="0"
            :legend-offset-y="-10"
            :marker-offset-x="0"
            :marker-offset-y="0"
            :chart-offset-x="0"
            :chart-offset-y="0"
          />
        </div>
      </div>
    </div>

    <div v-if="tableUiFlag">
      <table-skeleton />
    </div>
    <div v-else class="medium-12 bottom-container">
      <woot-table
        title="Product wise Sales data"
        :header-list="$t('ANALYTICS.SALES_ANALYTICS_HEADER')"
        class="lime-card-1dp"
        :table-data="formattedTableData"
        :is-sortable="true"
        :is-paginated="true"
        :current-page="currentPage"
        :total-count="tableTotalCount"
        :on-page-change="onPageChange"
        :is-downloadable="true"
        :is-downloading="false"
        download-text="Download"
        text-width="30rem"
        @download-csv="downloadSalesTable"
      />
    </div>

    <div v-if="uiFlag" class="flex-space-between">
      <div class="bottom-container" style="width: 49%">
        <line-chart-skeleton />
      </div>
      <div class="bottom-container" style="width: 49%">
        <line-chart-skeleton />
      </div>
    </div>
    <div v-else class="flex-space-between">
      <div class="bottom-container" style="width: 49%">
        <div class="lime-card-1dp medium-12">
          <funnel-chart
            id="chartdiv1"
            :subtitle="productTransactionValue"
            :data="funnelChartDataOne"
            :chart-colors="funnelOneColorSet"
            title="Product Quiz conversion"
          />
        </div>
      </div>
      <div class="bottom-container" style="width: 49%">
        <div class="lime-card-1dp medium-12">
          <funnel-chart
            id="chartdiv2"
            :subtitle="pdpTransactionValue"
            :data="funnelChartDataTwo"
            :chart-colors="funnelTwoColorSet"
            title="PDP Entry Conversion"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MetricsCard from '../components/MetricsCard';
import LineChart from 'dashboard/components/widgets/chart/LineChart';
import PieChart from 'dashboard/components/widgets/chart/PieChart';
import FunnelChart from 'dashboard/components/widgets/chart/FunnelChart';
import DateRangeSelector from '../components/DateRangeSelector';
import LineChartSkeleton from 'dashboard/components/LineChartSkeleton.vue';
import TableSkeleton from '../components/TableSkeleton.vue';
import MetricsCardSkeleton from '../components/MetricsCardSkeleton.vue';
import PieChartSkeleton from '../components/PieChartSkeleton.vue';

import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import downloadMixin from 'dashboard/mixins/download';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { getUserEmail } from 'dashboard/helper/cookieHelper';

export default {
  components: {
    MetricsCard,
    LineChart,
    PieChart,
    FunnelChart,
    DateRangeSelector,
    LineChartSkeleton,
    TableSkeleton,
    MetricsCardSkeleton,
    PieChartSkeleton,
  },
  mixins: [alertMixin, timeMixin, downloadMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      dateRange: '1',
      startDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      endDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      salesMetrics: null,
      revenuePerChatChart: null,
      salesDataTable: null,
      salesPieChart: null,
      productQuizConversion: null,
      pdpEntryConverion: null,
      downloading: false,
      downloadError: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlag: 'getSalesUIFlag',
      tableUiFlag: 'getSalesTableUIFlag',
      accountId: 'getCurrentAccountId',
    }),
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
    metricsCount() {
      return [
        {
          title:
            this.salesMetrics && this.salesMetrics['pre-purchased_sessions']
              ? this.salesMetrics['pre-purchased_sessions']
              : 0,
          sub: 'Pre-purchased sessions',
        },
        {
          title: this.salesMetrics?.total_orders
            ? this.salesMetrics?.total_orders
            : 0,
          sub: 'Total Orders',
        },
        {
          title: this.salesMetrics?.revenue_generated
            ? this.salesMetrics?.revenue_generated
            : 0,
          sub: 'Revenue Generated',
        },
        {
          title: this.salesMetrics?.conversion_rate
            ? this.salesMetrics?.conversion_rate
            : 0,
          sub: 'Conversion Rate',
        },
        {
          title:
            this.accountCurrency + this.salesMetrics?.revenue_per_chat
              ? this.salesMetrics?.revenue_per_chat
              : 0,
          sub: 'Revenue per chat',
        },
      ];
    },
    chartSeries() {
      return this.revenuePerChatChart?.yaxis;
    },
    chartCategories() {
      return this.revenuePerChatChart?.xaxis?.map(date =>
        this.dateToWords(date)
      );
    },
    chartColors() {
      return ['#F6BD16'];
    },
    pieSeries() {
      return this.salesPieChart?.percent;
    },
    pieLabels() {
      return ['Existing Users', 'New Users'];
    },
    pieColors() {
      return ['#6BAC1B', '#1B83AC'];
    },
    formattedTableData() {
      let formattedData = this.salesDataTable?.results?.map(row => {
        let newRow = {};
        newRow.products = row.name;
        newRow.shown = row.Shown;
        newRow.units_sold = row.units_sold;
        newRow.sales = row.sale;
        return newRow;
      });
      return formattedData;
    },
    tableTotalCount() {
      return this.salesDataTable?.count;
    },
    currentPage() {
      if (this.salesDataTable?.count > 0) {
        if (this.salesDataTable?.next) {
          let pageIndex = new URL(this.salesDataTable?.next)?.searchParams?.get(
            'page'
          );
          // eslint-disable-next-line radix
          return parseInt(pageIndex) - 1;
        }
        if (this.salesDataTable?.previous) {
          let pageIndex = new URL(
            this.salesDataTable?.previous
          )?.searchParams?.get('page');

          // eslint-disable-next-line radix
          return parseInt(pageIndex) + 1;
        }
      }
      return 0;
    },
    productTransactionValue() {
      if (this.productQuizConversion?.slice(-1)[0])
        return 'Transaction: ' + this.productQuizConversion?.slice(-1)[0].value;
      return '';
    },
    pdpTransactionValue() {
      if (this.pdpEntryConverion?.slice(-1)[0])
        return 'Transaction: ' + this.pdpEntryConverion?.slice(-1)[0].value;
      return '';
    },
    funnelChartDataOne() {
      return this.productQuizConversion?.slice(0, 3);
    },
    funnelChartDataTwo() {
      return this.pdpEntryConverion?.slice(0, 2);
    },
    funnelOneColorSet() {
      return ['#6BAC1B', '#1B83AC', '#9270CA', '#5D7092'];
    },
    funnelTwoColorSet() {
      return ['#6BAC1B', '#9270CA', '#5D7092'];
    },
    userEmail() {
      return getUserEmail();
    },
  },
  methods: {
    onDateRangeChange({ from, to, date_range_name, isFiredOnMount }) {
      this.startDate = this.formatDate(from * 1000).replaceAll('-', '/');
      this.endDate = this.formatDate(to * 1000).replaceAll('-', '/');
      this.getSalesMetrics();
      this.getRevenuePerChat();
      this.getNewVsExistingUsers();
      this.getSalesDataTable();
      this.getProductQuizConversion();
      this.getPdpEntryConversion();
      if (!isFiredOnMount) {
        this.mix_panel_clicked_date_filter_menu_item(
          'Sales Overview',
          date_range_name
        );
      }
    },

    nFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(0) + 'K';
      }
      if (num > 1000000) {
        return (num / 1000000).toFixed(0) + 'M';
      }
      if (num < 900) {
        return Math.round(num * 100) / 100;
      }
      return Math.round(num * 100) / 100;
    },

    async getSalesMetrics() {
      await this.$store
        .dispatch('getSalesOverview', {
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then(data => {
          this.salesMetrics = data;
        });
    },

    async getRevenuePerChat() {
      await this.$store
        .dispatch('getSalesLineChart', {
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then(data => {
          this.revenuePerChatChart = data;
        });
    },

    async getNewVsExistingUsers() {
      await this.$store
        .dispatch('getSalesPieChart', {
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then(data => {
          this.salesPieChart = data;
        });
    },
    async onPageChange(page) {
      await this.$store
        .dispatch('getSalesTable', {
          page: page,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then(data => {
          this.salesDataTable = data;
        });
    },
    async downloadSalesTable() {
      this.mix_panel_clicked_report_menu_item(
        'sales_analytics',
        'product_wise_sales'
      );
      this.downloading = true;
      await this.$store
        .dispatch('downloadSalesTable', {
          download: true,
          startDate: this.startDate,
          endDate: this.endDate,
          email: this.userEmail,
        })
        .then(() => {})
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
    async getSalesDataTable() {
      await this.$store
        .dispatch('getSalesTable', {
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then(data => {
          this.salesDataTable = data;
        });
    },
    async getProductQuizConversion() {
      await this.$store
        .dispatch('getSalesFunnelOne', {
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then(data => {
          this.productQuizConversion = data;
        });
    },

    async getPdpEntryConversion() {
      await this.$store
        .dispatch('getSalesFunnelTwo', {
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then(data => {
          this.pdpEntryConverion = data;
        });
    },
    hidePopup() {
      this.downloading = false;
      this.downloadError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.settings-container {
  display: flex;
  flex-direction: column;
}
.chart-card {
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-one $space-medium;
}
.metrics-card-container {
  display: flex;
  flex-direction: row;
  margin: $space-micro $space-micro $space-normal $space-micro;
}
.bottom-container {
  display: flex;
  flex-direction: column;
  margin: $zero $space-micro $space-normal;
}
.cancel-button {
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
}
.selector-button {
  background: $neutral-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
  &:focus {
    border: 1px solid $pg-1-500;
    outline: none;
  }
}
.section-title {
  font-size: $font-size-medium;
  line-height: $space-medium;
  color: $neutral-grey-600;
  margin-bottom: $space-slab;
}
.button {
  margin-bottom: auto;
}

.margin-right {
  margin-left: $space-medium;
}

.metric-card-skeleton {
  margin-right: $space-medium;
}

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-medium $zero;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}
.filter-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: #faf9f5;
  top: $zero;
  z-index: 1000;
}
</style>
