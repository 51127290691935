<template>
  <div class="flex-row flex-align">
    <span style="cursor: pointer" class="mg-right" @click="navigateBack">
      <icons
        name="chevronLeft"
        color="grey"
        size="mediumlarge"
        view="0 -1 24 24"
        title="Go back"
      />
    </span>
    <span
      class="title-h3 text-dark"
      v-text="$t('CREATE_TICKET.HEADER.TITLE')"
    />
    <create-ticket-button
      class="mg-left--slab CREATE_TICKET_INBOX_ID"
      :inbox="inbox"
      is-menu-styled
    />
  </div>
</template>
<script>
import CreateTicketButton from 'dashboard/components/buttons/CreateTicketButton';

export default {
  components: { CreateTicketButton },
  props: {
    inbox: {
      type: Object,
      default: null,
    },
  },
  methods: {
    navigateBack() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>
