import { isValidPhoneNumber } from 'libphonenumber-js';

export default {
  methods: {
    highlightedBrackets(data = '', format) {
      if (!data) return data;
      let formattedData = data.replaceAll(
        '{}',
        '<span class="brackets--highlight">{}</span>'
      );
      if (format) formattedData = formattedData.replaceAll('\n', '<br/>');
      return formattedData;
    },
    broadcastEnabledChannel(inboxes) {
      let filteredInboxIds = inboxes.filter(inbox => {
        return [
          'Channel::ChatApi',
          'Channel::Maytapi',
          'Channel::Acl',
          'Channel::Gupshup',
          'Channel::Zoko',
          'Channel::TwoFactor',
          'Channel::Wati',
          'Channel::TwilioSms',
          'Channel::ValueFirst',
          'Channel::GupshupEnterprise',
          'Channel::WhatsappCloudApi',
          'Channel::ThreeSixtyDialog',
        ].includes(inbox.channel_type);
      });

      return filteredInboxIds.map(inbox => inbox.id);
    },
    broadcastTimeCalculator(broadcastCount) {
      let timeForOne = 0.0625; // 960 brd per minute.
      let totalTimeInMinutes = broadcastCount * timeForOne;

      if (totalTimeInMinutes < 1) {
        return 'Less than 1 minute';
      }
      if (totalTimeInMinutes > 1 && totalTimeInMinutes < 5) {
        return 'Around 5 minutes';
      }
      if (totalTimeInMinutes > 5 && totalTimeInMinutes < 60) {
        return `Around ${Math.floor(totalTimeInMinutes)} minutes`;
      }

      return 'Will take significant time (1-5 hours)';
    },
    verifyPhoneNumbers(phoneNumberString, duplicate = true, allOptOutSet) {
      let payload = {
        defectiveNumberList: [],
        temporaryPhoneNumberList: [],
        errorState: false,
        errorMessages: [],
        optedOutNumbers: [],
      };

      if (phoneNumberString === '') {
        payload.errorMessages.push(
          this.$t('BROADCAST.BROADCAST_TAB.FORM.ERROR.PHONE_SELECT')
        );
        payload.error = true;
        return payload;
      }

      let phoneNumbers = phoneNumberString.replace(/\+/g, '');
      phoneNumbers = phoneNumbers.replace(/-/g, '');
      phoneNumbers = phoneNumbers.replace(/'/g, '');
      phoneNumbers = phoneNumbers.replace(/ /g, '').split(',');

      phoneNumbers = phoneNumbers.filter(el => {
        return el !== '';
      });
      // confirm the opted out numbers
      if (allOptOutSet?.size > 0) {
        payload.optedOutNumbers = phoneNumbers.filter(element => {
          return (
            allOptOutSet.has(element) || allOptOutSet.has(element.slice(2))
          );
        });
      }
      // eslint-disable-next-line no-restricted-syntax
      phoneNumbers.forEach((ph, i) => {
        if (isValidPhoneNumber('+' + ph)) {
          payload.temporaryPhoneNumberList.push(ph);
        } else if (
          ph.substring(0, 2) === '91' &&
          isValidPhoneNumber('+' + ph.substring(2))
        ) {
          // special case backward compatibilty for Indian clients
          payload.temporaryPhoneNumberList.push(ph.substring(2));
        } else if (ph.length === 10 && isValidPhoneNumber('+91' + ph)) {
          // special case backward compatibilty for Indian clients
          payload.temporaryPhoneNumberList.push('91' + ph);
        } else {
          payload.defectiveNumberList.push(ph);
          payload.errorMessages.push(
            `${ph} at position (${i + 1}), ${this.$t(
              'BROADCAST.BROADCAST_TAB.FORM.ERROR.INVALID_PHONE'
            )}`
          );
          payload.errorState = true;
        }
      });

      // filter out opted out contacts
      if (allOptOutSet?.size > 0) {
        payload.temporaryPhoneNumberList = payload.temporaryPhoneNumberList.filter(
          element => {
            return !allOptOutSet.has(element);
          }
        );
      }
      // remove duplicate entries
      if (duplicate) {
        let seen = new Set();
        payload.temporaryPhoneNumberList = payload.temporaryPhoneNumberList.filter(
          item => {
            let k = JSON.stringify(item);
            return seen.has(k) ? false : seen.add(k);
          }
        );
      }

      return payload;
    },

    verifyPhoneNumber(code, phn, allOptOutSet) {
      let phoneNumberString = code.toString() + phn.toString();
      phoneNumberString = phoneNumberString.replaceAll(',', '');
      let phone = phoneNumberString.replace(/\+/g, '');
      phone = phone.replace(/-/g, '');
      phone = phone.replace(/'/g, '');
      phone = phone.replace(/ /g, '').split(',');
      let ph = phone[0];
      let isPhoneNumber = false;
      let isOptOutNumber = false;

      if (ph !== '') {
        // eslint-disable-next-line no-restricted-syntax
        if (isValidPhoneNumber('+' + ph)) {
          isPhoneNumber = true;
        } else if (
          ph.substring(0, 2) === '91' &&
          isValidPhoneNumber('+' + ph.substring(2))
        ) {
          // special case backward compatibilty for Indian clients
          isPhoneNumber = true;
        } else if (ph.length === 10 && isValidPhoneNumber('+91' + ph)) {
          // special case backward compatibilty for Indian clients
          isPhoneNumber = true;
        }
        if (allOptOutSet?.size > 0) {
          // Check if each ph exists in allOptOutSet
          if (allOptOutSet.has(ph) || allOptOutSet.has(ph.slice(2)))
            isOptOutNumber = true;
        }
      }
      return isPhoneNumber && !isOptOutNumber;
    },
    encodeUTF8ToUTF16(input) {
      var escapeChar = s => {
        function q(c) {
          c = c.charCodeAt();
          return '%' + (c < 16 ? '0' : '') + c.toString(16).toUpperCase();
        }
        // eslint-disable-next-line no-control-regex
        return s.replace(/[\x00-),:-?[-^`{-\xFF]/g, q);
      };
      try {
        return decodeURIComponent(escapeChar(input));
      } catch (URIError) {
        // include invalid character, cannot convert
        return input;
      }
    },
    cleanDuplicates(payload) {
      let seen = new Set();
      payload = payload.filter(item => {
        let k = JSON.stringify(item);
        return seen.has(k) ? false : seen.add(k);
      });

      return payload;
    },
    async validateResourceURLs(urls) {
      urls = [...new Set(urls)];
      let result = true;

      function validateURL(url) {
        return new Promise((resolve, reject) => {
          fetch(url + '?not-from-cache-please', {
            method: 'HEAD',
            signal: controller.signal,
          })
            .then(res => (res.ok ? resolve() : reject()))
            .catch(() => reject());
        });
      }

      let controller = new AbortController();
      urls = urls.map(validateURL);

      await Promise.all(urls).catch(() => {
        result = false;
      });
      controller.abort();
      return result;
    },
  },
};
