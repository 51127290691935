import { INBOX_TYPES, isAWhatsAppChannel } from 'shared/mixins/inboxMixin';

// Define a base list of tabs visible to all channels
const baseTabs = [
  { key: 'inbox_settings', label: 'SETTINGS' },
  {
    key: 'businesshours',
    label: 'BUSINESS_HOURS',
    component: 'WeeklyAvailability',
  },
  { key: 'csat', label: 'CSAT_FORM', component: 'CsatForm' },
  { key: 'collaborators', label: 'COLLABORATORS', component: 'Collaborators' },
];

// Define specific tabs for each inbox type
const specificTabs = {
  [INBOX_TYPES.WEB]: [
    { key: 'preChatForm', label: 'PRE_CHAT_FORM', component: 'PreChatForm' },
    {
      key: 'limeChatWidget',
      label: 'LIMECHAT_WIDGET',
      component: 'LimeChatWidget',
    },
  ],
  [INBOX_TYPES.EMAIL]: [
    {
      key: 'configuration',
      label: 'CONFIGURATION',
      component: 'Configuration',
    },
  ],
  [INBOX_TYPES.THREE_SIXTY_DIALOG]: [
    {
      key: 'whatsapp_widget',
      label: 'WHATSAPP_WIDGET',
      component: 'WhatsAppWidget',
    },
  ],
  WHATSAPP_CHANNEL: [
    // This key is used for any WhatsApp channel type
    {
      key: 'configuration',
      label: 'CONFIGURATION',
      component: 'Configuration',
    },
    {
      key: 'whatsapp_widget',
      label: 'WHATSAPP_WIDGET',
      component: 'WhatsAppWidget',
    },
  ],
};

export const getTabs = inboxType => {
  let tabs = [...baseTabs]; // Start with base tabs

  // Add specific tabs for KNOWLARITY, directly returning to avoid adding further tabs
  if (inboxType === INBOX_TYPES.KNOWLARITY) {
    return [tabs[0], tabs[3]]; // Only SETTINGS and COLLABORATORS
  }

  // Handle specific tabs for other inbox types
  if (specificTabs[inboxType]) {
    tabs = tabs.concat(specificTabs[inboxType]);
  } else if (isAWhatsAppChannel(inboxType)) {
    tabs = tabs.concat(specificTabs.WHATSAPP_CHANNEL);
  }

  return tabs;
};
