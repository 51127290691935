<template>
  <div class="contact-info--row">
    <a v-if="href" :href="href" class="contact-info--details">
      <div class="flex-row">
        <span class="contact-info--icon">
          <icons :name="icon" :color="iconColor" :size="iconSize" />
        </span>
        <span v-if="value" class="subtitle-s2 text-dark" :title="value">{{
          value
        }}</span>
        <span v-else class="subtitle-s2 text-light">{{
          $t('CONTACT_PANEL.NOT_AVAILABLE')
        }}</span>
      </div>
      <button
        v-if="showCopy"
        type="submit"
        class="button nice link hollow grey-btn compact"
        @click="onCopy"
      >
        <i class="icon copy-icon ion-clipboard"></i>
      </button>
    </a>

    <div v-else class="contact-info--details">
      <span v-if="icon" class="contact-info--icon">
        <icons :name="icon" :color="iconColor" :size="iconSize" />
      </span>
      <span v-if="value" class="text-truncate">{{ value }}</span>
      <span v-else class="text-muted">{{
        $t('CONTACT_PANEL.NOT_AVAILABLE')
      }}</span>
    </div>
  </div>
</template>
<script>
import copy from 'copy-text-to-clipboard';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  mixins: [alertMixin],
  props: {
    href: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'normal',
    },
    iconColor: {
      type: String,
      default: 'darkestgrey',
    },
    value: {
      type: String,
      default: '',
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCopy(e) {
      e.preventDefault();
      copy(this.value);
      this.showAlert(this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'), 'success');
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.contact-info--row {
  padding-left: $space-slab;

  a {
    color: $neutral-grey-800;
  }

  .contact-info--details {
    display: flex;
    align-items: center;
    margin-bottom: $space-smaller;

    .copy-icon {
      margin-left: $space-one;
      &:hover {
        color: $color-woot;
      }
    }

    &.a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.contact-info--icon {
  margin-right: $space-normal;
}
</style>
