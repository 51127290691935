import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

const COMMON_VALUES = { name: 'whatsApp', color: 'whatsapp', hex: '#25d366' };

export default {
  methods: {
    getInboxClassByType(
      type,
      phoneNumber = '',
      identifier = '',
      insta_add_on = false
    ) {
      switch (type) {
        case INBOX_TYPES.WEB:
          return {
            name: 'browser',
            color: 'primary',
            hex: '#1b83ac',
            type: 'website',
          };

        case INBOX_TYPES.FB: {
          if (insta_add_on) {
            return {
              name: 'instagram',
              color: 'instagram',
              hex: '#e1306c',
              type: 'instagram',
            };
          }

          return identifier === 'comment'
            ? {
                name: 'facebook',
                color: 'facebook',
                hex: '#4267b2',
                type: 'facebook',
              }
            : {
                name: 'messenger',
                color: 'messenger',
                hex: '#00a7ff',
                type: 'facebook',
              };
        }

        case INBOX_TYPES.TWITTER:
          return { name: 'twitter', color: 'twitter', hex: '#1da1f2' };

        case INBOX_TYPES.TWILIO:
          return phoneNumber.startsWith('whatsapp')
            ? {
                ...COMMON_VALUES,
                brand_color: '#F22F46',
                type: 'whatsapp',
              }
            : {
                name: 'chat',
                color: 'danger',
                hex: '#ff7e61',
                type: 'whatsapp',
              };

        case INBOX_TYPES.API:
          return { name: 'chat', color: 'grey', hex: '#8c8c8c', type: 'api' };

        case INBOX_TYPES.EMAIL:
          return {
            name: 'email',
            color: 'warning',
            hex: '#ff9800',
            type: 'email',
          };

        case INBOX_TYPES.KNOWLARITY:
          return {
            name: 'phone',
            color: 'warning',
            hex: '#ff9800',
            brand_color: '#2C3A83',
            type: 'voice_channels',
          };

        case INBOX_TYPES.TWO_FACTOR:
          return { name: 'chat', color: 'danger', hex: '#ff7e61' };

        case INBOX_TYPES.CHAT_API:
          return {
            ...COMMON_VALUES,
            brand_color: '#2FBCAD',
            type: 'whatsapp',
          };
        case INBOX_TYPES.MAYTAPI:
          return {
            ...COMMON_VALUES,
            brand_color: '#029689',
            type: 'whatsapp',
          };
        case INBOX_TYPES.GUPSHUP:
          return {
            ...COMMON_VALUES,
            brand_color: '#6727FB',
            type: 'whatsapp',
          };
        case INBOX_TYPES.ZOKO:
          return {
            ...COMMON_VALUES,
            brand_color: '#000000',
            type: 'whatsapp',
          };
        case INBOX_TYPES.ACL:
          return {
            ...COMMON_VALUES,
            brand_color: '#386DB5',
            type: 'whatsapp',
          };
        case INBOX_TYPES.FRESHCHAT:
          return {
            ...COMMON_VALUES,
            brand_color: '#4CA6E8',
            type: 'whatsapp',
          };
        case INBOX_TYPES.WATI:
          return {
            ...COMMON_VALUES,
            brand_color: '#24B1AF',
            type: 'whatsapp',
          };
        case INBOX_TYPES.VALUE_FIRST:
          return {
            ...COMMON_VALUES,
            brand_color: '#EB6323',
            type: 'whatsapp',
          };
        case INBOX_TYPES.GUPSHUP_ENTERPRISE:
          return {
            ...COMMON_VALUES,
            brand_color: '#6727FB',
            type: 'whatsapp',
          };
        case INBOX_TYPES.CLOUD_API:
          return {
            ...COMMON_VALUES,
            brand_color: '#25d366',
            type: 'whatsapp',
          };
        case INBOX_TYPES.THREE_SIXTY_DIALOG:
          return {
            ...COMMON_VALUES,
            brand_color: '#FF4269',
            type: 'whatsapp',
          };
        case INBOX_TYPES.ICS:
          return {
            ...COMMON_VALUES,
            brand_color: '#fe0100',
            type: 'whatsapp',
          };

        default:
          return {
            name: 'browser',
            color: 'grey',
            hex: '#8c8c8c',
            type: 'website',
          };
      }
    },
  },
};
