import { frontendURL } from 'dashboard/helper/URLHelper';
import teams from './teams/teams.routes';
import store from 'dashboard/store';
import inbox from './inbox/inbox.routes';
import agent from './agents/agent.routes';
import canned from './canned/canned.routes';
import labels from './labels/labels.routes';
import botCsat from './botCsat/botCsat.routes';
import ticketAssignment from './ticketAssignment/ticketAssignment.routes';
import account from './account/account.routes';
import template from './template/template.routes';
import billing_plan from './billing_plan/billing_plan.routes';
import integrations from './integrations/integrations.routes';
import products from './products/products.routes';
import automation from './automation/automation.routes';
import customTicketFields from './customTicketFields/customTicketFields.routes';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings'),
      name: 'settings_home',
      roles: ['administrator', 'agent', 'supervisor'],
      redirect: () => {
        if (store.getters.getCurrentRole === 'administrator') {
          return frontendURL('accounts/:accountId/settings/inboxes');
        }
        return frontendURL('accounts/:accountId/settings/canned-response');
      },
    },
    ...botCsat.routes,
    ...account.routes,
    ...agent.routes,
    ...billing_plan.routes,
    ...canned.routes,
    ...inbox.routes,
    ...integrations.routes,
    ...labels.routes,
    ...template.routes,
    ...products.routes,
    ...teams.routes,
    ...automation.routes,
    ...ticketAssignment.routes,
    ...customTicketFields.routes,
  ],
};
