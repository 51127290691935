/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
// import Vue from 'vue';
import Vue from 'vue';
import store from 'dashboard/store';
import * as types from '../../mutation-types';
import actions from './actions';

const state = {
  selectedAddress: {
    shipping: null,
    billing: null,
  },
  orderMode: '',
  customerAddresses: [],
  customerDetails: {
    id: null,
  },
  orders: [],
  draftOrder: {},
  shopifyOrdersMeta: {
    next: '',
    previous: '',
    count: 0,
    contactId: 0,
  },
  shopifyOrders: [],
  products: [],
  productMeta: {
    next: '',
    prev: '',
    count: 0,
  },
  productsInCart: [],
  cartMeta: {
    cartTotal: '',
    cartId: null,
    isActive: false,
    createdAt: '',
    lastUpdatedAt: '',
    customer: null,
    orderId: null,
  },
  cartMetaInEditMode: {
    cartTotal: '',
    cartId: null,
    isActive: false,
    createdAt: '',
    lastUpdatedAt: '',
    customer: null,
    orderId: null,
  },
  productsInCartInEditMode: [],
  ordersMeta: {
    hasNext: false,
    cursor: null,
    contactId: 0,
  },
  attendanceMetrics: [],
  attendanceLogs: {},
  uiFlags: {
    fetchingList: false,
    creatingDraftOrder: false,
    creatingCodOrder: false,
    creatingItem: false,
    deletingItem: false,
    fetchingProducts: false,
    updatingProduct: false,
    fetchingAgentMetrics: false,
    creatingAddress: false,

    fetchingPerformanceMetricCards: false,
    fetchingPeriodicPerformance: false,
    fetchingMeanPerformance: false,
    fetchingHourlyPerformance: false,
    fetchingMessageBlocks: false,
    fetchingConversationState: false,

    fetchingConversationMetricsCards: false,
    fetchingConversationStates: false,
    fetchingConversationHandoffTrend: false,

    fetchingSalesMetrics: false,
    fetchingSalesTable: false,
    fetchingBotColumnChartMetrics: false,
    fetchingBotLineChartMetrics: false,
    fetchingBotMetricsCards: false,

    fetchingUserInboxWebsiteDivision: false,
    fetchingUserConcernsComplains: false,
    fetchingUserHandoff: false,
    fetchingUserActivity: false,
    fetchingUserBotFailingTable: false,
    fetchingUserDownloadTable: false,
    fetchingOutboundMsgSentRR: false,
    fetchingOutboundReplyRate: false,
    fetchingOutboundRevenue: false,
    fetchingOutboundNps: false,
    fetchingOutboundConvRate: false,
  },
  broadcastList: [],
  broadcastProgress: [],
  isShopifyClient: false,
  draftOrderMeta: {
    id: null,
    note: null,
    taxExempt: false,
    total_price: '0.00',
    total_tax: '0.00',
    subtotal_price: '0.00',
    paymentLink: '',
    isCod: false,
    appliedDiscount: '0',
    appliedShippingCharge: '0',
    cart: {},
  },
  activeShopifyOrder: {
    id: null,
  },
  accountShippingRate: {},
};

export const getters = {
  getOrders(_state) {
    return _state.orders;
  },
  getCurrentOrderMode(_state) {
    return _state.orderMode;
  },
  getAccountShippingRate(_state) {
    return _state.accountShippingRate;
  },
  getActiveShopifyOrder(_state) {
    return _state.activeShopifyOrder;
  },
  getCustomerAddresses(_state) {
    return _state.customerAddresses;
  },
  getSelectedAddress(_state) {
    return _state.selectedAddress;
  },
  getCustomerDetails(_state) {
    return _state.customerDetails;
  },
  getOrdersUIFlags(_state) {
    return _state.uiFlags;
  },
  getCustomerAddressUiFlags(_state) {
    return _state.uiFlags;
  },
  getShopifyOrders(_state) {
    return _state.shopifyOrders;
  },
  getDraftOrder(_state) {
    return _state.draftOrder;
  },
  getDraftOrderUIFlag(_state) {
    return _state.uiFlags.creatingDraftOrder;
  },
  getCodOrderUIFlag(_state) {
    return _state.uiFlags.creatingCodOrder;
  },
  getProducts(_state) {
    return _state.products;
  },
  getProductsMeta(_state) {
    return _state.productMeta;
  },
  getOrdersMeta(_state) {
    return _state.ordersMeta;
  },
  getProductsInCart(_state) {
    return _state.productsInCart;
  },
  getCartMeta(_state) {
    return _state.cartMeta;
  },
  getProductsInCartInEditMode(_state) {
    return _state.productsInCartInEditMode;
  },
  getCartMetaInEditMode(_state) {
    return _state.cartMetaInEditMode;
  },
  getShopifyOrdersMeta(_state) {
    return _state.shopifyOrdersMeta;
  },
  getDraftOrderMeta(_state) {
    return _state.draftOrderMeta;
  },
  getProductsUIFlags(_state) {
    return _state.uiFlags;
  },
  getAttendanceUIFlag(_state) {
    return _state.uiFlags.fetchingAgentMetrics;
  },
  getAttendanceMetrics(_state) {
    return _state.attendanceMetrics;
  },
  getAttendanceLogs(_state) {
    return _state.attendanceLogs;
  },
  getAnalyticsUIFlag(_state) {
    return (
      _state.uiFlags.fetchingPerformancMetrics ||
      _state.uiFlags.fetchingConversationMetrics
    );
  },
  getPerformanceMetricUIFlag(_state) {
    return _state.uiFlags.fetchingPerformanceMetricCards;
  },
  getPeriodicPerformanceUIFlag(_state) {
    return _state.uiFlags.fetchingPeriodicPerformance;
  },
  getMeanPerformanceUIFlag(_state) {
    return _state.uiFlags.fetchingMeanPerformance;
  },
  getHourlyPerformanceUIFlag(_state) {
    return _state.uiFlags.fetchingHourlyPerformance;
  },
  getMsgBlockUIFlag(_state) {
    return _state.uiFlags.fetchingMessageBlocks;
  },
  getAgentConversationStateUIFlag(_state) {
    return _state.uiFlags.fetchingConversationState;
  },

  getConversationMetricUIFlag(_state) {
    return _state.uiFlags.fetchingConversationMetricsCards;
  },
  getConversationStateUIFlag(_state) {
    return _state.uiFlags.fetchingConversationStates;
  },

  getConversationHandoffTrendUIFlag(_state) {
    return _state.uiFlags.fetchingConversationHandoffTrend;
  },

  getBotUIFlag(_state) {
    return _state.uiFlags.fetchingBotColumnChartMetrics;
  },
  getBotCardUIFlag(_state) {
    return _state.uiFlags.fetchingBotMetricsCards;
  },
  getBotLineChartUIFlag(_state) {
    return _state.uiFlags.fetchingBotLineChartMetrics;
  },
  getSalesUIFlag(_state) {
    return _state.uiFlags.fetchingSalesMetrics;
  },
  getSalesTableUIFlag(_state) {
    return _state.uiFlags.fetchingSalesTable;
  },

  getUserInboxWebsiteUIFlag(_state) {
    return _state.uiFlags.fetchingUserInboxWebsiteDivision;
  },

  getUserConcernComplainUIFlag(_state) {
    return _state.uiFlags.fetchingUserConcernsComplains;
  },

  getUserHandoffUIFlag(_state) {
    return _state.uiFlags.fetchingUserHandoff;
  },

  getUserActivityUIFlag(_state) {
    return _state.uiFlags.fetchingUserActivity;
  },
  getUserBotFailingTableUIFlag(_state) {
    return _state.uiFlags.fetchingUserBotFailingTable;
  },
  getUsersDownloadTableUIFlag(_state) {
    return _state.uiFlags.fetchingUserDownloadTable;
  },
  getIsShopifyClient($state) {
    return $state.isShopifyClient;
  },
  getOutboundMsgUIFlag(_state) {
    return _state.uiFlags.fetchingOutboundMsgSentRR;
  },
  getOutboundReplyRateUIFlag(_state) {
    return _state.uiFlags.fetchingOutboundReplyRate;
  },
  getOutboundRevenueUIFlag(_state) {
    return _state.uiFlags.fetchingOutboundRevenue;
  },
  getOutboundNpsUIFlag(_state) {
    return _state.uiFlags.fetchingOutboundNps;
  },
  getOutboundConvRateUIFlag(_state) {
    return _state.uiFlags.fetchingOutboundConvRate;
  },
  getBroadcastList(_state) {
    return _state.broadcastList;
  },
  getBroadcastProgress(_state) {
    return _state.broadcastProgress;
  },
  getDeepShopifyBetaEnabledFlag() {
    const accountId = store.getters.getCurrentAccountId;
    const { features: { shopify_beta_features: isEnabled } = {} } =
      store.getters['accounts/getAccount'](accountId) ?? {};

    return isEnabled ?? false;
  },
};

export const mutations = {
  [types.default.SET_ORDERS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_ORDERS](_state, data) {
    let {
      items,
      meta: { has_next: hasNext, ...rest },
      contactId,
      append,
    } = data;
    let orderIds = _state.orders.map(item => item.order_id);
    if (contactId === _state.ordersMeta.contactId && append) {
      let mergedOrders = [
        ..._state.orders,
        ...items.filter(item => !orderIds.includes(item.order_id)),
      ];
      _state.orders = mergedOrders;
    } else _state.orders = items;

    _state.ordersMeta = { ...rest, hasNext, contactId };
  },
  [types.default.RESET_ORDERS](_state) {
    _state.orders = [];
  },
  [types.default.SET_ACCOUNT_SHIPPING_RATES](_state, data) {
    _state.accountShippingRate = data;
  },
  [types.default.SET_SHOPIFY_ORDERS](_state, data) {
    let { results, next, previous, count, contactId, shouldLoadMore } = data;

    if (results.length === 0) {
      _state.shopifyOrders = [];
      return;
    }

    let orderIds = _state.shopifyOrders.map(item => item.order_id);

    if (shouldLoadMore) {
      const mergedOrders = [
        ..._state.shopifyOrders,
        ...results.filter(item => !orderIds.includes(item.order_id)),
      ];
      _state.shopifyOrders = mergedOrders;
    } else if (contactId === _state.shopifyOrdersMeta.contactId) {
      _state.shopifyOrders = results;
    } else {
      _state.shopifyOrders = results.filter(
        item => !orderIds.includes(item.order_id)
      );
    }
    _state.shopifyOrdersMeta = { next, previous, count, contactId };
  },
  [types.default.SET_ACTIVE_SHOPIFY_ORDER](_state, data) {
    _state.activeShopifyOrder = data;
  },
  [types.default.EMPTY_ORDERS](_state) {
    _state.shopifyOrders = [];
    _state.shopifyOrdersMeta = {
      next: '',
      previous: '',
      count: 0,
      contactId: 0,
    };
  },
  [types.default.SET_DRAFT_ORDER](_state, data) {
    if (data) {
      const {
        id,
        note,
        tax_exempt,
        total_price,
        total_tax,
        subtotal_price,
        payment_link: paymentLink,
        is_cod: isCod,
        applied_discount: appliedDiscount,
        shipping_charges: appliedShippingCharge,
        cart,
      } = data;

      _state.draftOrderMeta = {
        id,
        note,
        tax_exempt,
        total_price,
        total_tax,
        subtotal_price,
        paymentLink,
        isCod,
        appliedDiscount,
        appliedShippingCharge,
        cart,
      };
    }
  },
  [types.default.RESET_DRAFT_ORDER](_state) {
    _state.draftOrderMeta = {
      id: null,
      note: null,
      taxExempt: false,
      total_price: '0.00',
      total_tax: '0.00',
      subtotal_price: '0.00',
      paymentLink: '',
      isCod: false,
      appliedDiscount: '0',
      appliedShippingCharge: '0',
      cart: {},
    };
  },
  [types.default.SET_DRAFT_ORDER_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_COD_ORDER_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_PRODUCTS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_PRODUCTS](_state, data) {
    let { results, next, prev, count, append } = data;
    if (append) {
      _state.products = [..._state.products, ...results];
    } else {
      _state.products = results;
    }
    _state.productMeta = { next, prev, count };
  },
  [types.default.SET_CART](_state, data) {
    const { line_items: productsInCart, ...cartMeta } = data;

    _state.cartMeta = { ...cartMeta };
    _state.productsInCart = productsInCart;
  },
  [types.default.SET_EDIT_MODE_CART](_state, data) {
    const { line_items: productsInCart, ...cartMeta } = data;

    _state.cartMetaInEditMode = { ...cartMeta };
    _state.productsInCartInEditMode = productsInCart;
  },
  [types.default.EMPTY_CART](_state) {
    _state.cartMeta = {};
    _state.productsInCart = [];
  },
  [types.default.ADD_PRODUCT_IN_CART](_state, data) {
    if (_state.orderMode === 'edit') {
      const updatedProductsInCart = _state.productsInCartInEditMode.concat(
        data
      );
      _state.productsInCartInEditMode = updatedProductsInCart;

      return;
    }

    const updatedProductsInCart = _state.productsInCart.concat(data);
    _state.productsInCart = updatedProductsInCart;
  },
  [types.default.REMOVE_PRODUCT_FROM_CART](_state, itemId) {
    if (_state.orderMode === 'edit') {
      const updatedProductsInCart = _state.productsInCartInEditMode.filter(
        product => product.id !== itemId
      );
      _state.productsInCartInEditMode = updatedProductsInCart;

      return;
    }
    const updatedProductsInCart = _state.productsInCart.filter(
      product => product.id !== itemId
    );
    _state.productsInCart = updatedProductsInCart;
  },
  [types.default.UPDATE_PRODUCT_IN_CART](_state, data) {
    if (_state.orderMode === 'edit') {
      const updatedProductsInCart = _state.productsInCartInEditMode.map(
        product => (data.id === product.id ? data : product)
      );
      _state.productsInCartInEditMode = updatedProductsInCart;

      return;
    }
    const updatedProductsInCart = _state.productsInCart.map(product =>
      data.id === product.id ? data : product
    );
    _state.productsInCart = updatedProductsInCart;
  },
  [types.default.SET_CUSTOMER_DETAILS](_state, data) {
    const { addresses: customerAddresses, ...customerDetails } = data;

    _state.customerAddresses = [...customerAddresses];

    _state.customerDetails = { ...customerDetails };
  },
  [types.default.EMPTY_CUSTOMER_DETAILS](_state) {
    _state.customerAddresses = [];
    _state.customerDetails = {};
    _state.selectedAddress.shipping = null;
  },
  [types.default.ADD_ADDRESS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.ADD_NEW_CUSTOMER_ADDRESS](_state, data) {
    const updatedCustomerAddresses = _state.customerAddresses.concat(data);
    _state.customerAddresses = updatedCustomerAddresses;
  },
  [types.default.UPDATE_CUSTOMER_ADDRESS](_state, data) {
    const updatedCustomerAddresses = _state.customerAddresses.map(
      customerAddresses =>
        data.id === customerAddresses.id ? data : customerAddresses
    );
    _state.customerAddresses = updatedCustomerAddresses;
  },
  [types.default.DELETE_CUSTOMER_ADDRESS](_state, addressId) {
    const updatedCustomerAddresses = _state.customerAddresses.filter(
      customerAddresses => addressId !== customerAddresses.id
    );
    _state.customerAddresses = updatedCustomerAddresses;
    _state.selectedAddress = null;
  },
  [types.default.SET_SELETED_ADDRESS](_state, data) {
    _state.selectedAddress = {
      ..._state.selectedAddress,
      [data.addressType]: data.addressId,
    };
  },
  [types.default.SET_ORDER_MODE](_state, mode) {
    _state.orderMode = mode;
  },
  [types.default.UPDATE_PRODUCTS](_state, data) {
    _state.products.forEach((element, index) => {
      if (element.id === data.id) {
        let newData = { ..._state.products[index] };
        newData.attributes = data.attributes;
        Vue.set(_state.products, index, newData);
      }
    });
  },
  [types.default.SET_ATTENDANCE_METRICS](_state, data) {
    // eslint-disable-next-line valid-typeof
    if (typeof data === 'object') {
      _state.attendanceMetrics = data;
    } else {
      _state.attendanceMetrics = [];
    }
  },
  [types.default.SET_ATTENDANCE_LOGS](_state, data) {
    _state.attendanceLogs = data;
  },
  [types.default.SET_ANALYTICS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_AGENT_METRICS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_AGENT_PERIODIC_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_AGENT_MEAN_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_AGENT_HOURLY_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_AGENT_MSG_BLOCK_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_AGENT_CONV_STAT_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_CONVERSATION_METRICS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_CONVERSATION_STATE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_CONVERSATION_HANDOFF_TREND_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_BOT_ANALYTICS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_BOT_CARDS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_BOT_LINE_CHART_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_SALES_ANALYTICS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_SALES_TABLE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_USERS_INBOX_WEBSITE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_USERS_CONCERNS_COMPLAIN_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_USERS_HANDOFF_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_USERS_ACTIVITY_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_BOT_FAILING_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_USERS_DOWNLOAD_TABLE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_ACCOUNT_SHOPIFY_STATUS]($state) {
    $state.isShopifyClient = true;
  },
  [types.default.SET_BROADCAST_LIST]($state, data) {
    const { results } = data;

    $state.broadcastList = [...results];
  },
  [types.default.SET_BROADCAST_PROGRESS]($state, data) {
    const { broadCastId, progress } = data;
    $state.broadcastProgress[broadCastId] = progress;
  },
  [types.default.CANCEL_BROADCAST]($state, data) {
    const { id: broadcastId } = data;

    const idx = $state.broadcastList.findIndex(
      broadcast => broadcast.id === broadcastId
    );

    Vue.set($state.broadcastList, idx, {
      ...$state.broadcastList[idx],
      ...data,
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
