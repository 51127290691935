<template>
  <div class="column content-box settings-wrapper">
    <div class="settings-container column lime-card-1dp">
      <three-sixty-onboarding-modal
        v-if="inbox.id && isThreeSixtyChannelInProgress"
        :inbox="inbox"
        :screen="currentScreen"
        @updateCurrentScreen="value => (currentScreen = value)"
        @closeModal="onCloseModal"
      />
      <template v-else-if="inbox.id">
        <woot-tabs :index="selectedTabIndex" @change="onTabChange">
          <woot-tabs-item
            v-for="tab in tabs"
            :key="tab.key"
            :name="$t(`INBOX_MGMT.TABS.${tab.label}`)"
          />
        </woot-tabs>
        <component :is="renderComponent" :inbox="inbox" />
      </template>
      <loading-state v-else />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import LoadingState from 'dashboard/components/ui/LoadingState';
import ThreeSixtyOnboardingModal from 'dashboard/components/ui/threeSixtyOnboardingModal/Index.vue';

import threeSixtyDialogMixin from 'dashboard/mixins/threeSixtyDialog';
import onboardingMixin from 'dashboard/mixins/onboarding';
import inboxMixin from 'shared/mixins/inboxMixin';
import { INBOX_NAMES } from 'shared/mixins/inboxMixin';

import { frontendURL } from 'dashboard/helper/URLHelper.js';
import { getTabs } from './helper';

const loader = {
  loading: LoadingState,
  delay: 100,
};

export default {
  components: {
    LoadingState,
    ThreeSixtyOnboardingModal,
    Website: () => ({
      component: import('./Website/Index.vue'),
      ...loader,
    }),
    LimeChatWidget: () => ({
      component: import('./Website/LimeChatWidget'),
      ...loader,
    }),
    PreChatForm: () => ({
      component: import('./Website/PreChatForm'),
      ...loader,
    }),
    WeeklyAvailability: () => ({
      component: import('./shared/WeeklyAvailability'),
      ...loader,
    }),
    CsatForm: () => ({
      component: import('./shared/csat/CsatForm'),
      ...loader,
    }),
    Configuration: () => ({
      component: import('./shared/Configuration'),
      ...loader,
    }),
    Collaborators: () => ({
      component: import('../Collaborators'),
      ...loader,
    }),
    WhatsAppCloudApi: () => ({
      component: import('./WhatsappCloudApi/Index.vue'),
      ...loader,
    }),
    Gupshup: () => ({
      component: import('./Gupshup/Index.vue'),
      ...loader,
    }),
    GupshupEnterprise: () => ({
      component: import('./GupshupEnterprise/Index.vue'),
      ...loader,
    }),
    Maytapi: () => ({
      component: import('./Maytapi/Index.vue'),
      ...loader,
    }),
    Wati: () => ({
      component: import('./Wati/Index.vue'),
      ...loader,
    }),
    Acl: () => ({
      component: import('./Acl/Index.vue'),
      ...loader,
    }),
    Zoko: () => ({
      component: import('./Zoko/Index.vue'),
      ...loader,
    }),
    ChatAPI: () => ({
      component: import('./ChatAPI/Index.vue'),
      ...loader,
    }),
    ValueFirst: () => ({
      component: import('./ValueFirst/Index.vue'),
      ...loader,
    }),
    Freshchat: () => ({
      component: import('./Freshchat/Index.vue'),
      ...loader,
    }),
    TwilioSMS: () => ({
      component: import('./TwilioSMS/Index.vue'),
      ...loader,
    }),
    Email: () => ({
      component: import('./Email/Index.vue'),
      ...loader,
    }),
    Knowlarity: () => ({
      component: import('./Knowlarity/Index.vue'),
      ...loader,
    }),
    Api: () => ({
      component: import('./Api/Index.vue'),
      ...loader,
    }),
    TwoFactor: () => ({
      component: import('./TwoFactor/Index.vue'),
      ...loader,
    }),
    Facebook: () => ({
      component: import('./Facebook/Index.vue'),
      ...loader,
    }),
    Ics: () => ({
      component: import('./Ics/Index.vue'),
      ...loader,
    }),
    ThreeSixtyDialog: () => ({
      component: import('./ThreeSixtyDialog/Index.vue'),
      ...loader,
    }),
    WhatsAppWidget: () => ({
      component: import('../components/whatsappWidget/WhatsappWidget'),
      ...loader,
    }),
  },
  mixins: [threeSixtyDialogMixin, onboardingMixin, inboxMixin],
  data() {
    return {
      selectedTabIndex: 0,
      currentScreen: '',
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    tabParameter() {
      const selectedTab = Number(this.$route.query?.tab);
      return !Number.isNaN(selectedTab) && selectedTab >= 0 ? selectedTab : 0;
    },
    selectedTab() {
      return this.tabs[this.selectedTabIndex].key;
    },
    selectedTabCmpt() {
      return this.tabs[this.selectedTabIndex]?.component || '';
    },
    renderComponent() {
      if (this.selectedTabCmpt) return this.selectedTabCmpt;

      let componentName =
        INBOX_NAMES[this.inbox?.channel_type]?.component_name ||
        INBOX_NAMES[this.inbox?.channel_type]?.name ||
        '';
      componentName = componentName.split(' ').join('');
      return componentName;
    },
    tabs() {
      return getTabs(this.inbox?.channel_type);
    },
    isThreeSixtyChannelInProgress() {
      return (
        this.isThreeSixtyDialogInbox &&
        (!this.inbox.three_sixty_channel_id ||
          !this.inbox.three_sixty_client_id)
      );
    },
  },
  created() {
    if (this.inbox?.id) this.initOnboarding();
    else
      this.$watch('inbox', (newValue, oldValue) => {
        if (newValue.id !== oldValue.id) this.initOnboarding();
      });
  },
  methods: {
    initOnboarding() {
      if (this.isThreeSixtyChannelInProgress) this.onSetOnboardingScreen();
      else this.onTabChange(this.tabParameter);
    },
    onSetOnboardingScreen() {
      const params = this.getParamsFromUrl();
      const client = this.getClientId(params);
      const channels = this.getChannelIds(params);
      this.sendParamsToParentWindow(params);
      this.currentScreen = this.setOnboardingScreen(
        this.inbox,
        client,
        channels
      );
    },
    onTabChange(selectedTabIndex) {
      window.history.replaceState(
        {},
        null,
        `${this.$route.path}?tab=${selectedTabIndex}`
      );
      this.selectedTabIndex = selectedTabIndex;
    },
    onCloseModal() {
      const path = `accounts/${this.accountId}/settings/inboxes`;

      this.$router.push({ path: frontendURL(path) });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-wrapper {
  position: relative;

  .lime-card-1dp {
    padding: $zero;
  }
}

.settings-container {
  height: 100%;
  margin: $zero;
  overflow: hidden;
}
</style>
