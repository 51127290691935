<template>
  <!-- Text Input -->
  <woot-input
    v-if="isTextInput"
    :value="value"
    :label="formatLabel(label)"
    :required="isRequired"
    placeholder="Enter Value"
    :help-text="helpText"
    :class="{ error: validator.$error }"
    :error="error"
    :has-error="validator.$error"
    @input="updateValue"
    @blur="validator.$touch"
  />

  <!-- Large Text Input -->
  <woot-input
    v-else-if="isLargeTextInput"
    :value="value"
    :label="formatLabel(label)"
    :required="isRequired"
    placeholder="Enter Value"
    :help-text="helpText"
    rows="3"
    :class="{ error: validator.$error }"
    :error="error"
    :has-error="validator.$error"
    @input="updateValue"
    @blur="validator.$touch"
  />

  <!-- Numeric Input -->
  <woot-input
    v-else-if="isNumericInput"
    :value="value"
    type="number"
    :label="formatLabel(label)"
    placeholder="Enter Value"
    :help-text="helpText"
    :class="{ error: validator.$error }"
    :error="error"
    :has-error="validator.$error"
    @input="newValue => updateValue(Number(newValue))"
    @blur="validator.$touch"
  />

  <!-- Enum Selector -->
  <div v-else-if="isEnumInput">
    <label v-if="label" class="formlabel flex-row flex-align gap--smaller">
      <span v-if="isRequired" title="required" class="dot-circle" />
      <span class="title-h5 text-dark" v-text="formatLabel(label)" />
    </label>
    <woot-single-selector
      :default-option="value.toString()"
      :option-list="options"
      :class="{ error: validator.$error }"
      :custom-style="{
        left: 0,
        top: 0,
        width: '100%',
      }"
      @click="({ name: newVal, id }) => updateSelectorValue(newVal, id)"
    />
  </div>

  <!-- Enum - Multi-Selector -->
  <div v-else-if="isMultiEnumInput">
    <label v-if="label" class="formlabel flex-row flex-align gap--smaller">
      <span v-if="isRequired" title="required" class="dot-circle" />
      <span class="title-h5 text-dark" v-text="formatLabel(label)" />
    </label>
    <woot-multi-selector
      ref="multiSelector"
      default-option="Select Values"
      collapse-selected
      :option-list="options"
      :class="{ error: validator.$error }"
      :custom-style="{
        left: 0,
        top: 0,
        width: '100%',
      }"
      :toggle-filter="
        (_, selectedOptions, selectedOptionIds) =>
          updateSelectorValue(selectedOptions, selectedOptionIds)
      "
    />
  </div>

  <!-- Date Picker -->
  <div v-else-if="isDateInput">
    <label v-if="label" class="formlabel flex-row flex-align gap--smaller">
      <span v-if="isRequired" title="required" class="dot-circle" />
      <span class="title-h5 text-dark" v-text="formatLabel(label)" />
    </label>
    <date-picker
      variant="small"
      :class="{ error: validator.$error }"
      placeholder="yyyy-mm-dd"
      format="YYYY-MM-DD"
      :show-second="false"
      :show-range="false"
      :value="value"
      @change="updateValue"
    />
  </div>

  <!-- Date & Time Picker -->
  <div v-else-if="isDateTimeInput">
    <label v-if="label" class="formlabel flex-row flex-align gap--smaller">
      <span v-if="isRequired" title="required" class="dot-circle" />
      <span class="title-h5 text-dark" v-text="formatLabel(label)" />
    </label>
    <date-picker
      variant="small"
      type="datetime"
      :class="{ error: validator.$error }"
      placeholder="yyyy-mm-dd hh:mm"
      format="YYYY-MM-DD HH:mm"
      :show-second="false"
      :show-range="false"
      :value="value"
      @change="updateValue"
    />
  </div>

  <!-- Time Input -->
  <div v-else-if="isTimeInput">
    <label v-if="label" class="formlabel flex-row flex-align gap--smaller">
      <span v-if="isRequired" title="required" class="dot-circle" />
      <span class="title-h5 text-dark" v-text="formatLabel(label)" />
    </label>
    <date-picker
      type="time"
      :class="{ error: validator.$error }"
      placeholder="hh:mm"
      variant="small"
      format="HH:mm"
      :show-second="false"
      :show-range="false"
      :value="value"
      @change="updateValue"
    />
  </div>
</template>

<script>
import DatePicker from 'dashboard/components/ui/DatePicker';

import { DATA_TYPES } from './utils/constants';
import { formatLabel } from './utils/helper';

export default {
  components: { DatePicker },
  props: {
    dataType: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    validator: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Number, Array, Object, Boolean, Date],
      required: true,
    },
    choices: {
      type: Array,
      default: () => [],
    },
    helpText: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      required: true,
    },
  },
  computed: {
    options() {
      return this.choices.map(({ id, value }) => ({
        name: value,
        id: id.toString(),
      }));
    },
    isNumericInput() {
      return this.dataType === DATA_TYPES.NUM;
    },
    isEnumInput() {
      return this.dataType === DATA_TYPES.ENUM;
    },
    isMultiEnumInput() {
      return this.dataType === DATA_TYPES.MULTI_ENUM;
    },
    isTextInput() {
      return this.dataType === DATA_TYPES.TEXT;
    },
    isLargeTextInput() {
      return this.dataType === DATA_TYPES.LARGE_TEXT;
    },
    isDateInput() {
      return this.dataType === DATA_TYPES.DATE;
    },
    isDateTimeInput() {
      return this.dataType === DATA_TYPES.DATETIME;
    },
    isTimeInput() {
      return this.dataType === DATA_TYPES.TIME;
    },
    isRequired() {
      // Since a custom validator will set 'required' to true even if the field is not required
      // We need to check if the field is actually required through the required object in $params.
      return !!this.validator.$params.required;
    },
  },
  mounted() {
    if (this.isMultiEnumInput && this.value) {
      this.initialiseMultiselectorValues();
    }
  },
  methods: {
    updateSelectorValue(newValue, id) {
      this.updateValue({ id, value: newValue });
    },
    updateValue(newValue) {
      this.$emit('update-value', newValue);
    },
    initialiseMultiselectorValues() {
      // If the multiselector input field has pre-selected values, we need to set the selected values
      const optionsIds = this.value.map(({ id }) => id);
      const optionsNames = this.value.map(({ value }) => value);

      this.$refs.multiSelector.selectedOptionId = optionsIds;
      this.$refs.multiSelector.selectedOption = optionsNames;
    },
    formatLabel,
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.dot-circle {
  background-color: $warn-red-400;
  border-radius: 50%;
  display: inline-block;
  height: $space-smaller;
  width: $space-smaller;
}
</style>
