import ConversationAPI from '../../api/inbox/conversation';
import types from '../mutation-types';
export const initialState = {
  records: [],
  savedSearch: {
    query: '',
    filters: { searchBy: null, statusTimeRange: null, inbox: null },
    results: [],
  },
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getConversations(state) {
    return state.records;
  },
  getSavedSearch(state) {
    return state.savedSearch;
  },
  getUIFlags(state) {
    return state.uiFlags;
  },
};

export const actions = {
  async get(_, { query_type, q, page, inbox_id, status_time_range }) {
    const {
      data: { payload },
    } = await ConversationAPI.search({
      query_type,
      q,
      page,
      inbox_id,
      status_time_range,
    });

    return payload;
  },
  setSearchConversationsUIFlag({ commit }, uiFlags) {
    commit(types.SEARCH_CONVERSATIONS_SET_UI_FLAG, uiFlags);
  },
  setSearchConversations({ commit }, data) {
    commit(types.SEARCH_CONVERSATIONS_SET, data);
  },
  setSearchConversationsAppend({ commit }, data) {
    commit(types.SEARCH_CONVERSATIONS_APPEND, data);
  },
  saveSearchResults({ commit }, data) {
    commit(types.SAVE_SEARCH_CONVERSATIONS, data);
  },
  clearSavedResult({ commit }) {
    commit(types.CLEAR_SAVE_SEARCH_CONVERSATIONS);
  },
  resetSearchResults({ commit }) {
    commit(types.SEARCH_CONVERSATIONS_RESET);
  },
};

export const mutations = {
  [types.SEARCH_CONVERSATIONS_APPEND](state, results) {
    const newResults = [...state.records];
    results.forEach(result => {
      const indexInCurrentList = newResults.findIndex(r => r.id === result.id);
      if (indexInCurrentList < 0) newResults.push(result);
    });
    state.records = newResults;
  },
  [types.SAVE_SEARCH_CONVERSATIONS](state, data) {
    state.savedSearch = {
      ...state.savedSearch,
      filters: { ...state.savedSearch.filters, ...data.filters },
      ...data,
    };
  },
  [types.CLEAR_SAVE_SEARCH_CONVERSATIONS](state) {
    state.savedSearch = { query: '', type: 'message', results: [] };
  },
  [types.SEARCH_CONVERSATIONS_SET](state, results) {
    state.records = results;
  },
  [types.SEARCH_CONVERSATIONS_RESET](state) {
    state.records = [];
  },
  [types.SEARCH_CONVERSATIONS_SET_UI_FLAG](state, uiFlags) {
    state.uiFlags = { ...state.uiFlags, ...uiFlags };
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
