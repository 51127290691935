<template>
  <div class="column content-box settings-wrapper">
    <automation-form
      v-if="!uiFlags.isFetching && selectedAutomation"
      :selected-response="selectedAutomation"
      :mode="selectedMode"
      @saveAutomation="saveAutomation"
    />
    <loading-state v-else style="height: 100%" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import LoadingState from 'dashboard/components/ui/LoadingState';
import AutomationForm from '../components/form/AutomationForm';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { MODES } from '../utils/constants';

export default {
  components: { LoadingState, AutomationForm },
  mixins: [alertMixin, mixPanelMixin],
  data() {
    return { selectedAutomation: null, selectedMode: MODES.EDIT };
  },
  computed: {
    ...mapGetters({
      automationRules: 'automations/getAutomations',
      uiFlags: 'automations/getUIFlags',
    }),
    ruleId() {
      return +this.$router.history.current?.params?.rule_id;
    },
  },
  created() {
    this.$store.dispatch('automations/get').then(() => this.getSelectedRule());
  },
  methods: {
    getSelectedRule() {
      this.selectedAutomation =
        this.automationRules.find(template => template.id === this.ruleId) ||
        {};
    },
    async saveAutomation(payload) {
      try {
        this.mixPanelAddAutomationSuccessful();
        await this.$store.dispatch('automations/update', payload);
        this.showAlert(
          this.$t('AUTOMATION.EDIT.API.SUCCESS_MESSAGE'),
          'success'
        );
        this.mix_panel_clicked_settings_create_submit_automation(
          payload.event_name
        );
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.EDIT.API.ERROR_MESSAGE'), 'error');
      }
    },
  },
};
</script>
