<template>
  <div>
    <div class="contact--info flex-column flex-align--start">
      <div class="info--container flex-space-between flex-align--start">
        <div class="flex-row-justify-center">
          <woot-avatar
            :username="contact.name"
            color="#fff"
            background-color="#1B83AC"
          />
          <div class="row">
            <div class="info--name title-h2 medium-12 columns flex-row-center">
              <span class="text-truncate"> {{ contact.name }}</span>
            </div>
            <div
              v-if="inboxInfo.name"
              class="medium-12 columns edit-btn flex-row-center"
            >
              <icons
                :name="badgeType.name"
                :color="`${badgeType.color} evenodd`"
              />
              <span class="inbox-name body-b3 text-light">{{
                inboxInfo.name
              }}</span>
            </div>
          </div>
        </div>
        <button class="edit-btn flex-row-center" @click="toggleEditModal">
          <icons
            name="editRound"
            size="medium"
            view="0 0 24 24"
            color="green"
          />
        </button>
      </div>
      <div class="separator"></div>
      <div class="contact--details">
        <div
          v-for="(detail, index) in contactDetails"
          :key="index"
          class="flex-space-between detail-row"
        >
          <a
            v-if="detail.href"
            target="_blank"
            rel="noopener noreferrer nofollow"
            :href="detail.href"
          >
            <div class="flex-row-center">
              <span class="mg-right mg-left--smaller">
                <icons
                  :name="detail.icon"
                  :color="detail.iconColor"
                  size="semimedium"
                />
              </span>
              <span class="subtitle-s2 text-dark">
                {{ detail.value }}
              </span>
            </div>
          </a>
          <div v-else class="flex-row-center">
            <span class="mg-right mg-left--smaller">
              <icons
                :name="detail.icon"
                :color="detail.iconColor"
                size="semimedium"
              />
            </span>

            <span
              v-if="detail.value && detail.value !== ''"
              class="subtitle-s2 text-dark"
            >
              {{ detail.value }}
            </span>
            <span v-else class="subtitle-s2 text-light">
              {{ $t('CONTACT_PANEL.NOT_AVAILABLE') }}
            </span>
          </div>
          <div
            class="copy-icon"
            :style="!detail.value && { 'pointer-events': 'none' }"
            @click="onCopy(detail.value)"
          >
            <span>
              <icons
                name="copy"
                view="0 0 16 16"
                color="darkestgrey"
                size="normal"
              />
            </span>
          </div>
        </div>
        <div class="contact-labels-container">
          <div class="flex-row flex-align">
            <span class="mg-left--smaller mg-right--two">
              <icons name="hashFilled" color="darkgrey" size="normal" />
            </span>
            <span
              class="text-dark subtitle-s2 text-truncate"
              v-text="$t('CONTACT_PANEL.CONTACT_TAGS.LABEL')"
            />
          </div>
          <contact-labels
            :contact-labels="contactLabels"
            :contact-id="contact.id"
            show-add-button
            @updateLabels="UpdateLabels"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import copy from 'copy-text-to-clipboard';

import ContactLabels from 'dashboard/routes/dashboard/contacts/labels/LabelBox';

import inboxMixin from 'dashboard/mixins/inbox';
import alertMixin from 'shared/mixins/alertMixin';

import { mapGetters } from 'vuex';

export default {
  components: { ContactLabels },
  mixins: [inboxMixin, alertMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    inboxInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      contactLabelTitles: [],
    };
  },
  computed: {
    ...mapGetters({
      allContactLabels: 'labels/getContactLabelsWithoutGroups',
    }),
    badgeType() {
      return this.getInboxClassByType(
        this.inboxInfo?.channel_type,
        this.inboxInfo?.phone_number,
        '',
        this.inboxInfo?.instagram_add_on
      );
    },
    contactDetails() {
      return [
        {
          id: 1,
          value: this.contact?.phone_number,
          icon: 'phone',
          iconColor: 'darkestgrey',
          href: this.contact?.phone_number
            ? `tel:${this.contact.phone_number}`
            : null,
        },
        {
          id: 2,
          value: this.contact.email,
          icon: 'email',
          iconColor: 'darkestgrey',
          href: this.contact.email ? `mailto:${this.contact.email}` : null,
        },
        {
          value:
            this.contact.instagram_username?.trim() ||
            this.contact?.additional_attributes?.social_profiles?.instagram_username?.trim(),
          id: 3,
          icon: 'instagram',
          iconColor: 'instagram',
          href: `https://instagram.com/${this.contact?.additional_attributes
            ?.social_profiles?.instagram_username ||
            this.contact.instagram_username}`,
        },
        {
          id: 4,
          value: this.contact?.additional_attributes?.social_profiles?.facebook,
          icon: 'facebook',
          iconColor: 'darkestgrey',
          href: this.contact?.additional_attributes?.social_profiles?.facebook
            ? `https://facebook.com/${this.contact?.additional_attributes?.social_profiles?.facebook}`
            : null,
        },
        {
          id: 5,
          value: this.contact.identifier,
          icon: 'peopleFilled',
          iconColor: 'darkestgrey',
          href: '',
        },
      ];
    },
    contactLabels() {
      return this.allContactLabels.filter(({ title }) => {
        return this.contactLabelTitles.includes(title);
      });
    },
  },
  watch: {
    contactLabels: 'onContactLabelsChange',
  },
  mounted() {
    this.fetchContactLabels(this.contact);
  },
  methods: {
    fetchContactLabels(contact) {
      this.$store.dispatch('contactLabels/get', contact.id).then(data => {
        this.contactLabelTitles = data;
      });
    },
    toggleEditModal() {
      this.$emit('showEdit');
    },
    onCopy(value) {
      copy(value);
      this.showAlert(this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'), 'success');
    },
    async UpdateLabels({ id }, action) {
      try {
        await this.$store
          .dispatch('contactLabels/update', {
            contactId: this.contact.id,
            labelId: id,
            action,
          })
          .then(data => {
            this.contactLabelTitles = data;
          });
      } catch (error) {
        // Ignore error
      }
    },
    onContactLabelsChange() {
      bus.$emit('contactLabelsChange');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.contact--info {
  text-align: left;
  padding: $space-medium;
}

.info--container {
  width: 100%;
}

.info--name {
  @include text-ellipsis;
  text-transform: capitalize;
  white-space: normal;
  line-height: $space-medium;
  font-size: $font-size-default;
  color: $secondary-blue;
  margin-left: $space-normal;
}

.edit-btn {
  @include button-animation;
  margin-left: $space-normal;
}

.separator {
  height: 1px;
  background: $neutral-grey-400;
  width: 100%;
  margin: $space-normal $zero;
}

.contact--details {
  margin-top: $space-slab;
  width: 100%;

  .contact-labels-container {
    position: relative;
    margin-top: $space-slab;

    ::v-deep .add-btn {
      right: $zero;
    }

    .sidebar-labels-wrap {
      padding: $zero $space-four;
    }
  }

  .detail-row {
    width: 100%;
    align-items: center;
    margin-bottom: $space-six;
  }
  .copy-icon {
    cursor: pointer;
    padding: $space-six;
    border-radius: 50%;

    &:hover {
      background: $neutral-grey-100;
      cursor: pointer;
    }
  }
}
</style>
