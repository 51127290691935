<template>
  <div>
    <div
      class="flex-row flex-align flex-justify--between search-container gap--small"
    >
      <woot-base-button
        variant="secondary"
        icon-size="semimedium"
        front-icon="search"
        class="search--button full-width"
        @click="onShowSearchModal"
      >
        <span
          class="body-b3 text-light"
          v-text="$t('CONVERSATION.SEARCH_MESSAGES')"
        />
      </woot-base-button>
      <woot-base-button
        tag="span"
        :variant="uiFlags.isFilterActive ? 'primary' : 'secondary'"
        class="filter-button"
        @click="onShowFilterModal"
      >
        <icons
          name="filterHollow"
          :color="uiFlags.isFilterActive ? 'stroke-white' : 'stroke-lightgrey'"
          size="semimedium"
          title="Apply filters"
        />
        <div
          v-if="uiFlags.isFilterActive"
          class="statbadge statbadge--danger"
        />
      </woot-base-button>
    </div>
    <transition name="modal-fade">
      <filter-options
        v-if="showFilterModal"
        :assignee-tab="assigneeTab"
        :on-close="closeFilter"
      />
      <search
        v-else-if="showSearchModal"
        :saved-search-data="savedSearch.results.length ? savedSearch : null"
        :on-close="closeSearch"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FilterOptions from './filterOptions';
import Search from './search';

import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  name: 'SearchFilter',
  components: {
    FilterOptions,
    Search,
  },
  mixins: [mixPanelAnalyticsMixin],
  props: {
    assigneeTab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showSearchModal: false,
      showFilterModal: false,
    };
  },
  computed: {
    ...mapGetters({
      appliedConversationFilters: 'getConversationFilters',
      uiFlags: 'getConversationUiFlags',
      savedSearch: 'conversationSearch/getSavedSearch',
    }),
  },
  methods: {
    onShowSearchModal() {
      this.showSearchModal = true;
      this.mix_panel_clicked_ticket_search();
    },
    onShowFilterModal() {
      this.showFilterModal = true;
    },
    closeFilter() {
      this.showFilterModal = false;
    },
    closeSearch(id = null) {
      this.showSearchModal = false;
      this.$emit('onConversationLoad', id);
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.search-container {
  .filter-button {
    border: 1px solid $neutral-grey-400;
    position: relative;

    &:hover,
    &:active {
      ::v-deep .icon {
        fill: transparent;
      }
    }
  }

  .search--button {
    border: 1px solid $neutral-grey-400;
    color: $text-light;
    justify-content: flex-start;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: $height-h5;
    text-transform: capitalize;

    ::v-deep .icon {
      fill: $neutral-grey-500;
    }
  }
}

.statbadge {
  border: 0.5px solid $color-white;
  border-radius: 50%;
  bottom: $space-two + $space-micro;
  height: $space-one;
  position: absolute;
  right: $space-micro;
  width: $space-one;

  &--danger {
    background: $warn-red-500;
    border: none;
    bottom: $space-medium;
    right: $zero;
  }
}
</style>
