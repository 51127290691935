<template>
  <div class="flex-row flex-justify--start gap--slab mg-bottom--slab">
    <search-box
      :placeholder="$t('CUSTOM_TICKET_FIELDS.SEARCH_TXT')"
      :value="search"
      custom-class="search-box"
      @setSearch="$emit('setSearch', $event)"
    />
    <inbox-filter
      :value="selectedInbox || 'Inbox'"
      :default-option="defaultOption"
      @click="selectInbox"
    />
    <woot-single-selector
      :default-option="selectedType || 'Type'"
      :option-list="allTemplates"
      @click="selectType"
    />
  </div>
</template>

<script>
import SearchBox from '../../SearchBox';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';
import { CUSTOM_FIELD_TEMPLATES } from '../utils/constants';

export default {
  components: {
    SearchBox,
    InboxFilter,
  },
  data() {
    return {
      search: '',
      defaultOption: { name: 'All Inboxes', id: null },
      allTemplates: [
        { name: 'All Types', id: null },
        ...CUSTOM_FIELD_TEMPLATES,
      ],
      selectedInbox: { id: null, name: 'All Inboxes' },
      selectedType: { id: null, name: 'All Types' },
    };
  },
  methods: {
    selectInbox(selectedInbox) {
      this.$emit('selectedInbox', selectedInbox);
      this.selectedInbox = selectedInbox;
    },
    selectType(selectedType) {
      this.$emit('selectedType', selectedType);
      this.selectedType = selectedType;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.search-box {
  background: none;
  margin-bottom: $zero;
  width: 30.4rem;
}
</style>
