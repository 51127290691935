<template>
  <div>
    <div class="contact--profile lime-card-border">
      <div class="contact--info">
        <div class="info--container flex-row-justify-center">
          <div class="flex-row-justify-center">
            <thumbnail
              :src="contact.thumbnail"
              size="44px"
              color="#1B83AC"
              :username="contact.name"
              :status="contact.availability_status"
            />
            <div class="row" style="max-width: 20rem">
              <div class="info--name medium-12 columns">
                <span
                  v-tooltip="contact.name"
                  class="text-truncate"
                  style="max-width: 18rem"
                >
                  {{ contact.name }}
                </span>
              </div>
              <div
                v-if="inboxInfo.name"
                class="profile--button medium-12 columns name--box"
              >
                <icons
                  :name="badgeType.name"
                  :color="`${badgeType.color} evenodd`"
                />
                <span style="margin-left: 0.2rem">{{ inboxInfo.name }}</span>
              </div>
            </div>
          </div>
          <button class="edit-btn name--box" @click="toggleEditModal">
            <icons
              name="edit"
              size="medium"
              view="0 0 24 24"
              color="stroke-green"
            />
          </button>
        </div>

        <div class="contact--details">
          <div v-if="additionalAttributes.description" class="contact--bio">
            {{ additionalAttributes.description }}
          </div>
          <social-icons :social-profiles="socialProfiles" />
          <div class="contact--metadata">
            <contact-info-row
              :href="contact.email ? `mailto:${contact.email}` : ''"
              :value="contact.email"
              icon="email"
              :title="$t('CONTACT_PANEL.EMAIL_ADDRESS')"
            />
            <contact-info-row
              :href="contact.phone_number ? `tel:${contact.phone_number}` : ''"
              :value="contact.phone_number"
              icon="phone"
              :title="$t('CONTACT_PANEL.PHONE_NUMBER')"
            />
            <contact-info-row
              :value="additionalAttributes.location"
              icon="location"
              :title="$t('CONTACT_PANEL.LOCATION')"
            />
            <contact-info-row
              :value="additionalAttributes.company_name"
              icon="work"
              :title="$t('CONTACT_PANEL.COMPANY')"
            />
          </div>
        </div>

        <edit-contact
          v-if="showEditModal"
          :show="showEditModal"
          :contact="contact"
          parent-component-name="ContactInfo"
          @cancel="toggleEditModal"
        />
        <view-contact
          v-if="showViewModal"
          :show="showViewModal"
          :contact="contact"
          @cancel="toggleViewModal"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ContactInfoRow from './ContactInfoRow';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import SocialIcons from './SocialIcons';
import EditContact from './EditContact';
import ViewContact from './ViewContact';
import inboxMixin from 'dashboard/mixins/inbox';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: {
    ContactInfoRow,
    EditContact,
    ViewContact,
    Thumbnail,
    SocialIcons,
  },
  mixins: [inboxMixin, mixPanelAnalyticsMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    inboxInfo: {
      type: Object,
      default: () => ({}),
    },
    parentComponentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditModal: false,
      showViewModal: false,
      showRaiseTicket: false,
      myTicket: {
        email: this.contact.email,
        phone: this.contact.phone_number,
        name: this.contact.name,
      },
    };
  },
  computed: {
    additionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    badgeType() {
      return this.getInboxClassByType(
        this.inboxInfo?.channel_type,
        this.inboxInfo?.phone_number,
        '',
        this.inboxInfo?.instagram_add_on
      );
    },
    socialProfiles() {
      const {
        social_profiles: socialProfiles,
        screen_name: twitterScreenName,
      } = this.additionalAttributes;
      return {
        twitter: twitterScreenName,
        instagram_username: this.contact.instagram_username,
        ...(socialProfiles || {}),
      };
    },
  },
  updated() {
    this.myTicket.email = this.contact.email;
  },
  methods: {
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
      if (this.parentComponentName === 'ContactInfo' && this.showEditModal) {
        this.mix_panel_clicked_contact_edit();
      }
    },
    toggleViewModal() {
      this.showViewModal = !this.showViewModal;
    },
    toggleRaiseTicket() {
      this.showRaiseTicket = !this.showRaiseTicket;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.contact--profile {
  align-items: flex-start;
  margin-bottom: $space-slab;

  .user-thumbnail-box {
    margin-right: $space-small;
    align-self: center;
  }
}

.contact--details {
  margin-top: $space-slab;
  width: 100%;

  p {
    margin-bottom: 0;
  }
}

.contact--info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.info--name {
  @include text-ellipsis;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  white-space: normal;
  line-height: $space-medium;
  font-size: $font-size-default;
  color: $neutral-grey-800;
}

.profile--button {
  line-height: $space-two;
  font-size: $font-size-mini;
  color: $secondary-blue;
}

.contact--bio {
  margin: $space-small 0 0;
}

.contact--metadata {
  margin: 0;
}

.social--icons {
  i {
    font-size: $font-weight-normal;
  }
}

.info--container {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.name--box {
  @include button-animation;
  display: flex;
  align-items: center;
}
</style>
