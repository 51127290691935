<template>
  <div class="column content-box settings-box ">
    <div class="flex-space-between">
      <search-box
        :placeholder="$t('PRODUCTS_MGMT.SEARCH_TXT')"
        :value="search"
        :on-search="searchProducts"
        @setSearch="setSearch"
      />

      <button class="view-button lime-card-1dp" @click="toggleView">
        <icons
          :name="isGridView ? 'list' : 'grid'"
          size="semimedium"
          color="green"
        />
      </button>
    </div>
    <div class="settings-container lime-card-1dp">
      <div
        v-if="!uiFlags.fetchingProducts && productsList.length"
        class="row custom-scroll "
      >
        <article
          v-for="item in productsList"
          :key="item.id"
          :class="itemClass"
          @click="openCard(item)"
        >
          <div :class="{ card__media: true, 'lime-card-light': !isGridView }">
            <img :src="setImage(item)" />
            <div
              v-if="item.sale_price < item.price && isGridView"
              class="card__discount"
            >
              <span>{{
                `${Math.floor(100 - (100 * item.sale_price) / item.price)}%`
              }}</span>
              <span>OFF</span>
            </div>
          </div>
          <div class="card__content">
            <header class="card__header text-truncate">
              {{ item.name }}
            </header>
            <p
              v-if="item.description !== null"
              class="card__excerpt"
              v-html="item.description.replace(/(<([^>]+)>)/gi, '')"
            ></p>

            <footer class="card__meta flex-space-between" role="contentinfo">
              <div class="card__price">
                <span
                  v-if="item.sale_price < item.price"
                  v-html="`${accountCurrency} ${getPrice(item.sale_price)}`"
                >
                </span>
                <span
                  :class="{
                    'sale-price': item.sale_price < item.price,
                  }"
                  v-html="`${accountCurrency} ${getPrice(item.price)}`"
                >
                </span>
                <span
                  v-if="!isGridView && item.sale_price < item.price"
                  class="price__discount flex-row-justify-center"
                >
                  <icons name="discount" color="primary" size="semimedium" />
                  <span>{{
                    `Buy this at ${Math.floor(
                      100 - (100 * item.sale_price) / item.price
                    )}% OFF discount`
                  }}</span>
                </span>
              </div>
              <button v-if="isGridView" class="button clear see-more">
                See More
              </button>
            </footer>
          </div>
        </article>
      </div>

      <p
        v-if="!uiFlags.fetchingProducts && !productsList.length"
        class="no-items-error-message"
      >
        {{ $t('PRODUCTS_MGMT.LIST.404') }}
      </p>
      <woot-loading-state
        v-if="uiFlags.fetchingProducts"
        :message="$t('PRODUCTS_MGMT.LIST.LOADING')"
      />
      <pagination
        :on-page-change="onPageChange"
        :current-page="currentPage"
        :page-size="8"
        :total-count="meta.count"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchBox from '../SearchBox';
import router from 'dashboard/routes';
import Pagination from 'dashboard/components/widgets/TableFooter';
import { frontendURL } from 'dashboard/helper/URLHelper';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import accountMixin from '../../../../mixins/account';
import * as types from 'shared/constants/googleEventType';

export default {
  name: 'ProductHome',
  components: {
    SearchBox,
    Pagination,
  },
  mixins: [googleAnalyticsMixin, accountMixin],
  data() {
    return {
      search: '',
      currentPage: 1,
      isGridView: true,
    };
  },
  computed: {
    ...mapGetters({
      productsList: 'getProducts',
      meta: 'getProductsMeta',
      uiFlags: 'getProductsUIFlags',
      accountId: 'getCurrentAccountId',
      isDeepShopifyEnabled: 'accounts/getDeepShopifyEnabledFlag',
    }),
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber) && selectedPageNumber >= 1
        ? selectedPageNumber
        : 1;
    },
    itemClass() {
      return this.isGridView
        ? 'product-grid item-wrap--collapse item-wrap'
        : 'medium-12 item-wrap--expand item-wrap';
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
  },
  mounted() {
    this.fetchProducts(this.pageParameter);
  },
  methods: {
    getPrice(value) {
      return value === null || value === undefined
        ? 'price unavailable'
        : value;
    },
    fetchProducts(page, query = null) {
      let payload = {
        page,
      };

      if (this.isDeepShopifyEnabled)
        payload = {
          ...payload,
          is_shopify: true,
        };

      if (query) payload = { ...payload, query };
      this.$store.dispatch('getProducts', payload);
    },
    setSearch(value) {
      this.googleAnalyticsEvent(
        types.default.PRODUCT_SEARCH_SETTINGS,
        types.default.PRODUCT_SETTINGS,
        value
      );

      const refetchAllproducts = !!this.search && value === '';
      if (refetchAllproducts) {
        this.fetchProducts(1);
      }
      this.search = value;
    },
    setImage(item) {
      if (this.isDeepShopifyEnabled) {
        return (item?.default_image?.src || item.shopify_images[0]?.src || '');
      }
      return item.Image;
    },
    toggleView() {
      this.isGridView = !this.isGridView;
    },
    openCard(item) {
      router.push({
        path: frontendURL(
          `accounts/${this.accountId}/settings/products/${item.id}?page=${this.currentPage}`
        ),
      });
    },
    searchProducts() {
      if (this.search) this.fetchProducts(1, this.search);
    },
    onPageChange(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      if (this.search) {
        this.fetchProducts(page, this.search);
      } else {
        this.fetchProducts(page);
      }
      this.currentPage = page;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .flex-space-between {
    .search {
      width: 40%;
    }

    .view-button {
      cursor: pointer;
      padding: $space-small;
    }
  }

  .settings-container {
    display: flex;
    height: calc(100% - 60px);
    flex-direction: column;
    justify-content: space-between;
  }

  .footer {
    padding: 0;
  }
}

.item-wrap {
  display: flex;
  cursor: pointer;
  margin-right: 3.6rem;
  margin-bottom: 3.6rem;
  border-radius: $border-radius;
  border: 1px solid $neutral-grey-400;
  background: $neutral-white;
  min-height: 37vh;

  &:nth-child(4n) {
    margin-right: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  &--collapse {
    flex-direction: column;

    .card__media {
      width: 100%;
      height: 66.25%;

      img {
        width: 100%;
        max-height: 100%;
        border-radius: $border-radius $border-radius $zero $zero;
      }
    }

    .card__content {
      padding: $space-slab;

      .card__header {
        font-size: $font-size-default;
        line-height: $space-medium;
        color: $secondary-blue;
      }

      .card__excerpt {
        -webkit-line-clamp: 2;
      }
    }

    .card__meta {
      .card__price {
        font-size: $font-size-default;
        line-height: $space-medium;
      }
    }
  }

  &--expand {
    flex-direction: row;
    padding: $space-large $space-slab $space-large $space-slab;
    border-radius: 0;
    border: 0;
    margin: 0;
    border-bottom: 1px solid $neutral-grey-400;
    height: 30vh;

    .card__media {
      width: 15%;
      min-width: 18rem;
      height: 100%;
      padding: 0;

      img {
        max-width: 100%;
        height: 100%;
        border-radius: $border-radius;
      }
    }

    .card__content {
      margin-left: $space-medium;

      .card__header {
        margin-bottom: $space-medium;
        font-size: $font-size-big;
        line-height: $space-large;
        color: $neutral-grey-800;
      }

      .card__excerpt {
        -webkit-line-clamp: 4;
      }
    }

    .card__meta {
      .card__price {
        font-size: $font-size-big;
        line-height: $space-large;
      }
    }
  }

  .card__media {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      padding-right: 0;
      object-fit: cover;
    }

    .card__discount {
      position: absolute;
      right: $space-normal;
      top: $space-slab;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: $neutral-white;
      border: 2px solid $secondary-blue;
      font-size: $font-size-mini;
      line-height: 1.4rem;
      color: $secondary-blue;
    }
  }

  .card__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .card__excerpt {
      position: relative;
      font-size: $font-size-small;
      line-height: 22px;
      color: $neutral-grey-600;
      display: -webkit-box;
      max-width: 100%;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: start;
      word-break: break-word;

      &::after {
        content: '-';
        opacity: 0;
      }
    }

    .card__meta {
      border-bottom: 0;

      .card__price {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $neutral-grey-800;

        .sale-price {
          margin-left: $space-small;
          color: $neutral-grey-600;
          font-size: $font-size-small;
          text-decoration: line-through;
          text-decoration-color: $warn-red-500;
        }
      }

      .price__discount {
        margin-left: $space-medium;
        align-items: center;
        color: $secondary-blue;
        font-size: $font-size-default;
        line-height: $space-medium;
      }
    }
  }
}

.see-more {
  padding: 0;
  font-weight: $font-weight-normal;
}

.row {
  padding-top: $space-medium;
}

.no-items-error-message {
  margin-top: $space-medium;
  text-align: center;
}
</style>
