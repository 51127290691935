var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('drag-me',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCallingPopup),expression:"showCallingPopup"}],attrs:{"custom-style":{
    left: '40%',
    top: '25%',
  }},on:{"dragging":_vm.handleDragging,"dropped":_vm.handleDropped}},[_c('div',{staticClass:"dragged-container flex-column flex-align flex-justify--between",class:{
      dragging: _vm.isActivated,
      'error-shadow': _vm.callStatesFlags.callFailure,
    }},[_c('div',{staticClass:"flex-column flex-justify--between",style:(("height: " + _vm.dynamicHeight))},[_c('div',{staticClass:"container--top"},[_c('call-status',{attrs:{"current-status":_vm.callStatus}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.eventName.CUSTOMER_ANSWER || _vm.eventName.BRIDGE || _vm.eventName.HANGUP
          ),expression:"\n            eventName.CUSTOMER_ANSWER || eventName.BRIDGE || eventName.HANGUP\n          "}],staticClass:"timer flex-column flex-justify flex-align"},[_c('call-timer',{ref:"callTimer"})],1)],1),_vm._v(" "),_c('div',{staticClass:"container--mid"},[(_vm.eventName.AGENT_CALL || !_vm.eventName)?_c('div',{staticClass:"avatar-container flex-column flex-align"},[(_vm.eventName.AGENT_CALL || _vm.eventName.CUSTOMER_ANSWER)?_c('div',{staticClass:"outbound-ringer"},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":150,"width":150}})],1):_vm._e(),_vm._v(" "),(_vm.recepientName)?_c('avatar',{attrs:{"username":_vm.recepientName,"rounded":"","size":80,"colored":true,"custom-style":{
              'font-size': '2.4rem',
              'box-shadow': 'none',
              border: 'none',
            }}}):_c('span',{staticClass:"user-icon"},[_c('icons',{attrs:{"name":"user","color":"primary","size":"largest"}})],1)],1):_c('customer-connection',{attrs:{"event-name":_vm.eventName,"agent-name":_vm.agentName,"agent-role":_vm.agentRole,"customer-name":_vm.computedCustomerName}}),_vm._v(" "),_c('div',{staticClass:"customer-info flex-column flex-align"},[(_vm.recepientName !== '')?_c('span',{staticClass:"text-align--center title-h1 text-dark"},[_vm._v("\n            "+_vm._s(_vm.recepientName ||
                _vm.$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.NEW_USER'))+"\n          ")]):_vm._e(),_vm._v(" "),(!_vm.eventName)?_c('div',{staticClass:"content-margin"},[_c('woot-input',{staticClass:"medium-12 columns phone-number-field",class:{ error: _vm.$v.customerNumber.$error },attrs:{"type":"Number","size":"small","error":_vm.$t(
                  'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.PHONE.ERROR'
                ),"has-error":_vm.$v.customerNumber.$error,"placeholder":_vm.$t(
                  'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.PHONE.PLACEHOLDER'
                )},on:{"blur":_vm.$v.customerNumber.$touch},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.placeCall($event)}},model:{value:(_vm.customerNumber),callback:function ($$v) {_vm.customerNumber=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"customerNumber"}})],1):(_vm.callingMeta)?_c('div',{staticClass:"flex-column flex-align",staticStyle:{"gap":"0.4rem"}},_vm._l((Object.keys(_vm.callingMeta)),function(title,idx){return _c('span',{key:idx,staticClass:"body-b3 text-light"},[_vm._v("\n              "+_vm._s(_vm.callingMeta[title])+"\n            ")])}),0):_vm._e()]),_vm._v(" "),(!_vm.eventName)?_c('span',[_c('span',{staticClass:"text-light subtitle-s2"},[_vm._v("\n            "+_vm._s(_vm.$t(
                'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.INBOX_DROPDOWN.LABEL'
              ))+"\n          ")]),_vm._v(" "),_c('woot-single-selector',{attrs:{"size":"small","button-style":{
              'border-radius': '0.4rem',
            },"custom-style":{
              left: 0,
              top: 0,
              width: '240px',
            },"show-selected-text":false,"default-option":_vm.defaultSelectedInbox.name,"option-list":_vm.modifiedInboxList},on:{"click":_vm.onSelectInbox}})],1):_vm._e(),_vm._v(" "),(_vm.eventName.BRIDGE || _vm.eventName.HANGUP)?_c('span',[(_vm.eventName.BRIDGE || _vm.notes)?_c('span',{staticClass:"text-dark body-b3"},[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CALL_NOTES'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.eventName.BRIDGE)?_c('woot-input',{attrs:{"rows":"3","type":"text","placeholder":'Jot down something...'},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"notes"}}):_c('div',{staticClass:"body-b2 text-light notes custom-scroll",domProps:{"textContent":_vm._s(_vm.notes)}})],1):_vm._e()],1)]),_vm._v(" "),(!_vm.eventName || _vm.eventName.HANGUP)?_c('div',{staticClass:"container--bottom flex-column flex-align"},[_c('div',{class:{ shake: _vm.callStatesFlags.callFailure }},[_c('woot-base-button',{staticClass:"call--btn",attrs:{"size":"small","front-icon":"phone","icon-size":"semimedium","disabled":_vm.$v.customerNumber.$error,"loading":_vm.callStatesFlags.initiatingCall},on:{"click":_vm.placeCall}},[_vm._v("\n          "+_vm._s(_vm.placeCallBtnLabel)+"\n        ")])],1),_vm._v(" "),_c('woot-base-button',{staticClass:"call--btn",attrs:{"type":"button","variant":"tertiary-danger","size":"small"},on:{"click":_vm.cancelCall}},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CANCEL'))+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.eventName.BRIDGE)?_c('div',{staticClass:"flex-column flex-align mt-1"},[(
          _vm.isInboundCall && _vm.currentCallState.conversationId && _vm.eventName.BRIDGE
        )?_c('woot-primary-button',{attrs:{"type":"button","size":"small","name":_vm.$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.OPEN_TICKET'),"front-icon":"history","icon-size":"semimedium"},on:{"click":_vm.openTicket}}):_vm._e(),_vm._v(" "),(_vm.eventName.BRIDGE)?_c('woot-tertiary-button',{attrs:{"type":"button","size":"small","name":_vm.$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CANCEL'),"custom-class":"cancel--btn"},on:{"click":function () { return _vm.$store.dispatch('showCallPopup', false); }}}):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }