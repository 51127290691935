import alertMixin from 'shared/mixins/alertMixin';

const showError = validationPayload => {
  const {
    message = 'Unhandled error occured',
    isValid,
    errorneousFieldClassName,
    specificFieldSelector = 'input, textarea',
  } = validationPayload;

  if (!isValid) {
    const invalidElement = document?.querySelector(
      `.${errorneousFieldClassName}`
    );

    const invalidField = invalidElement?.querySelector(specificFieldSelector);

    if (invalidElement) {
      invalidElement.scrollIntoView({ behavior: 'smooth' });

      let currentEl = invalidElement;
      if (invalidField) {
        invalidField.focus();
        currentEl = invalidField;
      }

      currentEl.classList.add('invalid-field-error-highlighting');
      setTimeout(() => {
        currentEl.classList.remove('invalid-field-error-highlighting');
      }, 8000);
    }
    alertMixin.methods.showAlert(message, 'error');
  }
};

export default showError;
