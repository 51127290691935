<template>
  <div class="conversations-sidebar columns">
    <div class="conversations-sidebar-header">
      <chat-list-top @filterChange="updateFilter" />

      <!-- Sort filter -->
      <div class="conversation-sort-filter">
        <conversation-sort-filter
          ref="conversationSortFilter"
          variant="tertiary"
          @applyFilter="applySortFilter"
        />
      </div>

      <!-- <reference-box
        v-if="isConcurrencyEnabled"
        class="reference-box flex-row flex-justify flex-gap--small"
        text-color="#F08700"
        icon-name="ticket"
        icon-color="darkyellow"
        icon-size="semimedium"
        :content="agentTicketInfo"
      >
        <div
          v-if="concurrencyLimitReached"
          v-tooltip="agentInfoTooltipContent"
          style="margin-left: 8px"
        >
          <icons name="info" size="normal" color="darkyellow" />
        </div>
      </reference-box> -->

      <selection-bar
        v-if="selected.length"
        :selected="selected"
        :select-all-loaded="selectAllLoaded"
        :select-all="selectAll"
        :select-count="selectCount"
        @select-card="selectCard"
        @select-all-conversation="selectAllConversation"
        @show-modify-popup="showModifyPopup"
      />
    </div>

    <p v-if="!chatListLoading && !conversationList.length" class="content-box">
      {{ $t('CHAT_LIST.LIST.404') }}
    </p>
    <div class="conversations-list">
      <conversation-card
        v-for="chat in conversationShowList"
        :key="chat.id"
        :show-select-option="true"
        :selected="isSelected(chat.id)"
        :selection="!!selected.length"
        :select-all="selectAllLoaded"
        :active-tab="activeAssigneeTab"
        :active-status="activeStatus"
        :chat="chat"
        :team-id="teamId"
        :chat-account-name="companyName(chat.account_id)"
        @select="selectCard"
      />

      <div v-if="chatListLoading" class="text-center">
        <span class="spinner" />
      </div>

      <div
        v-if="!hasCurrentPageEndReached && !chatListLoading"
        class="clear button load-more-conversations"
        @click="loadMoreConversations"
      >
        {{ $t('CHAT_LIST.LOAD_MORE_CONVERSATIONS') }}
      </div>

      <p
        v-if="
          conversationList.length &&
            hasCurrentPageEndReached &&
            !chatListLoading
        "
        class="text-center text-muted end-of-list-text"
      >
        {{ $t('CHAT_LIST.EOF') }}
      </p>
    </div>

    <bulk-action-modal
      v-if="showModifyModal"
      :show.sync="showModifyModal"
      :selected-conv="selected"
      :selected-count="selectAll ? selectCount : selected.length"
      :select-all="selectAll"
      :filters="appliedConversationFilters"
      :on-close="hideModifyPopup"
      @done="selectCard(true)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ChatListTop from './ChatListTop';
// import ReferenceBox from 'dashboard/components/ui/ReferenceBox';
import BulkActionModal from 'dashboard/components/widgets/conversation/BulkActionModal';
import ConversationCard from 'dashboard/components/widgets/conversation/ConversationCard';
import ConversationSortFilter from 'dashboard/components/widgets/conversation/filter/ConversationSortFilter';
import SelectionBar from './SelectionBar';

import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import conversationMixin from 'dashboard/mixins/conversations';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import wootConstants from 'dashboard/constants';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    ConversationCard,
    ChatListTop,
    ConversationSortFilter,
    BulkActionModal,
    // ReferenceBox,
    SelectionBar,
  },
  mixins: [
    alertMixin,
    timeMixin,
    conversationMixin,
    googleAnalyticsMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      selectAllLoaded: false,
      selectAll: false,
      showModifyModal: false,
      faviconUpdated: false,
      tabFocus: true,
      activeStatus: wootConstants.STATUS_TYPE.OPEN,
      count: 0,
      prevMineCount: 0,
      selected: [],
      teamId: null,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
      mineChatsList: 'getMineChats',
      allChatList: 'getAllStatusChats',
      unAssignedChatsList: 'getUnAssignedChats',
      chatListLoading: 'getChatListLoadingStatus',
      currentUserID: 'getCurrentUserID',
      currentUser: 'getCurrentUser',
      conversationStats: 'conversationStats/getStats',
      appliedConversationFilters: 'conversationViews/getCurrentViewFilters',
      currentAgentTicketCount: 'agents/getCurrentAgentTicketsCount',
      inboxes: 'inboxes/getInboxes',
      views: 'conversationViews/getAllViews',
      currentConversationView: 'conversationViews/getCurrentView',
    }),
    currentViewDetails() {
      return {
        filters: { ...this.appliedConversationFilters },
        view: { ...this.currentConversationView },
      };
    },
    activeAssigneeTab() {
      return this.appliedConversationFilters.assigneeType;
    },
    isConcurrencyEnabled() {
      return this.currentAgentTicketsMeta.concurrencyEnabled;
    },
    currentAgentDetails() {
      return this.$store.getters['agents/getAgent'](this.currentUserID);
    },
    currentAgentTicketsMeta() {
      return {
        concurrencyEnabled: this.currentAgentDetails.concurrency_enabled,
        concurrencyLimit: this.currentAgentDetails.concurrency_limit,
        assignedCount: this.currentAgentTicketCount,
      };
    },
    concurrencyLimitReached() {
      const { assignedCount, concurrencyLimit } = this.currentAgentTicketsMeta;
      return assignedCount >= concurrencyLimit;
    },
    agentTicketInfo() {
      const { assignedCount, concurrencyLimit } = this.currentAgentTicketsMeta;

      return this.concurrencyLimitReached
        ? 'Concurrency Limit Reached.'
        : `Concurrency status ${assignedCount}/${concurrencyLimit} Tickets`;
    },
    agentInfoTooltipContent() {
      const { assignedCount, concurrencyLimit } = this.currentAgentTicketsMeta;

      return assignedCount >= concurrencyLimit
        ? 'You have reached the maximum number of tickets that can be assigned to you. Please resolve your existing tickets before new ones can be assigned.'
        : 'You can work on multiple tickets at the same time. The number of tickets you can work on simultaneously is limited by your account administrator by setting concurrency limit.';
    },
    conversationInboxId() {
      return this.$route.params.inbox_id;
    },
    currentPage() {
      return this.$store.getters['conversationPage/getCurrentPage'](
        this.activeAssigneeTab
      );
    },
    hasCurrentPageEndReached() {
      return this.$store.getters['conversationPage/getHasEndReached'](
        this.activeAssigneeTab
      );
    },
    conversationList() {
      let conversationList = [];
      if (this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.ME)
        conversationList = this.mineChatsList.slice();
      else if (
        this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.UNASSIGNED
      )
        conversationList = this.unAssignedChatsList.slice();
      else conversationList = this.allChatList.slice();

      conversationList = this.sortConversationsByLastMessageDate(
        conversationList
      );

      conversationList = this.getFilteredConversations(
        conversationList,
        this.appliedConversationFilters
      );

      return conversationList;
    },
    conversationShowList() {
      return this.conversationList;
    },
    selectCount() {
      let selectedIndex = this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS').findIndex(
        item => item.KEY === this.activeAssigneeTab
      );

      return this.conversationStats[
        this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS')[selectedIndex].COUNT_KEY
      ];
    },
  },
  watch: {
    currentViewDetails: {
      handler: 'setFilterStates',
      deep: true,
    },
  },
  mounted() {
    window.onfocus = this.onWindowFocus;
    window.onblur = this.onWindowBlur;

    this.$store.dispatch('setChatStatus', this.activeStatus);

    this.updateConversationFilter('inboxId', this.conversationInboxId);

    bus.$on('fetch_conversation_stats', this.updateConversationStats);

    // this.fetchCurrentAgentTicketCount();
  },
  methods: {
    fetchCurrentAgentTicketCount() {
      this.$store.dispatch(
        'agents/getCurrentAgentTicketCount',
        this.currentUserID
      );
    },
    setFilterStates(
      { view: newView, filters: newFilters },
      { view: oldView, filters: oldFilters }
    ) {
      if (!oldView?.id) return;

      const { status, isNew: newest, oldest } = newFilters;

      // set status
      this.activeStatus = status;
      this.$store.dispatch('setChatStatus', status);

      // set Conversation Sort filter
      const sortByBools = { newest, oldest };
      // Set selected values of sort filter as the default.
      this.setConversationSortDefaultValues(
        Object.keys(sortByBools).filter(key => sortByBools[key])
      );

      const changedFilters = this.getChangedFilters(newFilters, oldFilters);
      const viewNotChanged =
        `${oldView?.id}_${oldView.view_type}` ===
        `${newView?.id}_${newView.view_type}`;

      if (viewNotChanged) {
        this.resetAndFetchData({
          ...changedFilters,
          page: 1,
        });
      } else {
        this.resetAndFetchData({ page: 1 });
      }
    },
    getChangedFilters(newFilters, oldFilters) {
      return Object.entries(newFilters).reduce((acc, [key, value]) => {
        if (value !== oldFilters[key]) {
          acc[key] = value;
        }
        return acc;
      }, {});
    },
    sortConversationsByLastMessageDate(conversationList) {
      return conversationList.sort((a, b) => {
        const lastMessageA = a.messages?.last();
        const lastMessageB = b.messages?.last();

        const dateA = lastMessageA ? lastMessageA.created_at : 0;
        const dateB = lastMessageB ? lastMessageB.created_at : 0;

        return dateB - dateA;
      });
    },
    updateConversationFilter(key, value) {
      this.$store.dispatch('conversationViews/updateCurrentViewFilters', {
        key,
        value,
      });
    },
    setDocumentTitle(item) {
      this.count = this.conversationStats[item.COUNT_KEY] || 0;
      if (item.KEY === wootConstants.ASSIGNEE_TYPE.ME) {
        // update document title with updated mine count
        document.title = `(${this.count}) ${this.companyName(
          this.accountId
        )} | LimeChat`;
        if (this.prevMineCount < this.count) this.changeFavicon();
        this.prevMineCount = this.count;
      }
    },
    changeFavicon() {
      if (this.tabFocus) return;
      document.getElementById('dynamic-favicon').href = '/favicon2-32x32.png';
      this.faviconUpdated = true;
    },
    companyName(currentAccountId) {
      const currentAccount = this.currentUser.accounts?.find(
        acc => acc.id === currentAccountId
      );
      return currentAccount?.name || '';
    },
    updateConversationStats(data) {
      if (data.status !== this.activeStatus) return;
      if (
        data.inbox_id !== this.appliedConversationFilters.inboxId &&
        this.appliedConversationFilters.inboxId !== undefined
      )
        return;

      this.$store.dispatch('conversationStats/update', {
        ...data,
        activeStatus: this.activeStatus,
        userId: this.currentUserID,
      });
    },
    isSelected(id) {
      return this.selected.includes(id);
    },
    selectCard(selected, id = -1) {
      if (id > 0) {
        if (selected) {
          this.selected = this.selected.filter(item => item !== id);
          this.selectAllLoaded = false;
          this.selectAll = false;
        } else {
          this.selected.push(id);
        }
      } else if (selected) {
        this.selectAllLoaded = false;
        this.selectAll = false;
        this.selected = [];
      } else {
        this.mix_panel_clicked_ticket_select();
        this.selectAllLoaded = true;
        this.selected = this.conversationList.map(item => item.id);
      }
    },
    resetAndFetchData(params) {
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.fetchConversations(params);
    },
    loadMoreConversations() {
      this.fetchConversations({ page: this.currentPage + 1 });
    },
    fetchConversations(params) {
      this.googleAnalyticsEvent(
        types.default.LOAD_MORE_CONVERSATIONS,
        types.default.DASHBOARD_MID_PANEL,
        types.default.LOAD_MORE_CONVERSATIONS
      );

      this.$store.dispatch(
        'conversationPage/setCurrentPage',
        {
          filter: this.activeAssigneeTab,
          page: params.page,
        },
        { root: true }
      );

      this.$store
        .dispatch('fetchAllConversations', {
          current_view_id: this.currentConversationView.id,
          current_view_type: this.currentConversationView.view_type,
          ...params,
        })
        .then(newChats => {
          this.onConversationLoad();

          if (!newChats.length) {
            this.$store.dispatch(
              'conversationPage/setEndReached',
              { filter: this.activeAssigneeTab },
              { root: true }
            );
          }
        });
    },
    onConversationLoad() {
      this.$emit('conversation-load');
    },
    fetchConversationsIfNeeded() {
      if (!this.currentPage)
        this.fetchConversations(this.appliedConversationFilters);
    },
    setConversationSortDefaultValues(values) {
      this.$refs.conversationSortFilter.selectedFilterName = values;
    },
    updateFilter(key, value) {
      switch (key) {
        case 'label':
          this.updateConversationFilter('labels', value);
          break;
        case 'change_inbox':
          this.changeInbox(value);
          break;
        case 'status_time':
          this.updateConversationFilter('statusTimeRange', value);
          if (value && !value.isFiredOnMount) {
            this.mix_panel_clicked_date_filter_menu_item(
              'Tickets',
              value.date_range_name
            );
          }
          break;
        default:
          this.showAlert(
            this.$t('CONVERSATION.FILTER.APPLICATION.ERROR'),
            'error'
          );
          break;
      }
    },
    changeInbox(value) {
      this.updateConversationFilter('inboxId', value.id || undefined);

      this.mix_panel_clicked_inbox_filter_menu_item(
        'Tickets',
        value.id === 0 ? 'All' : value.type
      );
    },
    applySortFilter(_, state) {
      this.setConversationSortFilter(state);
    },
    selectAllConversation() {
      this.mix_panel_clicked_ticket_select_all();
      this.selectAll = !this.selectAll;
    },
    showModifyPopup() {
      this.mix_panel_clicked_ticket_bulk_modify();
      this.showModifyModal = true;
    },
    hideModifyPopup() {
      this.showModifyModal = false;
    },
    onWindowFocus() {
      this.tabFocus = true;
      if (this.faviconUpdated) {
        setTimeout(() => {
          document.getElementById('dynamic-favicon').href =
            '/favicon-32x32.png';
        }, 3000);
      }
    },
    onWindowBlur() {
      this.tabFocus = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.assignee-tabs {
  padding: $zero $space-slab;
  flex-wrap: nowrap;

  .assignee-tab {
    min-width: fit-content;
    width: 100%;
  }
}

.conversation-sort-filter {
  margin: $space-six $space-slab;
}

.reference-box {
  max-width: 100%;
}

.spinner {
  margin-top: $space-normal;
  margin-bottom: $space-normal;
}
</style>
