<template>
  <transition name="menu-list">
    <div
      v-if="callStatesFlags.showCallNotification && !clearNotification"
      class="notification"
    >
      <span class="cross-icon" @click="closeCallNotification">
        <icons name="cross" color="red" size="normal" :show-title="false" />
      </span>
      <div class="notification--container flex-row flex-align">
        <span>
          <lottie
            :options="defaultOptions"
            :height="90"
            :width="90"
            @animCreated="handleAnimation"
          />
        </span>
        <div class="flex-column flex-align--start">
          <span class="text-light body-b2">
            Incoming Call from
          </span>
          <span v-if="customerName" class="inbound-caller title-h3">
            {{ customerName }} ({{ customerNumber }})
          </span>
          <span v-else class="inbound-caller title-h3">
            {{ customerNumber }}
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex';

import Lottie from 'vue-lottie';
import animationData from 'dashboard/assets/lottie/inboundRinger.json';

import callMixin from 'shared/mixins/callMixin';

import inboundRingerAudio from 'shared/assets/audio/apple_watch_ringtone.mp3';

const audio = new Audio(inboundRingerAudio);
audio.loop = true;

export default {
  components: { Lottie },
  mixins: [callMixin],
  data() {
    return {
      clearNotification: false,
      defaultOptions: { animationData },
      customerNumber: '',
      customerName: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      callStatesFlags: 'getCallStatesUiFlags',
      currentCallState: 'getCurrentCallState',
    }),
  },
  watch: {
    'currentCallState.eventName'(newValue) {
      if (newValue === 'BRIDGE' || newValue === 'HANGUP') this.pauseAudio();
    },
  },
  created() {
    bus.$on('incomingCall', data => {
      this.clearNotification = false;
      this.customerNumber = data.customer_number;
      this.customerName = data.contact || '';
      this.$store.dispatch('showCallNotification', data.show);

      this.playAudio();

      const {
        phone_number: agentNumber,
        role: agentRole,
        available_name: agentName,
      } = this.currentUser;

      this.createCallInstance({
        agentNumber,
        agentRole,
        agentName,
      });
    });
  },
  methods: {
    playAudio() {
      audio.play();
    },
    pauseAudio() {
      audio.pause();
      audio.currentTime = 0;
    },
    closeCallNotification() {
      this.clearNotification = true;
      this.pauseAudio();
    },
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.setSpeed(0.522);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.notification {
  @include elevation-2dp;

  background: $color-white;
  border: 1px solid $secondary-blue;
  border-radius: $border-radius;
  bottom: $space-one * 10;
  display: inline-block;
  height: $space-slab * 10;
  padding: $space-small;
  position: absolute;
  right: $space-normal;
  text-align: center;
  width: 40.4rem;
  z-index: 9999;

  &--container {
    height: 100%;
  }

  .cross-icon {
    cursor: pointer;
    position: absolute;
    right: $space-small;
    top: $space-small;

    animation: rotate 1.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .inbound-caller {
    color: $secondary-blue;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(120deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
}
</style>
