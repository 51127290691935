export const FILTERS = [
  {
    GROUP_NAME: 'Status Filters',
    FILTERS: [
      {
        name: 'Assignee',
        key: 'assignee_type',
      },
      {
        name: 'Conversation Status',
        key: 'conversation_status',
      },
    ],
  },
  {
    GROUP_NAME: 'Frequently used Filters',
    FILTERS: [
      {
        name: 'Status Change Time',
        key: 'status_time',
        icon: 'history',
      },
      {
        name: 'Inboxes',
        key: 'inbox',
        icon: 'inboxSidebar',
      },
      {
        name: 'Agent',
        key: 'agent',
        icon: 'agent',
      },
      {
        name: 'Relevance',
        key: 'relevance',
        icon: 'filter',
      },
    ],
  },
  {
    GROUP_NAME: 'Advanced Filters',
    FILTERS: [
      {
        name: 'Teams',
        key: 'teams',
        icon: 'people',
      },
      {
        name: 'Tags',
        key: 'label',
        icon: 'hash',
        static_title: 'Tags',
      },
      {
        name: 'Assigned By',
        key: 'assigned_by',
        icon: 'share',
      },
      {
        name: 'Resolved By',
        key: 'resolved_by',
        icon: 'tickRound',
      },
      {
        name: 'Closed By',
        key: 'closed_by',
        icon: 'tickRound',
      },
      {
        name: 'Facebook',
        key: 'facebook',
        icon: 'facebook',
      },
    ],
  },
];

export const SLA_BREACHED_FILTER = {
  name: 'Service Level Agreement',
  key: 'sla_breached',
  icon: 'filterBtn',
};
