export default {
  watch: {
    'currentCallState.eventName'(newValue) {
      switch (newValue) {
        case 'AGENT_CALL':
          this.dynamicHeight = '75%';
          this.callStatus = {
            message: 'Step 1',
            color: '#808975',
            status: this.$t(
              'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CALL_STATUSES.RINGING',
              { role: this.agentRole }
            ),
            statusColor: '#3C492C',
          };

          break;

        case 'AGENT_ANSWER':
          this.dynamicHeight = '80%';
          this.callStatus = {
            message: 'Step 2',
            color: '#808975',
            status: this.$t(
              'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CALL_STATUSES.RINGING_CUSTOMER'
            ),
            statusColor: '#3C492C',
          };

          this.changeAvailabilityStatus('oncall');

          break;

        case 'CUSTOMER_CALL':
          this.callStatus = {
            message: 'Step 2',
            color: '#808975',
            status: this.$t(
              'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CALL_STATUSES.RINGING_CUSTOMER'
            ),
            statusColor: '#3C492C',
          };

          break;

        case 'CUSTOMER_ANSWER':
          this.dynamicHeight = '70%';
          this.callStatus = {
            message: this.$t(
              'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.STATES.CONNECTING'
            ),
            color: '#808975',
          };

          break;

        case 'BRIDGE':
          this.dynamicHeight = '90%';

          this.callStatus = {
            message: '',
            status: this.$t(
              'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CALL_STATUSES.BRIDGE'
            ),
            statusColor: '#6BAC1B',
          };
          if (this.isInboundCall) this.changeAvailabilityStatus('oncall');
          this.$refs.callTimer.startTimer();
          this.$store.dispatch('showCallPopup', true);
          this.$store.dispatch('showCallNotification', false);

          break;

        case 'HANGUP':
          this.dynamicHeight = '60%';

          this.callStatus = {
            message: '',
            status: this.$t(
              'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CALL_STATUSES.HANGUP'
            ),
            statusColor: '#FF7E61',
          };

          this.$refs.callTimer.stopTimer();
          this.$store.dispatch('updateAvailability', {
            availability:
              this.previousAvailabilityStatus || this.currentAvailabilityStatus,
            account_id: this.accountId,
          });
          this.$store.dispatch('showCallNotification', false);
          this.chat = null;
          this.activeInbox = null;

          break;

        default:
          this.dynamicHeight = '90%';

          this.callStatus = {
            message: this.$t(
              'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CALL_STATUSES.DEFAULT'
            ),
            color: '#808975',
          };

          break;
      }
    },
    notes(n) {
      this.$store.dispatch('setCallMeta', { notes: n, callDuration: '' });
    },
  },
};
