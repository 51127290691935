/* global axios */
import ApiClient from '../ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  get({
    inboxId,
    status,
    assigneeType,
    starred,
    oldest,
    page,
    labels,
    selectedAgent,
    unread,
    isNew,
    alert,
    teamId,
    identifier,
    assigned_by,
    resolved_by,
    closed_by,
    statusTimeRange,
    sla_breached,
    current_view_id,
    current_view_type,
  }) {
    return axios.get(this.url, {
      params: {
        inbox_id: inboxId,
        status,
        starred,
        oldest,
        assignee_type: assigneeType,
        page,
        label_ids: labels,
        assigned_by,
        resolved_by,
        closed_by,
        selectedAgent,
        unread,
        is_new: isNew,
        alert,
        team_id: teamId,
        identifier,
        status_time_range: statusTimeRange,
        sla_breached,
        current_view_id,
        current_view_type,
      },
    });
  }

  search({ query_type, q, page, inbox_id, status_time_range }) {
    return axios.get(`${this.baseUrl()}/search/initiate_search`, {
      params: {
        query_type,
        inbox_id,
        status_time_range,
        q,
        page,
      },
    });
  }

  toggleStatus({ conversationId, conversationStatus, alertDelay, nextStatus }) {
    return axios.post(`${this.url}/${conversationId}/toggle_status`, {
      status: conversationStatus,
      alert_delay: alertDelay,
      next_status: nextStatus,
    });
  }

  toggleStarred(conversationId) {
    return axios.post(`${this.url}/${conversationId}/toggle_starred`, {});
  }

  assignAgent({ conversationId, agentId }) {
    return axios.post(
      `${this.url}/${conversationId}/assignments?assignee_id=${agentId}`,
      {}
    );
  }

  assignTeam({ conversationId, teamId }) {
    const params = { team_id: teamId };
    return axios.post(`${this.url}/${conversationId}/assignments`, params);
  }

  markMessageRead({ id }) {
    return axios.post(`${this.url}/${id}/update_last_seen`);
  }

  toggleTyping({ conversationId, status }) {
    return axios.post(`${this.url}/${conversationId}/toggle_typing_status`, {
      typing_status: status,
    });
  }

  mute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/mute`);
  }

  unmute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/unmute`);
  }

  meta({
    inboxId,
    status,
    assigneeType,
    starred,
    oldest,
    page,
    labels,
    selectedAgent,
    unread,
    isNew,
    alert,
    identifier,
    teamId,
    assigned_by,
    resolved_by,
    closed_by,
    statusTimeRange,
  }) {
    return axios.get(`${this.url}/meta`, {
      params: {
        inbox_id: inboxId,
        status,
        starred,
        oldest,
        assignee_type: assigneeType,
        page,
        label_ids: labels,
        selectedAgent,
        unread,
        is_new: isNew,
        alert,
        identifier,
        team_id: teamId,
        assigned_by,
        resolved_by,
        closed_by,
        status_time_range: statusTimeRange,
      },
    });
  }

  bulkUpdate({
    selectAll,
    message,
    alertDelay,
    agentId,
    status,
    starred,
    labels,
    conversationIds,
    filters,
  }) {
    return axios.post(`${this.url}/bulk_update`, {
      select_all: selectAll,
      message,
      alert_delay: alertDelay,
      agentId,
      status,
      starred,
      labels,
      conversation_ids: conversationIds,
      filters: {
        inbox_id: filters.inboxId,
        status: filters.status,
        starred: filters.starred,
        oldest: filters.oldest,
        assignee_type: filters.assigneeType,
        labels: filters.labels,
        selectedAgent: filters.selectedAgent,
        unread: filters.selectedAgent,
        is_new: filters.isNew,
        team_id: filters.teamId,
        alert: filters.alert,
        identifier: filters.identifier,
      },
    });
  }

  sendEmailTranscript({ conversationId, email }) {
    return axios.post(`${this.url}/${conversationId}/transcript`, { email });
  }

  createConversation(payload) {
    return axios.post(`${this.url}/create_ticket`, payload);
  }

  getCustomFieldValues(conversationId) {
    return axios.get(`${this.url}/${conversationId}/fetch_custom_field_values`);
  }

  updateCustomFieldValues(conversationId, customFieldValues) {
    return axios.post(`${this.url}/${conversationId}/update_custom_fields`, {
      custom_fields: customFieldValues,
    });
  }
}

export default new ConversationApi();
