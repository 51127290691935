<template>
  <div class="column content-box settings-box no-padding">
    <woot-base-button
      v-if="isAdmin"
      size="medium"
      front-icon="plus"
      class="settings-button"
      icon-view-box="0 0 20 20"
      @click="handleClick"
    >
      {{ $t('TEAMS_SETTINGS.NEW_TEAM') }}
    </woot-base-button>

    <div class="settings-container">
      <div class="column settings-main-menu-new">
        <div class="table-container">
          <data-table
            :actions="actionsList"
            :columns="tableColumns"
            :table-data="teamsList"
            :loading="uiFlags.isFetching"
            :enable-animations="teamsList.length <= 10"
            _class="scrollable-table custom-scroll"
            @select="viewTeam"
            @delete="openDelete"
          >
            <template v-slot:table-features>
              <search-box
                :placeholder="$t('TEAMS_SETTINGS.SEARCH_TXT')"
                :value="search"
                custom-class="search-box"
                @setSearch="setSearch"
              />
            </template>
            <template v-slot:zero-state>
              <table-zero-state
                v-if="!teamsList.length"
                title="No Teams added till now"
                asset-link="/brand-assets/inbox_zero_state.svg"
              >
                <woot-base-button
                  v-if="isAdmin"
                  size="medium"
                  front-icon="plus"
                  class="settings-button"
                  style="margin-bottom: 5vh"
                  icon-view-box="0 0 20 20"
                  @click="handleClick"
                >
                  {{ $t('TEAMS_SETTINGS.NEW_TEAM') }}
                </woot-base-button>
              </table-zero-state>
            </template>
          </data-table>
        </div>
      </div>

      <settings-side-card>
        <team-form
          v-if="selectedTeam.id"
          :key="selectedTeam.id"
          :selected-team="selectedTeam"
          class="team-form-container"
        />
        <educational-onboarding v-else :show-case-data="SHOWCASE_TEAMS_DATA">
        </educational-onboarding>
      </settings-side-card>
    </div>

    <!-- Add team -->
    <woot-modal-new
      :show.sync="showAddPopup"
      :on-close="() => (showAddPopup = false)"
    >
      <woot-modal-header-new
        :header-title="$t('TEAMS_SETTINGS.CREATE_FLOW.CREATE.TITLE')"
      />
      <div class="add-team-modal">
        <team-form
          class="settings-form custom-scroll"
          @onClose="showAddPopup = false"
        />
      </div>
    </woot-modal-new>

    <woot-delete-modal-new
      :show.sync="showDeletePopup"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('TEAMS_SETTINGS.DELETE.CONFIRM.TITLE')"
      :message="$t('TEAMS_SETTINGS.DELETE.CONFIRM.MESSAGE')"
      show-close
      confirm-text="Continue"
      reject-text="Back"
      :custom-style="{
        'max-width': '35.2rem',
        height: 'auto',
      }"
    />
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import SearchBox from '../SearchBox';
import SettingsSideCard from '../SettingSideCard';
import DataTable from 'dashboard/components/widgets/table/DataTable';
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';
import TeamForm from './TeamForm';
import EducationalOnboarding from 'dashboard/components/ui/settings/EducationalOnboarding';

import adminMixin from '../../../../mixins/isAdmin';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { SHOWCASE_TEAMS_DATA } from 'dashboard/routes/dashboard/settings/teams/constants';

export default {
  name: 'TeamsHome',
  components: {
    SearchBox,
    DataTable,
    TableZeroState,
    TeamForm,
    SettingsSideCard,
    EducationalOnboarding,
  },
  mixins: [adminMixin, accountMixin, alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      search: '',
      showDeletePopup: false,
      showAddPopup: false,
      selectedTeam: {},
      tableColumns: [
        {
          label: '',
          prop: 'id',
          width: '10%',
          component: 'AgentAvatar',
        },
        {
          label: 'Name',
          prop: 'name',
          sortable: true,
        },
      ],
      SHOWCASE_TEAMS_DATA,
    };
  },
  computed: {
    ...mapGetters({
      teams: 'teams/getTeams',
      uiFlags: 'teams/getUIFlags',
    }),
    actionsList() {
      if (!this.isAdmin) return [];
      return [{ id: 1, name: 'delete', icon: 'delete' }];
    },
    teamsList() {
      let teamsList = [];

      teamsList = this.teams.filter(item => {
        return (
          item.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          item.description.toLowerCase().search(this.search.toLowerCase()) > -1
        );
      });

      return teamsList;
    },
    deleteConfirmText() {
      return `${this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedTeam.name
      }`;
    },
    deleteRejectText() {
      return this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.NO');
    },
  },
  created() {
    axios.all([this.$store.dispatch('agents/get')]);
  },
  methods: {
    handleClick() {
      this.mixPanelAddTeamInitiated();
      this.showAddPopup = true;
    },
    viewTeam(team) {
      this.selectedTeam = team;
    },
    setSearch(value) {
      this.mix_panel_searched_settings_team();
      this.search = value;
    },
    async deleteTeam({ id }) {
      try {
        await this.$store.dispatch('teams/delete', id);
        this.selectedTeam = {};
        this.showAlert(this.$t('TEAMS_SETTINGS.DELETE.API.SUCCESS_MESSAGE'));
        this.mix_panel_deleted_settings_team();
      } catch (error) {
        this.showAlert(this.$t('TEAMS_SETTINGS.DELETE.API.ERROR_MESSAGE'));
      }
    },
    confirmDeletion() {
      this.deleteTeam(this.selectedTeam);
      this.closeDelete();
    },
    openDelete() {
      this.showDeletePopup = true;
    },
    closeDelete() {
      this.showDeletePopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.table-container {
  height: 100%;

  table.woot-table tr td {
    word-break: break-word;
  }
}

.no-padding {
  padding-left: 0.1rem;
}

.settings-box {
  padding-top: $space-largest;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .search-box {
      background: none;
      margin-bottom: $zero;
      width: 30.4rem;
    }
  }

  .settings-side-card {
    padding: $zero;
  }
}

.team-form-container {
  padding: $space-two;
}

.add-team-modal {
  // height: $space-large * 10;
  padding: $space-two $space-jumbo $zero;
  // overflow: hidden;

  .settings-form {
    height: $space-large * 10;
    padding: $zero $space-two;
    overflow: overlay;
  }
}
</style>
