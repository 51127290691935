/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class AnalyticsApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  getLastUpdatedAt(scope) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/last_updated_at`,
      { params: { scope } }
    );
  }

  getReport(
    {
      start_date,
      end_date,
      query_type,
      labels,
      inboxes,
      email,
      scope,
      state,
      working_hours,
      agent_id,
    },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/download_report`,
      {
        params: {
          start_date,
          end_date,
          query_type,
          labels,
          inboxes,
          email,
          scope,
          state,
          working_hours,
          agent_id,
        },
        signal,
      }
    );
  }

  getBotReport(
    { start_date, end_date, email, labels, inboxes, working_hours },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/bot_performance`,
      {
        params: {
          start_date,
          end_date,
          email,
          labels,
          inboxes,
          working_hours,
          query_type: 'download_report',
        },
        signal,
      }
    );
  }

  getUserReport(
    { start_date, end_date, email, labels, inboxes, working_hours },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date,
        end_date,
        email,
        labels,
        inboxes,
        working_hours,
        query_type: 'download_report',
      },
      signal,
    });
  }

  getCoversationStats(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/conversation_overview`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          labels: labels,
          working_hours: workingHours,
          query_type: 'status',
        },
        signal,
      }
    );
  }

  getCoversationPeriodicStates(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/conversation_overview`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          labels: labels,
          working_hours: workingHours,
          query_type: 'periodic-states',
        },
        signal,
      }
    );
  }

  getCoversationAgentHandoffTrend(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/conversation_overview`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          labels: labels,
          working_hours: workingHours,
          query_type: 'agent_handoff_trend',
        },
        signal,
      }
    );
  }

  getAgentPerformanceStats(
    { startDate, endDate, inboxes, workingHours, agentId, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/agent_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          labels: labels,
          working_hours: workingHours,
          agent_id: agentId,
          query_type: 'overallagentperformance',
        },
        signal,
      }
    );
  }

  getAgentHourlyPerformance(
    { startDate, endDate, inboxes, labels, agentId, workingHours },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/agent_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          labels: labels,
          working_hours: workingHours,
          agent_id: agentId,
          query_type: 'agenthourlyperformance',
        },
        signal,
      }
    );
  }

  getPeriodicAgentsPerformance(
    { startDate, endDate, inboxes, workingHours, agentId, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/agent_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          agent_id: agentId,
          labels: labels,
          working_hours: workingHours,
          query_type: 'periodicagentsperformance',
        },
        signal,
      }
    );
  }

  getAgentPerformanceLogs(
    { startDate, endDate, inboxes, workingHours, agentId, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/agent_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          labels: labels,
          agent_id: agentId,
          working_hours: workingHours,
          query_type: 'allagentsperformance',
        },
        signal,
      }
    );
  }

  getAgentWiseCoversationStates(
    { startDate, endDate, inboxes, workingHours, agentId, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/agent_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          labels: labels,
          agent_id: agentId,
          working_hours: workingHours,
          query_type: 'agentconvstates',
        },
        signal,
      }
    );
  }

  getAgentMessageBlocks(
    { startDate, endDate, inboxes, workingHours, labels, agentId },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/agent_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          labels: labels,
          agent_id: agentId,
          working_hours: workingHours,
          query_type: 'agent_turns_count',
        },
        signal,
      }
    );
  }

  getSalesOverview({ startDate, endDate }, signal) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/sales_analytics`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          query_type: 'overview',
        },
        signal,
      }
    );
  }

  getSalesLineChart({ startDate, endDate }, signal) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/sales_analytics`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          query_type: 'periodic_sales',
        },
        signal,
      }
    );
  }

  getSalesTable({ startDate, endDate, page }, signal) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/sales_analytics`,
      {
        params: {
          start_date: startDate,
          page: page,
          end_date: endDate,
          query_type: 'product_wise_sales',
        },
        signal,
      }
    );
  }

  downloadSalesDataTable({ startDate, endDate, email }, signal) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/download_report`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          email: email,
          scope: 'sales',
        },
        signal,
      }
    );
  }

  getSalesPieChart({ startDate, endDate }, signal) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/sales_analytics`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          query_type: 'new_old_customers',
        },
        signal,
      }
    );
  }

  getSalesByScope({ startDate, endDate, scope }) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/sales_analytics`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          scope,
          query_type: 'revenue_overview',
        },
      }
    );
  }

  getSalesFunnelOne({ startDate, endDate }, signal) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/sales_analytics`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          query_type: 'product_quiz_conversion',
        },
        signal,
      }
    );
  }

  getSalesFunnelTwo({ startDate, endDate }, signal) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/sales_analytics`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          query_type: 'pdp_entry_conversion',
        },
        signal,
      }
    );
  }

  getBotPerformanceStats(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/bot_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          working_hours: workingHours,
          labels: labels,
          query_type: 'stat_cards',
        },
        signal,
      }
    );
  }

  getBotAutomationPercent(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/bot_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          working_hours: workingHours,
          labels: labels,
          query_type: 'automation_percentage',
        },
        signal,
      }
    );
  }

  getBotConversionRate(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/bot_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          working_hours: workingHours,
          labels: labels,
          query_type: 'conversion_rate',
        },
        signal,
      }
    );
  }

  getBotUserIntentBreakdown(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/bot_performance`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inboxes: inboxes,
          working_hours: workingHours,
          labels: labels,
          query_type: 'user_intent_breakdown',
        },
        signal,
      }
    );
  }

  getUserChannelDistribution(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        inboxes: inboxes,
        working_hours: workingHours,
        labels: labels,
        query_type: 'channel_distribution',
      },
      signal,
    });
  }

  getUserHandoffByFlows(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        inboxes: inboxes,
        working_hours: workingHours,
        labels: labels,
        query_type: 'handoff_by_flows',
      },
      signal,
    });
  }

  getUserConcerns(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        inboxes: inboxes,
        working_hours: workingHours,
        labels: labels,
        query_type: 'top_concerns',
      },
      signal,
    });
  }

  getUserComplaints(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        inboxes: inboxes,
        working_hours: workingHours,
        labels: labels,
        query_type: 'top_complaints',
      },
      signal,
    });
  }

  getUserActivity(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        inboxes: inboxes,
        working_hours: workingHours,
        labels: labels,
        query_type: 'user_activity',
      },
      signal,
    });
  }

  getBotFailingData(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        inboxes: inboxes,
        working_hours: workingHours,
        labels: labels,
        query_type: 'bot_failing_data',
      },
      signal,
    });
  }

  downloadBotFailingData(
    { startDate, endDate, topFive, inboxes, workingHours, labels, email },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        top_five: topFive,
        inboxes: inboxes,
        working_hours: workingHours,
        labels: labels,
        email: email,
        query_type: 'download_report',
        scope: 'failing',
      },
      signal,
    });
  }

  getHandoffReasonBreakdown(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        inboxes: inboxes,
        working_hours: workingHours,
        labels: labels,
        query_type: 'handoff_intent_breakdown',
      },
      signal,
    });
  }

  getWebsiteEntryPoint(
    { startDate, endDate, inboxes, workingHours, labels },
    signal
  ) {
    return axios.get(`${this.baseUrl()}/integrations/analytics/user_insights`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        inboxes: inboxes,
        working_hours: workingHours,
        labels: labels,
        query_type: 'website_entrypoint',
      },
      signal,
    });
  }

  getDeepShopifyData({
    startDate,
    endDate,
    inboxId,
    agentId,
    status = undefined,
    metrics,
    page,
    items_per_page,
  }) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/deep_shopify_analytics`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inbox_id: inboxId,
          agent_id: agentId,
          order_status: status,
          page,
          items_per_page,
          metrics,
        },
      }
    );
  }

  getDeepShopifyOrderStatusesList() {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/deep_shopify_order_statuses`
    );
  }

  downloadDeepShopifyData({ startDate, endDate, inboxId, agentId, email }) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/deep_shopify_report`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          inbox_id: inboxId,
          agent_id: agentId,
          email,
        },
      }
    );
  }
}

export default new AnalyticsApi();
