export const MESSAGE_STATUS = {
  FAILED: 'failed',
  SENT: 'sent',
  PROGRESS: 'progress',
  DELIVERED: 'delivered',
  READ: 'read',
};

export const MESSAGE_SENDER_TYPE = {
  CONTACT: 'contact',
  AGENT_BOT: 'agentbot',
  OUTBOUND_BOT: 'outboundbot',
  USER: 'user',
  SYSTEM: 'system',
};

export const MESSAGE_TYPE = {
  INCOMING: 0,
  OUTGOING: 1,
  ACTIVITY: 2,
  TEMPLATE: 3,
  SYNC_IN: 4,
  SYNC_OUT: 5,
  START_CONVERSATION: 6,
  INFO_COLLECT: 7,
  OUTBOUND_BOT: 8,
  OUT_OF_OFFICE: 9,
  FORWARDED: 10,
  CSAT_QUESTION: 11,
  GPT: 14,
};

export const S3_BUCKET_URL = 'https://limechats3.s3.ap-south-1.amazonaws.com';

// placeholders
export const PLACEHOLDER_MESSAGE =
  '<i style="color: #f6bd16">Content is loading, please wait...</i>';
export const UNSUPPORTED_TYPE_MESSAGE_PLACEHOLDER =
  '<i style="color: #8c8c8c">This content is unsupported</i>';

export const BOT_AVATAR_IMAGE_URL =
  'https://ik.imagekit.io/vysbzd9hl/Bot.png?updatedAt=1692638582236';

export const AGENT_AVATAR_IMAGE_URL =
  'https://ik.imagekit.io/vysbzd9hl/Group%202342.svg';

// Size in mega bytes
export const MAXIMUM_FILE_UPLOAD_SIZE = 40;

export const CSAT_RATINGS = [
  {
    key: 'disappointed',
    emoji: '😞',
    value: 1,
    color: '#FF9D4D',
    label: 'Sad',
  },
  {
    key: 'expressionless',
    emoji: '😑',
    value: 2,
    color: '#F6BD16',
    label: 'Disappointed',
  },
  {
    key: 'neutral',
    emoji: '😐',
    value: 3,
    color: '#9270CA',
    label: 'Not Satisfied',
  },
  {
    key: 'grinning',
    emoji: '😀',
    value: 4,
    color: '#1B83AC',
    label: 'Happy',
  },
  {
    key: 'smiling',
    emoji: '😍',
    value: 5,
    color: '#6BAC1B',
    label: 'Very Happy',
  },
];

export const MESSAGE_VARIABLES = [
  {
    label: 'Ticket Id',
    key: 'ticket_id',
  },
  {
    label: 'Contact name',
    key: 'contact_name',
  },
];
